import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { Agency } from "../../types/graphql";

export const listing: Listing<Agency> = {
  fields: [
    {
      heading: "Name",
      render: ent => <Link to={`/sales/agencies/${ent.id}`}>{ent.name}</Link>
    }
  ]
};
