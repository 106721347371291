import * as React from "react";
import { Card } from "components/Layout";
import { DetailProps } from "types/types";
import { MediaGallery } from "types/graphql";
import { Upload } from "components/Upload";

export const Overview: React.FC<DetailProps<MediaGallery>> = ({
  entity,
  actions,
  refetch
}) => {
  return (
    <>
      <Card>
        <Card.Content>
          <h4 className="title is-4">{entity.name}</h4>
        </Card.Content>
      </Card>
      <hr />
      <Upload gallery={entity} onUpload={refetch} />
      <hr />
      <div className="is-pulled-right">{actions}</div>
    </>
  );
};
