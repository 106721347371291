import * as React from "react";
import cx from "classnames";
import { BasicContainer } from ".";

export type ColumnsProps = BasicContainer & {
  centered?: boolean;
  vcentered?: boolean;
  gapless?: boolean;
  multiline?: boolean;
  mobile?: boolean;
};
export const Columns: React.FC<ColumnsProps> = ({
  children,
  centered,
  vcentered,
  gapless,
  multiline,
  mobile
}) => (
  <div
    className={cx("columns", {
      "is-centered": centered,
      "is-vcentered": vcentered,
      "is-gapless": gapless,
      "is-multiline": multiline,
      "is-mobile": mobile
    })}
  >
    {children}
  </div>
);
