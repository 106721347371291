import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { MediaGallery } from "../../types/graphql";

export const listing: Listing<MediaGallery> = {
  fields: [
    {
      heading: "Name",
      render: ent => <Link to={`/product/galleries/${ent.id}`}>{ent.name}</Link>
    },
    {
      heading: "Parent",
      render: ent =>
        ent.parent ? (
          <Link to={`/product/galleries/${ent.parent.id}`}>
            {ent.parent.name}
          </Link>
        ) : null
    },
    {
      heading: "Media items",
      render: ent => ent.mediaGalleryItems.totalCount
    },
    {
      heading: "Children",
      render: ent => ent.childMediaGalleries.totalCount
    }
  ]
};
