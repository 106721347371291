import React, { useState, useEffect } from "react";
import Pagination from "../Pagination";
import { Model } from "../../types/Model";
import { Message } from "../Layout/Message";
import useDebounce from "../../hooks/useDebounce";
import { Create } from "components/entities/Create";
import { Column, Columns } from "components/Layout";
import { ListingTable } from "components/ListingTable";

type Props<T> = {
  model: Model<T>;
  value?: any;
  onSelect?: (id: any) => void;
};

const pageSize = 10;

export function Listing<T>({ model, value, onSelect }: Props<T>) {
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState("");
  const filterDebounce = useDebounce(filter, 500);
  const filterRef = React.createRef<HTMLInputElement>();

  const queryResult = model.queries.list({
    fetchPolicy: "cache-and-network",
    variables: { skip: offset, take: pageSize, filter: `%${filterDebounce}%` },
    skip: false,
  });

  const hasNextPage = queryResult.data?.list?.pageInfo.hasNextPage ?? false;
  const hasPreviousPage =
    queryResult.data?.list?.pageInfo.hasPreviousPage ?? false;

  useEffect(() => {
    const handler = (ev: KeyboardEvent) => {
      switch (ev.keyCode) {
        case 191:
          if (document.activeElement !== filterRef.current) {
            ev.preventDefault();
            ev.stopPropagation();
            filterRef.current?.focus();
          }
          break;
        case 27:
          if (document.activeElement === filterRef.current) {
            setFilter("");
          }
          break;
        case 34: // pg down
          if (hasNextPage) {
            setOffset(offset + pageSize);
          }
          break;
        case 33: // pg up
          if (hasPreviousPage) {
            setOffset(offset - pageSize);
          }
          break;
      }
    };

    document.addEventListener("keydown", handler);

    return () => document.removeEventListener("keydown", handler);
  }, [filterRef, offset, setOffset, hasNextPage, hasPreviousPage]);

  const createProps = Create<T>({
    model,
    onCreate: (ent) => {
      if (onSelect) {
        onSelect(ent as any);
      } else {
        queryResult.refetch();
      }
    },
  });

  if (queryResult.error) {
    return (
      <Message title="An error occurred">
        {JSON.stringify(queryResult.error)}
      </Message>
    );
  }

  return (
    <>
      <ListingTable<T>
        model={model}
        items={queryResult.data?.list?.nodes}
        onSelect={onSelect}
        value={value}
      />
      <Columns vcentered>
        <Column />
        {queryResult.loading ? (
          <Column narrow>
            <span className="icon">
              <i className="fad fa-spinner fa-spin" />
            </span>
          </Column>
        ) : null}
        <Column narrow>
          <button
            type="button"
            className="button is-small is-info"
            {...createProps}
          >
            New
          </button>
        </Column>
        <Column narrow>
          <div className="field">
            <div className="control">
              <input
                type="text"
                className="input is-small"
                value={filter}
                onChange={(ev) => setFilter(ev.currentTarget.value)}
                ref={filterRef}
              />
            </div>
          </div>
        </Column>
        {queryResult.data?.list ? (
          <Pagination
            offset={offset}
            setOffset={setOffset}
            pageInfo={{
              hasNextPage,
              hasPreviousPage,
              totalCount: queryResult.data.list.totalCount,
            }}
            pageSize={pageSize}
          />
        ) : null}
      </Columns>
    </>
  );
}
