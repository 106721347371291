import * as React from "react";
import { model as customerModel } from "entities/Customer/model";
import { Customer } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const CustomerSelector: React.FC<SelectorProps<Customer>> = ({
  label,
  ...rest
}) => (
  <SelectorField<Customer>
    model={customerModel}
    label={label ?? "Customer"}
    display={ent => ent.name ?? "(unnamed)"}
    {...rest}
  />
);
