import * as React from "react";
import { Tabs, Tab } from "components/Tabs";
import { Expenses } from "./Expenses";
import { DetailProps } from "types/types";
import { SupplierInvoice } from "types/graphql";

export const Detail: React.FC<DetailProps<SupplierInvoice>> = props => (
  <Tabs>
    <Tab title="Expenses">
      <Expenses {...props} />
    </Tab>
  </Tabs>
);
