import * as React from "react";
import { InputField } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { FinanceCategory } from "../../../types/graphql";

export const Editor: React.FC<DetailProps<FinanceCategory>> = ({ g, entity }) => (
  <>
    <InputField
      className="input is-large"
      placeholder="Name"
      autoFocus={!entity?.id}
      {...g.input("name")}
    />
  </>
);
