import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Invoice } from "../../../types/graphql";
import { TripSelector, FinanceCategorySelector } from "components/Selectors";
import { DateField, AmountField } from "components/Layout/Field";

export const Editor: React.FC<DetailProps<Invoice>> = ({ g }) => (
  <>
    <Columns>
      <Column span={6}>
        {g.link("trip", (trip) => (
          <TripSelector field={trip} />
        ))}
      </Column>
      <Column span={6}>
        {g.link("category", (category) => (
          <FinanceCategorySelector field={category} />
        ))}
      </Column>
    </Columns>
    <Columns>
      <Column>
        <DateField label="Invoiced" {...g.input("invoiced")} />
      </Column>
      <Column>
        <DateField label="Due" {...g.input("due")} />
      </Column>
      <Column>
        <AmountField label="Amount" {...g.input("amount")} />
      </Column>
      <Column>
        <InputField label="Currency" {...g.input("currency")} />
      </Column>
      <Column>
        <DateField label="Paid" {...g.input("paid")} />
      </Column>
      <Column>
        <DateField label="Voided" {...g.input("voided")} />
      </Column>
    </Columns>
    <Columns>
      <Column span={6}>
        <InputField label="Summary" {...g.input("summary")} />
      </Column>
      <Column span={3}>
        <InputField label="Type" {...g.input("type")} />
      </Column>
      <Column>
        <InputField label="Note" {...g.input("note")} />
      </Column>
    </Columns>
  </>
);
