import * as React from "react";
import type { Form as Graphile } from "use-graphile";
import { Quote, QuoteFinanceLineItem, Supplier } from "types/graphql";
import { itemTotal } from "../util";
import { Columns, Column, InputField } from "components/Layout";
import { SupplierSelector } from "components/Selectors/SupplierSelector";
import { SelectField } from "components/Layout/Field";
import { money } from "components/Format";
import { MultiFieldItem } from "use-graphile/dist/fields/multi";
import cx from "classnames";

type LineItemProps = {
  lineItem: MultiFieldItem<QuoteFinanceLineItem>;
  g: Graphile<Quote>;
  onMove?: () => void;
  moving: number | null;
};

export const LineItem: React.FC<LineItemProps> = ({
  g,
  lineItem,
  onMove,
  moving,
}) => {
  const subtotal = itemTotal(lineItem.item, g.values);
  return (
    <Columns>
      <Column span={4}>
        {lineItem.link<Supplier>("supplier", (field) => (
          <SupplierSelector field={field} />
        ))}
      </Column>
      <Column>
        <InputField label="Description" {...lineItem.input("description")} />
        <InputField label="Confirmation" {...lineItem.input("confirmation")} />
      </Column>
      <Column span={2}>
        <InputField label="Amount" {...lineItem.input("amount")} />
        <SelectField
          label="Currency"
          {...lineItem.select("currency", {
            list: [g.values.baseCurrency].concat(
              g.values.quoteCurrencies.nodes.map((ent) => (ent ? ent.code : ""))
            ),
            displayKey: (ent) => (ent ? ent : ""),
            valueKey: (ent) => ent,
          })}
        />
        <div className="field">
          <label className="checkbox">
            <input type="checkbox" {...lineItem.checkbox("excludeMargin")} />
            <span>Exclude margin</span>
          </label>
        </div>
      </Column>
      <Column span={2}>
        <InputField
          label="Quantity"
          {...lineItem.input("quantity")}
          helpMessage={
            lineItem.item.quantity?.toString() !== "1"
              ? money(subtotal.foreign, lineItem.item.currency)
              : null
          }
        />
        <InputField
          label="Subtotal"
          value={money(subtotal.subtotal, g.values.baseCurrency) || ""}
          // helpMessage={
          //   subtotal.margin
          //     ? `Incl. margin: ${money(subtotal.margin, g.values.baseCurrency)}`
          //     : null
          // }
          readOnly
        />
      </Column>

      {moving !== null ? null : (
        <Column narrow>
          <div className="buttons">
            {onMove ? (
              <button className="button is-info is-outlined" onClick={onMove}>
                <span className="icon">
                  <i
                    className={cx({
                      "far fa-times": moving !== null,
                      "fad fa-arrows-v": moving === null,
                    })}
                  />
                </span>
              </button>
            ) : null}

            <button
              className="button is-warning is-outlined"
              onClick={lineItem.remove}
            >
              <span className="icon">
                <i className="fad fa-trash" />
              </span>
            </button>
          </div>
        </Column>
      )}
    </Columns>
  );
};
