export const model = {
  MediaGallery: {
    name: "string",
    isProtected: "boolean",
    parent: {
      type: "MediaGallery",
      patchProperty: "mediaGalleryToParentId"
    }
  },
  MediaItem: {}
};
