import * as React from "react";
import { model as MediaGalleryModel } from "entities/MediaGallery/model";
import { MediaGallery } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const GallerySelector: React.FC<SelectorProps<MediaGallery>> = ({
  label,
  ...rest
}) => (
  <SelectorField<MediaGallery>
    model={MediaGalleryModel}
    label={label ?? "Media gallery"}
    display={ent => ent.name ?? "(unnamed)"}
    {...rest}
  />
);
