import * as React from "react";
import { model as LegalDocumentModel } from "entities/LegalDocument/model";
import { LegalDocument } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const LegalDocumentSelector: React.FC<SelectorProps<LegalDocument>> = ({
  label,
  ...rest
}) => (
  <SelectorField<LegalDocument>
    model={LegalDocumentModel}
    label={label ?? "Legal Document"}
    display={(ent) => ent.name}
    {...rest}
  />
);
