import * as React from "react";
import type { Form as Graphile } from "use-graphile";
import { quoteTotal } from "./util";
import { Quote, QuoteFinanceLineItem } from "types/graphql";
import { money } from "components/Format";
import { Column } from "components/Layout";

export const Summary: React.FC<any> = ({ g }: { g: Graphile<Quote> }) =>
  g.multi<QuoteFinanceLineItem>("quoteFinanceLineItems", (lineItems) => {
    const total = quoteTotal(lineItems.items, g.values);

    return (
      <>
        <Column narrow>
          <span className="heading">Total</span>
          <p className="title">
            {money(total.total, g.values.baseCurrency)}
            <ul
              className="help"
              style={{ fontWeight: "normal", width: 100, textAlign: "right" }}
            >
              <li>{money(total.subtotal, g.values.baseCurrency)}</li>
              {total.margin ? (
                <li>+ {money(total.margin, g.values.baseCurrency)}</li>
              ) : null}
              {total.agentMargin ? (
                <li>+ {money(total.agentMargin, g.values.baseCurrency)}</li>
              ) : null}
              {total.adjustment ? (
                <li>
                  {total.adjustment > 0 ? "+" : "-"}{" "}
                  {money(Math.abs(total.adjustment), g.values.baseCurrency)}
                </li>
              ) : null}
            </ul>
          </p>
          {total.deposit ? (
            <>
              <span className="heading">Deposit</span>
              <p className="subtitle">
                {money(total.deposit, g.values.baseCurrency)}
              </p>
            </>
          ) : null}
        </Column>
        {total.margin || total.agentMargin ? (
          <Column narrow>
            {total.margin ? (
              <>
                <span className="heading">Margin</span>
                <p className="subtitle">
                  {money(
                    total.margin + total.adjustment,
                    g.values.baseCurrency
                  )}
                  {total.adjustment ? (
                    <ul
                      className="help"
                      style={{ textAlign: "right", width: 100 }}
                    >
                      <li>{money(total.margin, g.values.baseCurrency)}</li>
                      <li>
                        {total.adjustment > 0 ? "+" : "-"}
                        {money(
                          Math.abs(total.adjustment),
                          g.values.baseCurrency
                        )}
                      </li>
                    </ul>
                  ) : null}
                </p>
              </>
            ) : null}
            {total.agentMargin ? (
              <>
                <span className="heading">Agent margin</span>
                <p className="subtitle">
                  {money(total.agentMargin, g.values.baseCurrency)}
                </p>
              </>
            ) : null}
          </Column>
        ) : null}
      </>
    );
  });
