export const model = {
  Expense: {
    amount: "number",
    currency: "string",
    amountActual: "number",
    paid: "date",
    summary: "string",
    note: "string",
    category: {
      type: "FinanceCategory",
      patchProperty: "financeCategoryToCategoryId"
    },
    trip: {
      type: "Trip",
      patchProperty: "tripToTripId"
    },
    supplier: {
      type: "Supplier",
      patchProperty: "supplierToSupplierId"
    },
    supplierInvoice: {
      type: "SupplierInvoice",
      patchProperty: "supplierInvoiceToSupplierInvoiceId"
    }
  }
};
