import { Model } from "use-graphile";
import { model as agency } from "./Agency/graphile";
import { model as agencyMember } from "./AgencyMember/graphile";
import { model as airport } from "./Airport/graphile";
import { model as country } from "./Country/graphile";
import { model as customer } from "./Customer/graphile";
import { model as destination } from "./Destination/graphile";
import { model as expense } from "./Expense/graphile";
import { model as financeCategory } from "./FinanceCategory/graphile";
import { model as invoice } from "./Invoice/graphile";
import { model as legalDocument } from "./LegalDocument/graphile";
import { model as legalFolder } from "./LegalFolder/graphile";
import { model as mediaGallery } from "./MediaGallery/graphile";
import { model as payment } from "./Payment/graphile";
import { model as property } from "./Property/graphile";
import { model as quote } from "./Quote/graphile";
import { model as supplier } from "./Supplier/graphile";
import { model as supplierInvoice } from "./SupplierInvoice/graphile";
import { model as traveller } from "./Traveller/graphile";
import { model as trip } from "./Trip/graphile";
import { model as user } from "./User/graphile";

export const graphileModel: Model = {
  ...agency,
  ...agencyMember,
  ...airport,
  ...country,
  ...customer,
  ...destination,
  ...expense,
  ...financeCategory,
  ...invoice,
  ...legalDocument,
  ...legalFolder,
  ...mediaGallery,
  ...payment,
  ...property,
  ...quote,
  ...supplier,
  ...supplierInvoice,
  ...traveller,
  ...trip,
  ...user,
};
