import * as React from "react";
import { DetailProps } from "types/types";
import { Quote, QuoteHero, MediaItem } from "types/graphql";
import { TextAreaField, InputField } from "components/Layout/Field";
import { UserSelector, MediaSelector } from "components/Selectors";

export const Presentation: React.FC<DetailProps<Quote>> = ({ g }) => (
  <>
    {g.link<QuoteHero>("hero", (hero) => (
      <>
        {hero.link<MediaItem>("image", (image) => (
          <MediaSelector
            gallery="d4c12fc1-6328-429c-a1b8-40cbf34d2add"
            {...image}
          />
        ))}
        <InputField label="Title" {...hero.input("title")} />
        <TextAreaField label="Subtitle" {...hero.textarea("subtitle")} />
      </>
    ))}
    {g.link("user", (user) => (
      <UserSelector field={user} />
    ))}
  </>
);
