import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { Traveller } from "../../types/graphql";
import { date } from "components/Format";
import moment from "moment";

export const listing: Listing<Traveller> = {
  fields: [
    {
      heading: "Last name",
      render: ent => (
        <Link to={`/sales/travellers/${ent.id}`}>{ent.lastName}</Link>
      ),
      className: "col-name"
    },
    {
      heading: "First name",
      render: ent => ent.firstName,
      className: "col-name"
    },
    {
      heading: "Date of birth",
      render: ent =>
        ent.dateOfBirth
          ? `${date(ent.dateOfBirth)} (${moment().diff(
              moment(ent.dateOfBirth),
              "years"
            )})`
          : null,
      className: "col-date-ext"
    },
    {
      heading: "Nationality",
      render: ent => ent.nationality,
      className: "col-country"
    }
  ]
};
