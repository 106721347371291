import { Queries } from "types/Model";
import {
  Quote,
  useListQuotesQuery,
  useGetQuoteQuery,
  useUpdateQuoteMutation,
  useCreateQuoteMutation
} from "types/graphql";

export const queries: Queries<Quote> = {
  list: useListQuotesQuery,
  create: useCreateQuoteMutation,
  detail: useGetQuoteQuery,
  update: useUpdateQuoteMutation
};
