import * as React from "react";
import { Link } from "react-router-dom";
import { Supplier } from "types/graphql";

type Props = {
  supplier: Supplier | null | undefined;
};

export const SupplierField: React.FC<Props> = ({ supplier }) =>
  supplier ? (
    <Link to={`/finance/suppliers/${supplier.id}`}>{supplier.name}</Link>
  ) : null;
