import * as React from "react";
import moment from "moment";
import cx from "classnames";
import { DetailProps } from "../../../types/types";
import {
  Quote,
  Trip,
  useCreateQuoteMutation,
  useQuotePdfMutation,
  useWetuImportMutation,
} from "../../../types/graphql";
import { Columns, Column, InputField } from "../../../components/Layout";
import { SelectField, DateField } from "../../../components/Layout/Field";
import { TripSelector } from "../../../components/Selectors/TripSelector";
import { useNavigate } from "react-router";
import { cloneQuote } from "./clone";

const QuoteStatusEnum = [
  { id: 0, name: "Active" },
  { id: 1, name: "Confirmed" },
  { id: 2, name: "Expired" },
  { id: 3, name: "Void" },
  { id: 4, name: "Sample" },
];

export const Editor: React.FC<DetailProps<Quote>> = ({
  g,
  actions,
  entity,
  refetch,
}) => {
  const navigate = useNavigate();
  const [clone, cloneStatus] = useCreateQuoteMutation();
  const [pdf, pdfStatus] = useQuotePdfMutation();
  const [wetu, wetuStatus] = useWetuImportMutation();

  const handleClone = async () => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    const newQuote = cloneQuote(entity);
    // TODO: hook up daily breakdown accommodation
    try {
      const res = await clone({ variables: { entity: newQuote } });

      navigate(`/sales/quotes/${res.data?.create?.entity?.id}`);
    } catch (ex) {
      window.alert(
        "Something went wrong cloning this quote.  Check the console."
      );
      console.error(ex);
    }
  };

  const handlePdf = async () => {
    const res = await pdf({ variables: { key: entity.key! } });

    if (res.data?.quotePdf) {
      downloadBase64(res.data?.quotePdf, entity.key!);
    }
  };

  const handleWetu = async () => {
    if (
      !window.confirm(
        "WARNING: Proceeding with this will clear the current quote and overwrite it with the values from Wetu.  Are you sure you want to proceed?"
      )
    ) {
      return;
    }
    let wetuId = prompt("Enter Wetu ID");
    if (!wetuId) {
      return;
    }
    if (wetuId.includes("/")) {
      wetuId = wetuId.split("/").filter(Boolean).pop()!;
    }
    const res = await wetu({ variables: { wetuId, quoteId: entity.id! } });

    if (res.data?.importWetu?.success) {
      refetch();
    }
  };

  return (
    <>
      <Columns>
        <Column>
          <InputField
            label="Short description"
            {...g.input("shortDescription")}
            autoFocus={!entity?.id}
          />

          {g.link<Trip>("trip", (trip) => (
            <TripSelector field={trip} />
          ))}

          <SelectField
            label="Status"
            {...g.select("status", {
              list: QuoteStatusEnum,
              valueKey: (ent: any) => ent.id,
              displayKey: (ent: any) => ent.name,
            })}
          />
          <DateField label="Expires" {...g.input("expires")} />
          <DateField label="Locked" {...g.input("locked")} />
          {g.values?.key && (
            <InputField label="Key" {...g.input("key")} readOnly />
          )}
        </Column>
        <Column>
          <DateField label="Departing" {...g.input("start")} />
          <InputField
            label="Nights"
            type="number"
            min={1}
            step={1}
            {...g.input("duration")}
          />
          {g.values.start && g.values.duration ? (
            <DateField
              label="Return"
              readOnly
              value={moment(g.values.start)
                .add(g.values.duration, "day")
                .format("YYYY-MM-DD")}
            />
          ) : null}
          <InputField
            label="Travellers"
            type="number"
            min={1}
            step={1}
            {...g.input("travellerCount")}
          />
          {g.values?.key && (
            <>
              <hr />
              <div className="buttons is-centered">
                <a
                  href={`https://www.takeoffgo.com/quote/${g.values.key}?preview=true`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-text"
                >
                  <span>Quote</span>
                  <span className="icon">
                    <i className="fad fa-external-link-square"></i>
                  </span>
                </a>
                <a
                  href={`https://www.takeoffgo.com/itinerary/${g.values.key}?preview=true`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-text"
                >
                  <span>Itinerary</span>
                  <span className="icon">
                    <i className="fad fa-external-link-square"></i>
                  </span>
                </a>
              </div>
              <div className="buttons is-centered">
                <button
                  className={cx("button is-link is-small", {
                    "is-loading": cloneStatus.loading,
                  })}
                  onClick={handleClone}
                >
                  Clone
                </button>
                <button
                  className={cx("button is-small", {
                    "is-loading": pdfStatus.loading,
                  })}
                  onClick={handlePdf}
                >
                  PDF
                </button>
                <button
                  className={cx("button is-small", {
                    "is-loading": wetuStatus.loading,
                  })}
                  onClick={handleWetu}
                >
                  Wetu Import
                </button>
              </div>
            </>
          )}
        </Column>
      </Columns>
      <hr />
      {actions}
      {/* <pre>{JSON.stringify(g.buildPatch(), null, 2)}</pre> */}
    </>
  );
};

const base64ToBlob = (input: string) => {
  const binaryString = window.atob(input);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return new Blob([bytes], { type: "application/pdf" });
};

const downloadBase64 = (input: string, name: string) => {
  const blob = base64ToBlob(input);
  const blobURL = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("download", `${name}.pdf`);
  link.href = blobURL;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
