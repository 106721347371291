import * as React from "react";
import { Card } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Agency } from "../../../types/graphql";

export const Overview: React.FC<DetailProps<Agency>> = ({
  entity,
  actions
}) => {
  return (
    <>
      <Card>
        {entity.logo && (
          <Card.Image>
            <figure className="image" style={{ padding: 20 }}>
              <img
                src={`https://cdn.takeoffgo.com/${entity.logo.hash}`}
                alt={entity.name || "Agency logo"}
              />
            </figure>
          </Card.Image>
        )}
        <Card.Content>
          <h4 className="title is-4">{entity.name}</h4>
        </Card.Content>
      </Card>
      <hr />
      <div className="is-pulled-right">{actions}</div>
    </>
  );
};
