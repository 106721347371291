import * as React from "react";
import { Container, Section, Columns, Column } from "components/Layout";
import {
  useDashboardQuery,
  Quote,
  SupplierInvoice,
  Invoice,
} from "types/graphql";
import { ListingTable } from "components/ListingTable";
import { model as quote } from "entities/Quote/model";
import { model as supplierInvoice } from "entities/SupplierInvoice/model";
import { model as invoice } from "entities/Invoice/model";

export const Dashboard: React.FC = () => {
  const query = useDashboardQuery({ fetchPolicy: "cache-and-network" });

  return (
    <Section>
      <Container>
        <Columns>
          <Column span={6}>
            <p className="heading">Active quotes</p>
            <ListingTable<Quote>
              items={query.data?.quotesWorking?.nodes as Quote[]}
              model={quote}
              excludedColumns={["Status"]}
            />
          </Column>
          <Column span={6}>
            <p className="heading">Confirmed quotes</p>
            <ListingTable<Quote>
              items={query.data?.quotesUpcoming?.nodes as Quote[]}
              model={quote}
              excludedColumns={["Status"]}
            />
          </Column>
        </Columns>
        <Columns>
          <Column span={6}>
            <p className="heading">Upcoming invoices (payable)</p>
            <ListingTable<SupplierInvoice>
              items={query.data?.supplierInvoices?.nodes as SupplierInvoice[]}
              model={supplierInvoice}
              excludedColumns={["Amount actual", "Voided", "Paid"]}
            />
          </Column>
          <Column span={6}>
            <p className="heading">Upcoming invoices (receivable)</p>
            <ListingTable<Invoice>
              items={query.data?.invoices?.nodes as Invoice[]}
              model={invoice}
              excludedColumns={["Voided", "Paid"]}
            />
          </Column>
        </Columns>
      </Container>
    </Section>
  );
};
