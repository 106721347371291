import * as React from "react";
import cx from "classnames";
import { Model } from "../types/Model";
import { useNavigate } from "react-router";

type ListingTableProps<T> = {
  items: T[] | undefined;
  model: Model<T>;
  value?: any;
  onSelect?: (id: any) => void;
  excludedColumns?: string[];
};

export function ListingTable<T>({
  items,
  model,
  value,
  onSelect,
  excludedColumns,
}: ListingTableProps<T>) {
  const tableRef = React.createRef<HTMLDivElement>();
  const [cursor, setCursor] = React.useState(-1);
  const navigate = useNavigate();

  React.useEffect(() => setCursor(-1), [items]);

  React.useEffect(() => {
    const handler = (ev: KeyboardEvent) => {
      if (ev.ctrlKey) {
        switch (ev.key) {
          case "j":
            if (onSelect || model.detailUrl) {
              ev.preventDefault();
              ev.stopPropagation();
              setCursor(cursor === items?.length ? cursor : cursor + 1);
            }
            break;
          case "k":
            if (onSelect || model.detailUrl) {
              ev.preventDefault();
              ev.stopPropagation();
              setCursor(cursor === -1 ? cursor : cursor - 1);
            }
            break;
          case "Enter":
            if (items && cursor > -1) {
              ev.preventDefault();
              ev.stopPropagation();
              if (onSelect) {
                onSelect(items[cursor]);
              } else if (model.detailUrl) {
                const url = model.detailUrl(items[cursor]);
                navigate(url);
              }
            }
            break;
        }
      }
    };

    const target = tableRef.current;

    target?.addEventListener("keydown", handler);

    return () => target?.removeEventListener("keydown", handler);
  }, [items, cursor, model, onSelect, navigate, tableRef]);

  return (
    <div className="table-container" ref={tableRef} tabIndex={0}>
      <table className="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            {onSelect ? <th style={{ width: 65 }} /> : null}
            {model.listing.fields
              .filter(
                (fld) => (excludedColumns?.indexOf(fld.heading) ?? -1) === -1
              )
              .map((fld, key) =>
                React.createElement("th", {
                  key,
                  children: fld.heading,
                  className: fld.className,
                })
              )}
          </tr>
        </thead>
        <tbody>
          {items?.length === 0 ? (
            <tr>
              <td
                colSpan={model.listing.fields.length + (onSelect ? 1 : 0)}
                className="has-text-centered"
              >
                No results.
              </td>
            </tr>
          ) : null}
          {items?.map((ent, idx) => (
            <tr
              key={(ent as any).id}
              className={cx({
                "is-selected": idx === cursor,
                [model.listing.rowClassName?.(ent) ?? ""]:
                  model.listing.rowClassName?.(ent),
              })}
            >
              {onSelect ? (
                <td>
                  <button
                    type="button"
                    className="button is-small"
                    onClick={() => onSelect(ent)}
                    disabled={value === (ent as any).id}
                  >
                    Select
                  </button>
                </td>
              ) : null}
              {model.listing.fields
                .filter(
                  (fld) => (excludedColumns?.indexOf(fld.heading) ?? -1) === -1
                )
                .map((fld, key) =>
                  React.createElement("td", {
                    key,
                    children: fld.render(ent),
                    className: cx({
                      "has-text-right": fld.right,
                    }),
                  })
                )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
