import * as React from "react";
import Markdown from "react-markdown";
import { Card } from "components/Layout";
import { DetailProps } from "types/types";
import { Destination, MediaGallery, MediaItem } from "types/graphql";
import { GallerySelector, MediaSelector } from "components/Selectors";
import { imageUrl } from "../../../util";

export const Overview: React.FC<DetailProps<Destination>> = ({
  entity,
  actions,
  g,
}) => {
  return (
    <>
      <Card>
        {g.values.heroMedia?.hash && (
          <Card.Image>
            <img
              src={`${imageUrl(g.values.heroMedia.hash)}?w=800&h=600`}
              alt=""
            />
          </Card.Image>
        )}
        <Card.Content>
          <h4 className="title is-4">{entity.name}</h4>
          <h6 className="subtitle is-6">{entity.country?.name}</h6>
          {entity.body && (
            <Markdown className="content">{entity.body}</Markdown>
          )}
          <hr />
          {g.link<MediaGallery>("gallery", (gallery) => (
            <GallerySelector field={gallery} />
          ))}
          {g.values?.gallery?.id &&
            g.link<MediaItem>("heroMedia", (image) => (
              <MediaSelector gallery={g.values.gallery?.id} {...image} />
            ))}
        </Card.Content>
        <Card.Footer>
          <Card.Footer.Item>
            <span className="icon">
              <i className="fad fa-external-link" />
            </span>
            <a
              href={`https://www.takeoffgo.com/travel/destinations/${entity.id}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              Public
            </a>
          </Card.Footer.Item>
        </Card.Footer>
      </Card>
      <hr />
      <div className="is-pulled-right">{actions}</div>
    </>
  );
};
