import { Model } from "use-graphile";

export const model: Model = {
  Property: {
    name: "string",
    region: "string",
    nearestAirport: "string",
    summary: "string",
    latitude: "number",
    longitude: "number",
    featured: "date",
    featureCopy: "string",
    url: "string",
    country: {
      type: "Country",
      patchProperty: "countryToCountryId",
    },
    gallery: {
      type: "MediaGallery",
      patchProperty: "mediaGalleryToGalleryId",
    },
    heroMedia: {
      type: "MediaItem",
      patchProperty: "mediaItemToHeroMediaId",
      patchWorkaroundProperty: "heroMediaId",
    },
    destination: {
      type: "Destination",
      patchProperty: "destinationToDestinationId",
      patchWorkaroundProperty: "destinationId",
    },
  },
};
