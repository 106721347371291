import * as React from "react";
import { Card } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Traveller } from "../../../types/graphql";

export const Overview: React.FC<DetailProps<Traveller>> = ({
  entity,
  actions
}) => {
  const footerButtons = [
    entity.email
      ? {
          href: `mailto:${entity.email}`,
          icon: "fad fa-envelope",
          text: "Email"
        }
      : null,
    entity.phone
      ? {
          href: `tel:${entity.phone.replace(/\s/g, "")}`,
          icon: "fad fa-phone",
          text: "Phone"
        }
      : null
  ].filter(ent => ent);
  return (
    <>
      <Card>
        <Card.Content>
          <h4 className="title is-4">
            {[entity.lastName, entity.firstName].filter(a => !!a).join(", ")}
          </h4>
          {entity.email && <h6 className="subtitle is-6">{entity.email}</h6>}
          {entity.nationality && (
            <span className="tag is-dark">{entity.nationality}</span>
          )}
        </Card.Content>
        {footerButtons.length > 0 && (
          <Card.Footer>
            {footerButtons.map((ent: any, idx) => (
              <Card.Footer.Item key={idx}>
                {ent.icon && (
                  <span className="icon">
                    <i className={ent.icon} />
                  </span>
                )}
                <a href={ent.href} target="_blank" rel="noreferrer noopener">
                  {ent.text}
                </a>
              </Card.Footer.Item>
            ))}
          </Card.Footer>
        )}
      </Card>
      <hr />
      <div className="is-pulled-right">{actions}</div>
    </>
  );
};
