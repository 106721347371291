import * as React from "react";
import { Card } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Airport } from "../../../types/graphql";

export const Overview: React.FC<DetailProps<Airport>> = ({
  entity,
  actions
}) => {
  return (
    <>
      <Card>
        <Card.Content>
          <h4 className="title is-4">{entity.name}</h4>
          <h6 className="subtitle is-6">
            {[entity.city, entity.country?.name].filter(a => !!a).join(", ")}
          </h6>
          <div className="field is-grouped is-grouped-multiline">
            {[
              { title: "IATA", value: entity.iata },
              { title: "ICAO", value: entity.icao },
              { title: "TZ", value: entity.timezone }
            ]
              .filter(ent => ent.value)
              .map(ent => (
                <div className="control" key={ent.title}>
                  <div className="tags has-addons">
                    <span className="tag is-dark">{ent.title}</span>
                    <span className="tag">{ent.value}</span>
                  </div>
                </div>
              ))}
          </div>
        </Card.Content>
      </Card>
      <hr />
      <div className="is-pulled-right">{actions}</div>
    </>
  );
};
