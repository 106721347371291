import * as React from "react";
import { DetailProps } from "types/types";
import { Trip } from "types/graphql";
import { Upload } from "components/Upload";
import { date } from "components/Format";
import { imageUrl } from "../../../../util";
import { useAuth0 } from "@auth0/auth0-react";

export const FileList: React.FC<DetailProps<Trip>> = ({
  entity,
  g,
  refetch,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = React.useState("");

  React.useEffect(() => {
    (async () => {
      setAccessToken(await getAccessTokenSilently());
    })();
  }, [getAccessTokenSilently]);

  if (!g.values.mediaGallery) {
    return null;
  }
  return (
    <>
      <hr />
      <table className="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>Name</th>
            <th className="col-date">Uploaded</th>
          </tr>
        </thead>
        <tbody>
          {entity.mediaGallery?.mediaGalleryItems?.nodes.map((ent) => (
            <tr key={ent?.id}>
              <td>
                {ent?.mediaItem?.hash ? (
                  <a
                    href={imageUrl(
                      ent?.mediaItem.hash,
                      entity.mediaGallery?.isProtected,
                      accessToken
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ent?.mediaItem?.fileName}
                  </a>
                ) : (
                  <span>{ent?.mediaItem?.fileName}</span>
                )}
              </td>
              <td>{date(ent?.mediaItem?.created)}</td>
            </tr>
          )) ?? null}
        </tbody>
      </table>
      <Upload gallery={g.values.mediaGallery} onUpload={refetch} />
    </>
  );
};
