import { Model } from "../../types/Model";
import { queries } from "./query";
import { listing } from "./listing";
import { graphileModel } from "../graphile";

import { View as display } from "./components/View";
import { Expense } from "../../types/graphql";
import { Editor as editor } from "./components/Editor";

export const model: Model<Expense> = {
  // editing
  graphileEntity: "Expense",
  graphileModel,
  // querying
  queries,
  // displaying
  listing,
  display,
  editor,
  // misc.
  breadcrumb: () => [
    { target: ["finance"], label: "Finance" },
    { target: ["finance", "expenses"], label: "Expenses" }
  ]
};
