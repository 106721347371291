export const model = {
  Payment: {
    amount: "number",
    currency: "string",
    summary: "string",
    amountActual: "number",
    paid: "date",
    note: "string",
    category: {
      type: "FinanceCategory",
      patchProperty: "financeCategoryToCategoryId"
    },
    trip: {
      type: "Trip",
      patchProperty: "tripToTripId"
    },
    invoice: {
      type: "Invoice",
      patchProperty: "invoiceToInvoiceId"
    }
  }
};
