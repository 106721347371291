import * as React from "react";
import { model as DestinationModel } from "entities/Destination/model";
import { Destination } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const DestinationSelector: React.FC<SelectorProps<Destination>> = ({
  label,
  ...rest
}) => (
  <SelectorField<Destination>
    model={DestinationModel}
    label={label ?? "Destination"}
    display={ent => ent.name ?? "(unnamed)"}
    {...rest}
  />
);
