import * as React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { DetailProps } from "types/types";
import { Trip, SupplierInvoice } from "types/graphql";
import { DateFormat, money } from "components/Format";
import { SupplierField } from "components/SupplierField";
import { model as supplierInvoiceModel } from "entities/SupplierInvoice/model";
import { Create } from "components/entities/Create";

export const SupplierInvoiceList: React.FC<DetailProps<Trip>> = ({
  entity,
  refetch,
}) => {
  const initialValues = React.useMemo(
    () => ({
      trip: {
        id: entity.id,
        name: entity.name,
      },
    }),
    [entity]
  );

  const createProps = Create<SupplierInvoice>({
    model: supplierInvoiceModel,
    initialValues,
    onCreate: () => refetch(),
  });

  return (
    <>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td colSpan={2}>
                <span className="heading">Supplier invoices received</span>
              </td>
              <th className="col-date">Paid</th>
            </tr>
            <tr>
              <th className="col-amount">Local</th>
              <th className="col-amount">Foreign</th>
              <th className="col-date">Due</th>
            </tr>
          </thead>
          <tbody>
            {entity.supplierInvoices.nodes.map((ent) =>
              !ent ? null : (
                <React.Fragment key={ent.id}>
                  <tr className={ent.paid ? "is-muted" : ""}>
                    <td colSpan={2}>
                      <SupplierField supplier={ent.supplier} />
                    </td>
                    <td>
                      {ent.paid && moment(ent.paid).format(DateFormat.Short)}
                    </td>
                  </tr>
                  <tr
                    className={"is-group-end " + (ent.paid ? "is-muted" : "")}
                  >
                    <td>
                      <Link to={`/finance/supplier-invoices/${ent.id}`}>
                        {money(ent.amountActual)}
                      </Link>
                    </td>
                    <td>{money(ent.amount, ent.currency)}</td>
                    <td>{moment(ent.due).format(DateFormat.Short)}</td>
                  </tr>
                </React.Fragment>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="buttons is-right">
        <button className="button is-dark is-small" {...createProps}>
          Create
        </button>
      </div>
    </>
  );
};
