export const model = {
  Airport: {
    name: "string",
    city: "string",
    iata: "string",
    icao: "string",
    timezone: "string",
    latitude: "number",
    longitude: "number",
    country: {
      type: "Country",
      patchProperty: "countryToCountryId"
    }
  }
};
