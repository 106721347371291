export const model = {
  Customer: {
    name: "string",
    phone: "string",
    email: "string",
    customerTravellers: {
      type: "CustomerTraveller",
      multi: true,
      patchProperty: "customerTravellersUsingId"
    }
  },
  CustomerTraveller: {
    traveller: {
      type: "Traveller",
      patchProperty: "travellerToTravellerId",
      patchWorkaroundProperty: "travellerId"
    }
  }
};
