import { Queries } from "types/Model";
import {
  User,
  useListUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation
} from "types/graphql";

export const queries: Queries<User> = {
  list: useListUsersQuery,
  create: useCreateUserMutation,
  detail: useGetUserQuery,
  update: useUpdateUserMutation
};
