import * as React from "react";

import { model } from "entities/Payment/model";
import { Payment, Invoice } from "types/graphql";
import { ListingTable } from "components/ListingTable";
import { DetailProps } from "types/types";
import moment from "moment";
import { Create } from "components/entities/Create";

export const Payments: React.FC<DetailProps<Invoice>> = ({
  entity,
  refetch
}) => {
  const initialValues = React.useMemo(
    () => ({
      paid: moment().format("YYYY-MM-DD"),
      invoice: entity,
      trip: entity.trip,
      category: entity.category,
      amount: entity.amount,
      amountActual: entity.amount,
      currency: entity.currency
    }),
    [entity]
  );
  const createProps = Create<Payment>({
    model,
    initialValues,
    onCreate: () => refetch()
  });

  return (
    <>
      <ListingTable<Payment>
        items={entity.payments?.nodes as Payment[]}
        model={model}
      />

      <hr />
      <div className="buttons is-right">
        <button
          type="button"
          className="button is-dark is-small"
          {...createProps}
        >
          Create
        </button>
      </div>
    </>
  );
};
