import { QuoteFinanceLineItem, Quote } from "../../../../../types/graphql";

export const itemTotal = (item: QuoteFinanceLineItem, quote: Quote) => {
  const currency = quote.quoteCurrencies.nodes.find(
    (cur) => cur && cur.code === item.currency
  );
  const rate = currency ? currency.rate : 1;

  const foreign =
    (parseFloat(item.amount || "") || 0) *
    (parseInt(item.quantity?.toString(10) || "", 10) || 1);
  const subtotal = foreign / rate;
  return { foreign, subtotal, excludeMargin: item.excludeMargin };
};

export const quoteTotal = (items: QuoteFinanceLineItem[], quote: Quote) => {
  const marginPercentage = parseFloat(quote.margin || "") || 0;
  const agentMarginPercentage = parseFloat(quote.agentMargin || "0");
  const adjustment = parseFloat(quote.adjustment || "0");

  const subtotal = items.reduce((p, c) => {
    const item = itemTotal(c, quote);
    return p + item.subtotal;
  }, 0);
  const marginFree = items
    .filter((ent) => ent.excludeMargin)
    .reduce((p, c) => {
      const item = itemTotal(c, quote);
      return p + item.subtotal;
    }, 0);

  const agentMargin = (subtotal - marginFree) * agentMarginPercentage;
  const margin = (subtotal - marginFree) * marginPercentage;
  const total = subtotal + margin + agentMargin + adjustment;
  const deposit = total * quote.deposit;

  return {
    agentMargin,
    subtotal,
    total,
    margin,
    deposit,
    adjustment,
  };
};
