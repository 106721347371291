import * as React from "react";

import { Column, Columns } from "components/Layout";
import { TextAreaField } from "components/Layout/Field";
import { Quote } from "types/graphql";
import { DetailProps } from "types/types";
import { Acceptance } from "./Acceptance";
import { LegalDocuments } from "./LegalDocuments";

export const Legal: React.FC<DetailProps<Quote>> = (props) => (
  <>
    <Acceptance {...props} />
    <Columns>
      <Column>
        <TextAreaField label="Inclusions" {...props.g.textarea("inclusions")} />
      </Column>
      <Column>
        <TextAreaField label="Exclusions" {...props.g.textarea("exclusions")} />
      </Column>
    </Columns>
    <hr />
    <LegalDocuments {...props} />
  </>
);
