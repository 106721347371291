import * as React from "react";
import { DetailProps } from "../../../types/types";
import { Quote } from "../../../types/graphql";
import { Columns, Column, Section } from "../../../components/Layout";
import { Overview } from "./Overview";
import { Tabs, Tab } from "../../../components/Tabs";
import { Financials } from "./Detail/Finance";
import { DailyBreakdown } from "./Detail/DailyBreakdown";
import { Legal } from "./Detail/Legal";
import { Presentation } from "./Detail/Presentation";
import { Accommodation } from "./Detail/Accommodation";
import { Views } from "./Detail/Views";

export const View: React.FC<DetailProps<Quote>> = (props) => {
  return (
    <Section>
      <Columns>
        <Column span={4}>
          <Overview {...props} />
        </Column>
        <Column span={8}>
          <Tabs>
            <Tab title="Itinerary">
              <DailyBreakdown {...props} />
            </Tab>
            <Tab title="Accommodation">
              <Accommodation {...props} />
            </Tab>
            <Tab title="Financials">
              <Financials {...props} />
            </Tab>
            <Tab title="Legal">
              <Legal {...props} />
            </Tab>
            <Tab title="Presentation">
              <Presentation {...props} />
            </Tab>
            <Tab title={`Views (${props.entity.quoteViews.totalCount})`}>
              <Views {...props} />
            </Tab>
          </Tabs>
        </Column>
      </Columns>
    </Section>
  );
};
