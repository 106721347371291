import * as React from "react";
import {
  Columns,
  Column,
  Section,
  Container,
} from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Property } from "../../../types/graphql";
import { Overview } from "./Overview";
import { Editor } from "./Editor";
import { Tab, Tabs } from "components/Tabs";
import { Quotes } from "./Detail/Quotes";

export const View: React.FC<DetailProps<Property>> = (props) => (
  <Container>
    <Section>
      <Columns>
        <Column span={3}>
          <Overview {...props} />
        </Column>
        <Column span={9}>
          <Tabs>
            <Tab title="Detail">
              <Editor {...props} />
            </Tab>
            <Tab title="Quotes">
              <Quotes {...props} />
            </Tab>
          </Tabs>
        </Column>
      </Columns>
    </Section>
  </Container>
);
