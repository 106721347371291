import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import cx from "classnames";
import Logo from "../Logo";
import { useAuth0 } from "@auth0/auth0-react";

type Section = {
  title: string;
  links: { title: string; target: string }[];
};

const links: Section[] = [
  {
    title: "Sales",
    links: [
      { title: "Agencies", target: "/sales/agencies" },
      { title: "Customers", target: "/sales/customers" },
      { title: "Quotes", target: "/sales/quotes" },
      { title: "Travellers", target: "/sales/travellers" },
      { title: "Trips", target: "/sales/trips" },
    ],
  },
  {
    title: "Product",
    links: [
      { title: "Airports", target: "/product/airports" },
      { title: "Countries", target: "/product/countries" },
      { title: "Destinations", target: "/product/destinations" },
      { title: "Galleries", target: "/product/galleries" },
      { title: "Properties", target: "/product/properties" },
    ],
  },
  {
    title: "Finance",
    links: [
      { title: "Expenses", target: "/finance/expenses" },
      { title: "Invoices", target: "/finance/invoices" },
      { title: "Payments", target: "/finance/payments" },
      { title: "Supplier invoices", target: "/finance/supplier-invoices" },
      { title: "Suppliers", target: "/finance/suppliers" },
    ],
  },
  {
    title: "Legal",
    links: [
      { title: "Folders", target: "/legal/folders" },
      { title: "Documents", target: "/legal/documents" },
    ],
  },
];

export const Navbar: React.FC = () => {
  const [visible, setVisible] = React.useState(false);
  const { logout, user } = useAuth0();

  const location = useLocation();

  React.useEffect(() => {
    setVisible(false);
  }, [location.pathname]);

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <NavLink className="navbar-item" to="/">
          <Logo />
        </NavLink>
        <div className="navbar-item">
          <strong>Jambo</strong>
        </div>
        <button
          className={cx("navbar-burger", { "is-active": visible })}
          aria-label="menu"
          aria-expanded="false"
          onClick={() => setVisible(!visible)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>
      {user ? (
        <div className={cx("navbar-menu", { "is-active": visible })}>
          <div className="navbar-start">
            {links.map((ent, idx) => (
              <div key={idx} className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link">{ent.title}</div>
                <div className="navbar-dropdown">
                  {ent.links.map((lnk, idx2) => (
                    <NavLink
                      key={idx2}
                      to={lnk.target}
                      className={({ isActive }) =>
                        cx("navbar-item", { "is-active": isActive })
                      }
                    >
                      {lnk.title}
                    </NavLink>
                  ))}
                </div>
              </div>
            ))}
            <NavLink
              to="/utilities"
              className={({ isActive }) =>
                cx("navbar-item", { "is-active": isActive })
              }
            >
              Utilities
            </NavLink>
          </div>
          <div className="navbar-end">
            {user && (
              <div className="navbar-item">
                <strong>{user.name}</strong>
              </div>
            )}
            <button
              className="navbar-item"
              type="button"
              onClick={() => logout({})}
            >
              Logout
            </button>
          </div>
        </div>
      ) : null}
    </nav>
  );
};
