import { Model } from "types/Model";
import { queries } from "./query";
import { listing } from "./listing";
import { graphileModel } from "../graphile";

import { View as display } from "./components/View";
import { Quote } from "types/graphql";
import { Editor as editor } from "./components/Editor";
import { buildPatch } from "use-graphile";
import { gql } from "@apollo/client";

export const QuoteStatus = ["Active", "Confirmed", "Expired", "Void", "Sample"];

const workaroundPatch = gql`
  mutation PatchQuoteDay($id: UUID!, $patch: QuoteDayPatch!) {
    updateQuoteDay(input: { id: $id, patch: $patch }) {
      clientMutationId
    }
  }
`;

export const model: Model<Quote> = {
  // editing
  graphileEntity: "Quote",
  graphileModel,
  // querying
  queries,
  // displaying
  listing,
  display,
  editor,
  // misc.
  breadcrumb: () => [
    { target: ["sales"], label: "Sales" },
    { target: ["sales", "quotes"], label: "Quotes" },
  ],
  detailUrl: (quote) => `/sales/quotes/${quote.id}`,
  beforeSave: async (original, updated, client) => {
    // this is a workaround for https://github.com/mlipscombe/postgraphile-plugin-nested-mutations/issues/29
    const patches = updated.quoteDays.nodes
      .filter((ent) => ent?.id)
      .map((ent) => {
        if (ent?.accommodationId === "(select)") {
          ent.accommodationId = null;
        }
        const orig = original.quoteDays.nodes.find((a) => a?.id === ent?.id);
        if (orig) {
          const patch = buildPatch(graphileModel, "QuoteDay", orig, ent);
          if (Object.keys(patch).length > 0) {
            return { id: ent?.id, patch };
          }
        }

        return null;
      })
      .filter(Boolean);

    for await (const variables of patches) {
      await client.mutate({ mutation: workaroundPatch, variables });
    }

    return updated;
  },
};
