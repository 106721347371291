import React from 'react'
import { BasicContainer } from '.'
import cx from 'classnames'

export type ColumnProps = BasicContainer & {
  span?: number
  narrow?: boolean
}

export const Column: React.FC<ColumnProps> = ({ span, narrow, children }) => (
  <div className={cx('column', { 'is-narrow': narrow, [`is-${span}`]: span })}>{children}</div>
)
