import * as React from "react";
import { ListingTable } from "components/ListingTable";
import { Traveller, Customer } from "types/graphql";
import { model as customerModel } from "entities/Customer/model";
import { DetailProps } from "types/types";

export const Customers: React.FC<DetailProps<Traveller>> = ({ entity }) => (
  <ListingTable<Customer>
    items={
      entity.customerTravellers.nodes?.map(ent => ent?.customer) as Customer[]
    }
    model={customerModel}
  />
);
