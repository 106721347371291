import * as React from "react";
import { Card } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { FinanceCategory } from "../../../types/graphql";

export const Overview: React.FC<DetailProps<FinanceCategory>> = ({
  entity,
  actions
}) => {
  return (
    <>
      <Card>
        <Card.Content>
          <h4 className="title is-4">{entity.name}</h4>
        </Card.Content>
      </Card>
      <hr />
      <div className="is-pulled-right">{actions}</div>
    </>
  );
};
