import * as React from "react";
import { model as financeCategoryModel } from "entities/FinanceCategory/model";
import { FinanceCategory } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const FinanceCategorySelector: React.FC<SelectorProps<
  FinanceCategory
>> = ({ label, ...rest }) => (
  <SelectorField<FinanceCategory>
    model={financeCategoryModel}
    label={label ?? "Category"}
    display={ent => ent.name ?? "(unnamed)"}
    {...rest}
  />
);
