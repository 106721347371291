import { Model } from "../../types/Model";
import { queries } from "./query";
import { listing } from "./listing";
import { graphileModel } from "../graphile";

import { View as display } from "./components/View";
import { Agency } from "../../types/graphql";
import { Editor as editor } from "./components/Editor";

export const model: Model<Agency> = {
  // editing
  graphileEntity: "Agency",
  graphileModel,
  // querying
  queries,
  // displaying
  listing,
  display,
  editor,
  // misc.
  breadcrumb: () => [
    { target: ["sales"], label: "Sales" },
    { target: ["sales", "agencies"], label: "Agencies" }
  ]
};
