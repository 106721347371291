import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { Customer } from "../../types/graphql";

export const listing: Listing<Customer> = {
  fields: [
    {
      heading: "Name",
      render: ent => <Link to={`/sales/customers/${ent.id}`}>{ent.name}</Link>
    },
    {
      heading: "Trips",
      render: ent => ent.trips.totalCount
    }
  ]
};
