import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { Expense } from "../../types/graphql";
import { money, date } from "components/Format";

export const listing: Listing<Expense> = {
  fields: [
    {
      heading: "Paid",
      render: ent => (
        <Link to={`/finance/expenses/${ent.id}`}>{date(ent.paid)}</Link>
      ),
      className: "col-date"
    },
    {
      heading: "Amount",
      render: ent => money(ent.amount, ent.currency),
      className: "col-amount",
      right: true
    },
    {
      heading: "Amount actual",
      render: ent => money(ent.amountActual),
      className: "col-amount",
      right: true
    },
    {
      heading: "Supplier",
      render: ent => (
        <Link to={`/finance/suppliers/${ent.supplier?.id}`}>
          {ent.supplier?.name}
        </Link>
      ),
      className: "col-customer"
    },
    {
      heading: "Trip",
      render: ent =>
        ent.trip ? (
          <Link to={`/sales/trips/${ent.trip?.id}`}>{ent.trip?.name}</Link>
        ) : null,
      className: "col-trip"
    }
  ]
};
