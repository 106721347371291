import * as React from "react";
import { Home } from "./routes";

import { Loading, Login } from "./components/auth";

import "./styles/app.scss";
import { makeClient } from "./apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter, MemoryRouter } from "react-router-dom";
import { ModalProvider } from "react-modal-hook";
import { useAuth0 } from "@auth0/auth0-react";
import { ApolloClient } from "@apollo/client";

const App: React.FC<{}> = () => {
  const {
    isLoading,
    isAuthenticated,
    error,
    getAccessTokenWithPopup,
    getAccessTokenSilently,
  } = useAuth0();

  const [client, setClient] = React.useState<ApolloClient<any> | null>(null);

  React.useEffect(() => {
    (async () => {
      if (!isLoading && isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: "https://jambo.takeoffgo.com/",
            },
          });
          setClient(makeClient(accessToken));
        } catch (ex) {
          console.log(`count not get access token silently`, ex);
          const accessToken = await getAccessTokenWithPopup({
            authorizationParams: {
              audience: "https://jambo.takeoffgo.com/",
            },
          });
          setClient(makeClient(accessToken ?? null));
        }
      }
    })();
  }, [
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isAuthenticated,
    isLoading,
  ]);

  if (error) {
    <pre>{JSON.stringify(error, null, 2)}</pre>;
  }
  if (isLoading) {
    return (
      <MemoryRouter>
        <Loading />
      </MemoryRouter>
    );
  }

  if (!isAuthenticated) {
    return (
      <BrowserRouter>
        <Login />
      </BrowserRouter>
    );
  }

  if (!client) {
    return (
      <MemoryRouter>
        <Loading />
      </MemoryRouter>
    );
  }

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ModalProvider>
          <Home />
        </ModalProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
