import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { LegalFolder } from "../../types/graphql";

export const listing: Listing<LegalFolder> = {
  fields: [
    {
      heading: "Name",
      render: (ent) => <Link to={`/legal/folders/${ent.id}`}>{ent.name}</Link>,
    },
    {
      heading: "Parent",
      render: (ent) => ent.parent?.name,
    },
  ],
};
