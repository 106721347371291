import * as React from "react";
import { LinkField } from "use-graphile/dist/fields/link";
import { SelectButton } from "./SelectButton";
import { Model } from "types/Model";
import { Link } from "react-router-dom";
import { Columns, Column } from "components/Layout";

type Props<T> = {
  label?: string;
  field: LinkField<T>;
  model: Model<T>;
  display: (ent: T) => string;
  buttons?: React.DOMAttributes<HTMLButtonElement>[];
};

export const SelectorField = <T extends { id: string }>({
  label,
  field,
  model,
  display,
  buttons,
}: Props<T>) => {
  const selectButtonRef = React.createRef<HTMLButtonElement>();
  return (
    <>
      {label && (
        <label className="label">
          <Columns mobile>
            <Column>{label}</Column>
            {field.value && model.detailUrl && (
              <Column narrow>
                <small>
                  <Link to={model.detailUrl(field.value)}>
                    <span className="icon">
                      <i className="fad fa-external-link"></i>
                    </span>
                  </Link>
                </small>
              </Column>
            )}
          </Columns>
        </label>
      )}
      <div className="field has-addons">
        <div className="control is-expanded">
          <input
            className="input"
            value={field.value ? display(field.value) : ""}
            readOnly
            onKeyDown={(ev) =>
              ev.key === "/" && selectButtonRef.current?.click()
            }
          />
        </div>
        <SelectButton<T>
          model={model}
          value={field.display("id")}
          onSelect={field.onChange}
          title={label}
          selectButtonRef={selectButtonRef}
        />
        {buttons?.map((ent, idx) => (
          <div className="control" key={idx}>
            <button {...ent} />
          </div>
        ))}
      </div>
    </>
  );
};
