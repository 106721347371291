import { Model } from "use-graphile";

export const model: Model = {
  Traveller: {
    lastName: "string",
    firstName: "string",
    middleName: "string",
    email: "string",
    dateOfBirth: "string",
    phone: "string",
    nationality: "string",
    emergencyContact: "string",
    height: "number",
    weight: "number",
    insuranceDetails: "string",
    medicalConditions: "string",
    notes: "string",
    passports: {
      type: "Passport",
      multi: true,
      patchProperty: "passportsUsingId"
    }
  },
  Passport: {
    number: "string",
    expiry: "date",
    country: {
      type: "Country",
      patchProperty: "countryToCountryId",
      patchWorkaroundProperty: "countryId"
    }
  }
};
