import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "types/Model";
import { Quote } from "types/graphql";
import { money, date } from "components/Format";
import { QuoteStatus } from "./model";

export const listing: Listing<Quote> = {
  rowClassName: (ent) => {
    switch (ent.status) {
      case 0 /*Active*/:
      case 1 /*Confirmed*/:
        return "";
      default:
        return "is-muted";
    }
  },
  fields: [
    {
      heading: "Key",
      render: (ent) => (
        <div className="icon-text key">
          <span>
            <Link to={`/sales/quotes/${ent.id}`}>{ent.key}</Link>
          </span>
          <a
            className="icon is-small"
            target="_blank"
            href={`https://www.takeoffgo.com/quote/${ent.key}?preview=true`}
            rel="noreferrer"
          >
            <i className="fad fa-external-link" />
          </a>
        </div>
      ),
      className: "col-key",
    },
    {
      heading: "Start",
      render: (ent) => date(ent.start),
      className: "col-date",
    },
    {
      heading: "Trip",
      render: (ent) => (
        <Link to={`/sales/trips/${ent.trip?.id}`}>{ent.trip?.name}</Link>
      ),
      className: "col-trip",
    },
    {
      heading: "Status",
      render: (ent) => QuoteStatus[ent.status],
      className: "col-status",
    },
    {
      heading: "Description",
      render: (ent) => ent.shortDescription,
      className: "col-description",
    },
    {
      heading: "Total",
      render: (ent) => money(ent.total, ent.baseCurrency),
      className: "col-amount",
      right: true,
    },
    {
      heading: "Last viewed",
      render: (ent) => date(ent.lastViewed),
      className: "col-date",
    },
  ],
};
