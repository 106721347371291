import * as React from "react";
import { useModal } from "react-modal-hook";
import { Listing } from "../routes/Listing";
import { Model } from "../../types/Model";

type SelectButtonProps<T> = {
  model: Model<T>;
  value: T | null;
  onSelect: (value: T | null) => void;
  title?: string;
  selectButtonRef?: React.RefObject<HTMLButtonElement>;
};

export function SelectButton<T>({
  model,
  value,
  onSelect,
  title,
  selectButtonRef
}: SelectButtonProps<T>) {
  const [showModal, hideModal] = useModal(() => {
    const handleSelect = (value: any) => {
      hideModal();
      onSelect(value);
    };

    return (
      <div className="modal is-active" tabIndex={0}>
        <div className="modal-background" onClick={hideModal} />
        <div className="modal-card">
          <header className="modal-card-head">
            <span className="modal-card-title">{title}</span>
            <button type="button" className="delete" onClick={hideModal} />
          </header>
          <section className="modal-card-body">
            <Listing<T> model={model} value={value} onSelect={handleSelect} />
          </section>
          <footer className="modal-card-foot has-text-right">
            <button className="button" onClick={hideModal}>
              Cancel
            </button>
          </footer>
        </div>
      </div>
    );
  }, [value, onSelect]);

  const handleClear = () => onSelect(null);

  return (
    <>
      <div className="control">
        <button
          type="button"
          className="button"
          onClick={handleClear}
          disabled={!value}
        >
          <span className="icon">
            <i className="fad fa-eraser" />
          </span>
        </button>
      </div>
      <div className="control">
        <button
          type="button"
          className="button is-info"
          onClick={showModal}
          ref={selectButtonRef}
        >
          <span className="icon">
            <i className="fad fa-search" />
          </span>
        </button>
      </div>
    </>
  );
}
