import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import {
  Expense,
  Supplier,
  FinanceCategory,
  Trip,
  Invoice,
  SupplierInvoice
} from "../../../types/graphql";
import {
  SupplierSelector,
  TripSelector,
  FinanceCategorySelector,
  InvoiceSelector,
  SupplierInvoiceSelector
} from "components/Selectors";
import { DateField, AmountField } from "components/Layout/Field";

export const Editor: React.FC<DetailProps<Expense>> = ({ g, entity }) => (
  <>
    <Columns>
      <Column>
        <AmountField label="Amount" {...g.input("amount")} />
      </Column>
      <Column>
        <InputField label="Currency" {...g.input("currency")} />
      </Column>
      <Column>
        <AmountField label="Amount actual" {...g.input("amountActual")} />
      </Column>
      <Column>
        <DateField label="Paid" {...g.input("paid")} />
      </Column>
    </Columns>
    <Columns>
      <Column>
        {g.link<Supplier>("supplier", field => (
          <SupplierSelector field={field} />
        ))}
      </Column>
      <Column>
        {g.link<Trip>("trip", field => (
          <TripSelector field={field} />
        ))}
      </Column>
      <Column>
        {g.link<FinanceCategory>("category", field => (
          <FinanceCategorySelector field={field} />
        ))}
      </Column>
    </Columns>
    <Columns>
      <Column>
        {g.link<Invoice>("invoice", field => (
          <InvoiceSelector field={field} />
        ))}
      </Column>
      <Column>
        {g.link<SupplierInvoice>("supplierInvoice", field => (
          <SupplierInvoiceSelector field={field} />
        ))}
      </Column>
    </Columns>
  </>
);
