import * as React from "react";
import numeral from "numeral";
import { Field, Control } from "components/Layout";
import { getExchangeRates } from "../../../util";

const QuickQuote: React.FC = () => {
  const calculate = (input: any): any => {
    const amount = input.amount / input.rate;
    const margin = amount * (input.margin / 100);
    const total = amount + margin;
    const deposit = total * (input.deposit / 100);
    return {
      amount,
      margin,
      total,
      deposit
    };
  };

  const reducer = (state: any, action: any): any => {
    switch (action.type) {
      case "change":
        const valueConverter: any = {
          number: (input: any) => {
            try {
              return parseFloat(input);
            } catch {
              return 0;
            }
          }
        };
        const input = {
          ...state.input,
          [action.field]: valueConverter[action.inputType]
            ? valueConverter[action.inputType](action.value)
            : action.value
        };
        return {
          ...state,
          input,
          output: calculate(input)
        };
    }
  };

  const initialInput = { amount: 100, rate: 1, margin: 20, deposit: 30 };
  const initialOutput = calculate(initialInput);
  const [state, dispatch] = React.useReducer(reducer, {
    input: initialInput,
    output: initialOutput
  });

  const [rates, setRates] = React.useState({});
  React.useEffect(() => {
    getExchangeRates().then(setRates);
  }, []);

  const handleChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    dispatch({
      type: "change",
      field: ev.currentTarget.name,
      value: ev.currentTarget.value,
      inputType: ev.currentTarget.type
    });
  };

  const usefulRates = ["AUD", "USD", "ZAR", "GBP"];

  return (
    <>
      <div className="content">
        <p>Calculate indicative quote prices.</p>
      </div>
      <form>
        <Field>
          <label className="label">Amount ($)</label>
          <Control>
            <input
              type="number"
              className="input"
              onChange={handleChange}
              name="amount"
              value={state.input.amount}
              step={0.01}
            />
          </Control>
        </Field>
        <Field>
          <label className="label">Exchange rate</label>
          <Control>
            <input
              type="number"
              className="input"
              onChange={handleChange}
              name="rate"
              value={state.input.rate}
              step={0.0001}
            />
          </Control>
          <p className="help">
            {usefulRates
              .map(
                cur =>
                  `${cur}: ${(
                    (rates as any)[cur] / (rates as any)["AUD"]
                  ).toFixed(4)}`
              )
              .join(", ")}
          </p>
          <p className="help">
            See{" "}
            <a href="https://xe.com" target="_blank" rel="noopener noreferrer">
              XE
            </a>{" "}
            for current rates.
          </p>
        </Field>
        <Field>
          <label className="label">Margin (%)</label>
          <Control>
            <input
              type="number"
              className="input"
              onChange={handleChange}
              name="margin"
              value={state.input.margin}
              step={1}
            />
          </Control>
        </Field>
        <Field>
          <label className="label">Deposit (%)</label>
          <Control>
            <input
              type="number"
              className="input"
              onChange={handleChange}
              name="deposit"
              value={state.input.deposit}
              step={1}
            />
          </Control>
        </Field>
      </form>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Amount</th>
            <td className="has-text-right">
              {numeral(state.output.amount).format("0,0.00")}
            </td>
          </tr>
          <tr>
            <th>Margin</th>
            <td className="has-text-right">
              {numeral(state.output.margin).format("0,0.00")}
            </td>
          </tr>
          <tr>
            <th>Total</th>
            <td className="has-text-right is-primary has-text-weight-bold">
              {numeral(state.output.total).format("0,0.00")}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Deposit</th>
            <td className="has-text-right">
              {numeral(state.output.deposit).format("0,0.00")}
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default QuickQuote;
