import type { Queries } from "types/Model";
import {
  Agency,
  useListAgenciesQuery,
  useGetAgencyQuery,
  useUpdateAgencyMutation,
  useCreateAgencyMutation
} from "types/graphql";

export const queries: Queries<Agency> = {
  list: useListAgenciesQuery,
  create: useCreateAgencyMutation,
  detail: useGetAgencyQuery,
  update: useUpdateAgencyMutation
};
