import * as React from "react";
import { DetailProps } from "../../../types/types";
import { Trip } from "../../../types/graphql";
import { Columns, Column, Section } from "../../../components/Layout";
import { Overview } from "./Overview";
import { QuoteList } from "./Detail/QuoteList";
import { FlightList } from "./Detail/FlightList";
import { TravellerList } from "./Detail/TravellerList";
import { InvoiceList } from "./Detail/finance/InvoiceList";
import { ExpenseList } from "./Detail/finance/ExpenseList";
import { Tabs, Tab } from "../../../components/Tabs";
import { SupplierInvoiceList } from "./Detail/finance/SupplierInvoiceList";
import { PaymentList } from "./Detail/finance/PaymentList";
import { FinancialBreakdown } from "./Detail/finance/FinancialBreakdown";
import { FileList } from "./Detail/FileList";

export const View: React.FC<DetailProps<Trip>> = props => {
  return (
    <Section>
      <Columns>
        <Column span={4}>
          <Overview {...props} />
          <hr />
          <TravellerList {...props} />
          <hr />
          <FileList {...props} />
        </Column>
        <Column span={8}>
          <QuoteList {...props} />
          <Columns>
            <Column span={6}>
              <FlightList {...props} />
            </Column>
            <Column span={6}>
              <Tabs>
                <Tab title="Summary">
                  <FinancialBreakdown {...props} />
                </Tab>
                <Tab title="Invoices">
                  <InvoiceList {...props} />
                </Tab>
                <Tab title="Payments">
                  <PaymentList {...props} />
                </Tab>
                <Tab title="Supplier  invoices">
                  <SupplierInvoiceList {...props} />
                </Tab>
                <Tab title="Expenses">
                  <ExpenseList {...props} />
                </Tab>
              </Tabs>
            </Column>
          </Columns>
        </Column>
      </Columns>
    </Section>
  );
};
