import { Queries } from "types/Model";
import {
  SupplierInvoice,
  useListSupplierInvoicesQuery,
  useGetSupplierInvoiceQuery,
  useUpdateSupplierInvoiceMutation,
  useCreateSupplierInvoiceMutation
} from "types/graphql";

export const queries: Queries<SupplierInvoice> = {
  list: useListSupplierInvoicesQuery,
  create: useCreateSupplierInvoiceMutation,
  detail: useGetSupplierInvoiceQuery,
  update: useUpdateSupplierInvoiceMutation
};
