import * as React from "react";
import { InputField, Column, Columns } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Traveller } from "../../../types/graphql";
import { DateField, TextAreaField } from "components/Layout/Field";

export const Editor: React.FC<DetailProps<Traveller>> = ({ g, entity }) => (
  <>
    <Columns>
      <Column>
        <InputField
          className="input is-large"
          placeholder="Last name"
          autoFocus={!entity?.id}
          {...g.input("lastName")}
        />
      </Column>
      <Column>
        <InputField
          className="input is-large"
          placeholder="First name"
          {...g.input("firstName")}
        />
      </Column>
      <Column>
        <InputField
          className="input is-large"
          placeholder="Middle name"
          {...g.input("middleName")}
        />
      </Column>
    </Columns>
    <Columns>
      <Column span={2}>
        <DateField label="Date of birth" {...g.input("dateOfBirth")} />
      </Column>
      <Column>
        <InputField label="Email" {...g.input("email")} />
      </Column>
      <Column span={3}>
        <InputField label="Phone" {...g.input("phone")} />
      </Column>
    </Columns>
    <Columns>
      <Column>
        <InputField label="Nationality" {...g.input("nationality")} />
      </Column>
      <Column>
        <InputField
          label="Emergency contact"
          {...g.input("emergencyContact")}
        />
      </Column>
      <Column span={2}>
        <InputField label="Height" {...g.input("height")} />
      </Column>
      <Column span={2}>
        <InputField label="Weight" {...g.input("weight")} />
      </Column>
    </Columns>
    <Columns>
      <Column>
        <TextAreaField
          label="Insurance details"
          {...g.textarea("insuranceDetails")}
        />
      </Column>
      <Column>
        <TextAreaField
          label="Medical conditions"
          {...g.textarea("medicalConditions")}
        />
      </Column>
    </Columns>
    <TextAreaField label="Notes" {...g.textarea("notes")} />
  </>
);
