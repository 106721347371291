import * as React from "react";
import { Column, Columns, InputField } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { User } from "../../../types/graphql";

export const Editor: React.FC<DetailProps<User>> = ({ g, entity }) => (
  <>
    <Columns>
      <Column>
        <InputField
          className="input is-large"
          placeholder="First name"
          autoFocus={!entity?.id}
          {...g.input("firstName")}
        />
      </Column>
      <Column>
        <InputField
          className="input is-large"
          placeholder="Last name"
          {...g.input("lastName")}
        />
      </Column>
    </Columns>
    <Columns>
      <Column>
        <InputField
          label="Email"
          className="input"
          inputMode="email"
          {...g.input("email")}
        />
      </Column>
      <Column>
        <InputField
          label="Phone"
          className="input"
          inputMode="tel"
          {...g.input("phone")}
        />
      </Column>
      <Column>
        <InputField
          label="Pronoun"
          className="input"
          {...g.input("genderPreposition")}
        />
      </Column>
    </Columns>
  </>
);
