import * as React from "react";
import { Columns, Column, Card, Container, Section } from "components/Layout";
import StripeFees from "./components/StripeFees";
import QuickQuote from "./components/QuickQuote";

export const Utilities: React.FC = () => (
  <Container>
    <Section>
      <Columns>
        <Column span={3}>
          <Card>
            <Card.Header>
              <Card.Header.Title>Quick quote</Card.Header.Title>
            </Card.Header>
            <Card.Content>
              <QuickQuote />
            </Card.Content>
          </Card>
        </Column>
        <Column span={3}>
          <Card>
            <Card.Header>
              <Card.Header.Title>Stripe fees</Card.Header.Title>
            </Card.Header>
            <Card.Content>
              <StripeFees />
            </Card.Content>
          </Card>
        </Column>
      </Columns>
    </Section>
  </Container>
);
