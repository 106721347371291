import { Queries } from "types/Model";
import {
  FinanceCategory,
  useListFinanceCategoriesQuery,
  useGetFinanceCategoryQuery,
  useUpdateFinanceCategoryMutation,
  useCreateFinanceCategoryMutation
} from "types/graphql";

export const queries: Queries<FinanceCategory> = {
  list: useListFinanceCategoriesQuery,
  create: useCreateFinanceCategoryMutation,
  detail: useGetFinanceCategoryQuery,
  update: useUpdateFinanceCategoryMutation
};
