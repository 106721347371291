import * as React from "react";
import { Traveller, Passport, Country } from "types/graphql";
import { DetailProps } from "types/types";
import { Box, Columns, Column, InputField } from "components/Layout";
import cx from "classnames";
import moment from "moment";
import { DateField } from "components/Layout/Field";
import { CountrySelector } from "components/Selectors";

export const Passports: React.FC<DetailProps<Traveller>> = ({ g }) =>
  g.multi<Passport>("passports", passports => (
    <Box>
      {passports.renderItems(passport => (
        <Columns>
          <Column span={2}>
            <InputField label="Number" {...passport.input("number")} />
          </Column>
          <Column span={2}>
            <DateField
              label="Date of expiry"
              className={cx("input", {
                "is-danger": moment(passport.item.expiry).isBefore(moment())
              })}
              {...passport.input("expiry")}
            />
          </Column>
          <Column>
            {passport.link<Country>("country", field => (
              <CountrySelector
                field={field}
                buttons={[
                  {
                    className: "button is-warning",
                    children: (
                      <span className="icon">
                        <i className="fad fa-trash"></i>
                      </span>
                    ),
                    onClick: passport.remove
                  } as React.DOMAttributes<HTMLButtonElement>
                ]}
              />
            ))}
          </Column>
        </Columns>
      ))}
      <div className="buttons is-right">
        <button className="button is-info" onClick={passports.add}>
          Add
        </button>
      </div>
    </Box>
  ));
