import * as React from "react";
import numeral from "numeral";
import { Field, Control, Columns, Column } from "components/Layout";

type Input = {
  amount: number;
  includeFee: boolean;
  feeFixed: number;
  feeVariable: number;
  taxRate: number;
};

type Tax = {
  tax: number;
  taxIncl: number;
  taxExcl: number;
};

type Output = {
  amount: number;
  fee: Tax;
  total: number;
};

type State = {
  input: Input;
  output: Output;
};

const calculateTax = (input: number, taxRate: number): Tax => {
  const taxExcl = input / (1 + taxRate);
  const tax = input - taxExcl;
  return {
    taxIncl: input,
    tax,
    taxExcl,
  };
};

const calculate = (input: Input): Output => {
  const taxRate = input.taxRate / 100;
  const feeFixedTaxInc = input.feeFixed;
  const feeVariableTaxInc = input.feeVariable / 100;

  if (input.includeFee) {
    const amount = input.amount * (1 - feeVariableTaxInc) - feeFixedTaxInc;
    const fee = calculateTax(input.amount - amount, taxRate);
    return {
      amount,
      fee,
      total: input.amount,
    };
  } else {
    const total = (input.amount + feeFixedTaxInc) / (1 - feeVariableTaxInc);
    const fee = calculateTax(total - input.amount, taxRate);
    return {
      amount: input.amount,
      fee,
      total,
    };
  }
};

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case "change":
      const valueConverter: any = {
        number: (input: any) => {
          try {
            return parseFloat(input) || input;
          } catch {
            return input;
          }
        },
      };
      const input = {
        ...state.input,
        [action.field]: valueConverter[action.inputType]
          ? valueConverter[action.inputType](action.value)
          : action.value,
      };
      return {
        ...state,
        input,
        output: calculate(input),
      };
  }

  return state;
};

const initialInput: Input = {
  amount: 1000,
  feeVariable: 1.75,
  feeFixed: 0.3,
  taxRate: 10,
  includeFee: true,
};
const initialOutput = calculate(initialInput);

const StripeFees: React.FC = () => {
  const initialState: State = {
    input: initialInput,
    output: initialOutput,
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    dispatch({
      type: "change",
      field: ev.currentTarget.name,
      value:
        ev.currentTarget.type === "checkbox"
          ? (ev.currentTarget as HTMLInputElement).checked
          : ev.currentTarget.value,
      inputType: ev.currentTarget.type,
    });
  };

  return (
    <>
      <div className="content">
        <p>
          Calculate Stripe card fees. This can be used to determine how much to
          charge if you wish to pass on the card fees to your customer.
        </p>
      </div>
      <form>
        <Field>
          <label className="label">Amount ($)</label>
          <Control>
            <input
              type="number"
              className="input"
              onChange={handleChange}
              name="amount"
              value={state.input.amount.toString()}
              step={0.01}
            />
          </Control>
        </Field>
        <Field>
          <label className="checkbox">
            <input
              type="checkbox"
              name="includeFee"
              checked={state.input.includeFee}
              onChange={handleChange}
            />
            <span>Include fee</span>
          </label>
        </Field>
        <Columns>
          <Column>
            <Field>
              <label className="label">Fee (%)</label>
              <Control>
                <input
                  type="number"
                  className="input"
                  onChange={handleChange}
                  name="feeVariable"
                  value={state.input.feeVariable.toString()}
                  step={0.0001}
                />
              </Control>

              <ul className="help">
                <li>1.75% domestic</li>
                <li>2.90% international</li>
              </ul>
            </Field>
          </Column>
          <Column>
            <Field>
              <label className="label">Fee ($)</label>
              <Control>
                <input
                  type="number"
                  className="input"
                  onChange={handleChange}
                  name="feeFixed"
                  value={state.input.feeFixed.toString()}
                  step={0.0001}
                />
              </Control>
              <ul className="help">
                <li>Generally - $0.30</li>
              </ul>
            </Field>
          </Column>
          <Column>
            <Field>
              <label className="label">Tax (%)</label>
              <Control>
                <input
                  type="number"
                  className="input"
                  onChange={handleChange}
                  name="taxRate"
                  value={state.input.taxRate.toString()}
                  step={0.0001}
                />
              </Control>
              <ul className="help">
                <li>10% GST in Australia</li>
              </ul>
            </Field>
          </Column>
        </Columns>
      </form>
      <hr />
      <table className="table is-striped is-fullwidth">
        <tbody>
          {state.output.amount ? (
            <tr>
              <th>Amount received</th>
              <td className="has-text-right">
                {numeral(state.output.amount).format("0,0.00")}
              </td>
            </tr>
          ) : null}
          <tr>
            <th>Fee</th>
            <td className="has-text-right">
              {numeral(state.output.fee.taxIncl).format("0,0.00")}
            </td>
          </tr>
          {state.input.taxRate ? (
            <React.Fragment>
              <tr>
                <td>
                  <small>Fee (tax excl.)</small>
                </td>
                <td className="has-text-right">
                  <small>
                    {numeral(state.output.fee.taxExcl).format("0,0.00")}
                  </small>
                </td>
              </tr>
              <tr>
                <td>
                  <small>Fee (tax)</small>
                </td>
                <td className="has-text-right">
                  <small>
                    {numeral(state.output.fee.tax).format("0,0.00")}
                  </small>
                </td>
              </tr>
            </React.Fragment>
          ) : null}
          <tr>
            <th>Total charged</th>
            <td className="has-text-right">
              {numeral(state.output.total).format("0,0.00")}
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <div className="content">
        <p>
          In some jurisdictions, charging processing fees to your customers is
          prohibited by law.
        </p>
        <p>This calculator assumes that tax is included in the fee values.</p>
      </div>
    </>
  );
};

export default StripeFees;
