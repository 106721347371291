import { Queries } from "types/Model";
import {
  Destination,
  useListDestinationsQuery,
  useGetDestinationQuery,
  useUpdateDestinationMutation,
  useCreateDestinationMutation
} from "types/graphql";

export const queries: Queries<Destination> = {
  list: useListDestinationsQuery,
  create: useCreateDestinationMutation,
  detail: useGetDestinationQuery,
  update: useUpdateDestinationMutation
};
