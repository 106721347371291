import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { User } from "../../types/graphql";

export const listing: Listing<User> = {
  fields: [
    {
      heading: "Last name",
      render: ent => <Link to={`/system/users/${ent.id}`}>{ent.lastName}</Link>
    },
    {
      heading: "First name",
      render: ent => ent.firstName
    }
  ]
};
