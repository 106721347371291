import * as React from "react";
import { Tabs, Tab } from "components/Tabs";
import { Expenses } from "./Expenses";
import { Invoices } from "./Invoices";
import { DetailProps } from "types/types";
import { Supplier } from "types/graphql";

export const Detail: React.FC<DetailProps<Supplier>> = props => (
  <Tabs>
    <Tab title="Expenses">
      <Expenses {...props} />
    </Tab>
    <Tab title="Invoices">
      <Invoices {...props} />
    </Tab>
  </Tabs>
);
