import * as React from "react";
import { DetailProps } from "../../../../types/types";
import { Customer } from "../../../../types/graphql";
import { Tabs, Tab } from "../../../../components/Tabs";
import { Trips } from "./Trips";
import { Enquiries } from "./Enquiries";
import { Travellers } from "./Travellers";

export const Detail: React.FC<DetailProps<Customer>> = props => {
  return (
    <Tabs>
      <Tab key="trips" title="Trips">
        <Trips {...props} />
      </Tab>
      <Tab key="enquiries" title="Enquiries">
        <Enquiries {...props} />
      </Tab>
      <Tab key="travellers" title="Travellers">
        <Travellers {...props} />
      </Tab>
    </Tabs>
  );
};
