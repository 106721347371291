import * as React from "react";

import { model } from "entities/Quote/model";
import { Quote, Property } from "types/graphql";
import { ListingTable } from "components/ListingTable";
import { DetailProps } from "types/types";

export const Quotes: React.FC<DetailProps<Property>> = ({ entity }) => (
  <ListingTable<Quote>
    items={
      entity.quoteAccommodationDetails?.nodes.map(
        (ent) => ent?.quote
      ) as Quote[]
    }
    model={model}
  />
);
