import * as React from "react";
import { model as travellerModel } from "entities/Traveller/model";
import { Traveller } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const TravellerSelector: React.FC<SelectorProps<Traveller>> = ({
  label,
  ...rest
}) => (
  <SelectorField<Traveller>
    model={travellerModel}
    label={label ?? "Traveller"}
    display={ent =>
      [ent.lastName, ent.firstName].filter(a => !!a).join(", ") ?? "(unnamed)"
    }
    {...rest}
  />
);
