import { Model } from "../../types/Model";
import { queries } from "./query";
import { listing } from "./listing";
import { graphileModel } from "../graphile";

import { View as display } from "./components/View";
import { User } from "../../types/graphql";
import { Editor as editor } from "./components/Editor";

export const model: Model<User> = {
  // editing
  graphileEntity: "User",
  graphileModel,
  // querying
  queries,
  // displaying
  listing,
  display,
  editor,
  // misc.
  breadcrumb: () => [
    { target: ["system"], label: "System" },
    { target: ["system", "users"], label: "Users" }
  ]
};
