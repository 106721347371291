import * as React from "react";
import { useDropzone } from "react-dropzone";
import cx from "classnames";
import { Columns, Column } from "components/Layout";
import {
  useUploadUrlMutation,
  useCreateMediaItemMutation,
  MediaGallery,
  MediaItem
} from "types/graphql";
import md5 from "../md5";

type Props = {
  gallery: MediaGallery;
  onUpload: () => void;
};

const calculateHash = async (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (ev: any) => {
      if (ev.target && file) {
        switch (ev.target.readyState) {
          case FileReader.DONE:
            const hash = md5(ev.target.result);
            resolve(hash);
            break;
          default:
            break;
        }
      }
    };
    reader.readAsBinaryString(file as Blob);
  });

enum Status {
  Pending,
  Success,
  Failed
}

type FileStatus = {
  file: File;
  status: Status;
  hash?: string;
  uploadUrl?: string;
  progress?: number;
  mediaItem?: MediaItem;
};

export const Upload: React.FC<Props> = ({ gallery, onUpload }) => {
  const [uploadUrl] = useUploadUrlMutation();
  const [upload] = useCreateMediaItemMutation();
  const [files, setFiles] = React.useState<FileStatus[]>([]);
  const [loading, setLoading] = React.useState(false);
  const onDrop = (acceptedFiles: File[]) => {
    setFiles([
      ...files,
      ...acceptedFiles.map(file => ({
        file,
        status: Status.Pending
      }))
    ]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true
  });

  const handleUpload = async () => {
    setLoading(true);
    // 1. calculate file hash
    const withHash = await Promise.all(
      files.map(async f => {
        if (f.hash) {
          return f;
        }
        return {
          ...f,
          hash: await calculateHash(f.file)
        };
      })
    );

    // 2. get upload url
    const withUrl = await Promise.all(
      withHash.map(async f => {
        if (f.uploadUrl) {
          return f;
        }
        const url = await uploadUrl({
          variables: {
            input: {
              contentType: f.file.type,
              hash: f.hash ?? "",
              protected: gallery.isProtected ?? false
            }
          }
        });

        return {
          ...f,
          uploadUrl: url.data?.generateUploadUrl ?? ""
        };
      })
    );

    // 3. upload files
    const withSuccess = await Promise.all(
      withUrl
        .filter(ent => ent.uploadUrl && ent.status === Status.Pending)
        .map(async ent => ({
          ...ent,
          status: await fetch(ent.uploadUrl as string, {
            method: "PUT",
            headers: {
              "Content-Type": ent.file.type,
              "x-amz-tagging": `Protected=${
                gallery.isProtected ? "true" : "false"
              }`
            },
            body: ent.file
          }).then(res => (res.ok ? Status.Success : Status.Failed))
        }))
    );

    // 4. create media items
    const created = await Promise.all(
      withSuccess
        .filter(f => !f.mediaItem)
        .map(async ent => ({
          ...ent,
          mediaItem: (
            await upload({
              variables: {
                input: {
                  mediaItem: {
                    fileName: ent.file.name,
                    contentType: ent.file.type,
                    hash: ent.hash,
                    mediaGalleryItemsUsingId: {
                      create: [{ mediaGalleryId: gallery.id }]
                    }
                  }
                }
              }
            })
          ).data?.createMediaItem?.mediaItem as MediaItem
        }))
    );

    setFiles(created);
    setLoading(false);

    onUpload();
  };

  return (
    <>
      <div className="message" {...getRootProps()}>
        <div className="message-body">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drop some files here, or click to select files</p>
          )}
        </div>
      </div>
      {files.length === 0 ? null : (
        <>
          {files.map((f, idx) => (
            <Columns key={idx}>
              <Column>{f.file.name}</Column>
              <Column narrow>
                {f.status === Status.Pending ? (
                  <button
                    className="button is-small"
                    onClick={() => setFiles(files.filter(ff => f !== ff))}
                    disabled={loading}
                  >
                    Remove
                  </button>
                ) : null}
                {f.status === Status.Success ? (
                  <span className="tag is-success">Uploaded</span>
                ) : null}
                {f.status === Status.Failed ? (
                  <span className="tag is-warning">Failed</span>
                ) : null}
              </Column>
            </Columns>
          ))}
          <div className="buttons is-right">
            <button
              className="button"
              onClick={() => setFiles([])}
              disabled={files.length === 0 || loading}
            >
              Reset
            </button>
            <button
              className={cx("button is-info", { "is-loading": loading })}
              onClick={handleUpload}
            >
              Upload
            </button>
          </div>
        </>
      )}
    </>
  );
};
