import * as React from "react";
import { InputField, Columns, Column } from "components/Layout";
import { DetailProps } from "types/types";
import { Destination, MediaGallery } from "types/graphql";
import {
  CountrySelector,
  GallerySelector,
  MapSelector,
} from "components/Selectors";
import { TextAreaField } from "components/Layout/Field";

export const Editor: React.FC<DetailProps<Destination>> = ({ g, entity }) => (
  <>
    <InputField
      className="input is-large"
      placeholder="Name"
      autoFocus={!entity?.id}
      {...g.input("name")}
    />
    <Columns>
      <Column>
        {g.link("country", (country) => (
          <CountrySelector field={country} />
        ))}
        {g.link<MediaGallery>("gallery", (field) => (
          <GallerySelector field={field} />
        ))}
        <TextAreaField label="Summary" {...g.textarea("body")} />
      </Column>
      <Column>
        <MapSelector
          field={{
            onChange: (val) => g.set({ ...g.values, ...val }),
            value: g.values,
            initialValue: g.values,
            ...g,
            set: () => null,
          }}
        />
      </Column>
    </Columns>
  </>
);
