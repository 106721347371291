import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { AgencyMember } from "../../types/graphql";

export const listing: Listing<AgencyMember> = {
  fields: [
    {
      heading: "Name",
      render: (ent) => (
        <Link to={`/sales/agencies/${ent.agency?.id}/members/${ent.id}`}>
          {[ent.lastName, ent.firstName].filter((a) => !!a).join(", ")}
        </Link>
      ),
    },
  ],
};
