import { Model } from "../../types/Model";
import { queries } from "./query";
import { listing } from "./listing";
import { graphileModel } from "../graphile";

import { View as display } from "./components/View";
import { Country } from "../../types/graphql";
import { Editor as editor } from "./components/Editor";

export const model: Model<Country> = {
  // editing
  graphileEntity: "Country",
  graphileModel,
  // querying
  queries,
  // displaying
  listing,
  display,
  editor,
  // misc.
  breadcrumb: () => [
    { target: ["product"], label: "Product" },
    { target: ["product", "countries"], label: "Countries" }
  ]
};
