import * as React from "react";
import { DetailProps } from "types/types";
import { Supplier, SupplierInvoice } from "types/graphql";
import { ListingTable } from "components/ListingTable";
import { model } from "entities/SupplierInvoice/model";
import { Create } from "components/entities/Create";

export const Invoices: React.FC<DetailProps<Supplier>> = ({
  entity,
  refetch
}) => {
  const initialValues = React.useMemo(
    () => ({
      supplier: {
        id: entity.id,
        name: entity.name
      }
    }),
    [entity.id, entity.name]
  );
  const createProps = Create<SupplierInvoice>({
    model,
    initialValues,
    onCreate: () => refetch()
  });

  return (
    <>
      <ListingTable<SupplierInvoice>
        items={entity.supplierInvoices.nodes as SupplierInvoice[]}
        model={model}
      />
      <hr />
      <div className="buttons is-right">
        <button
          type="button"
          className="button is-dark is-small"
          {...createProps}
        >
          Create
        </button>
      </div>
    </>
  );
};
