import * as React from "react";
import { model as propertyModel } from "entities/Property/model";
import { Property } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const PropertySelector: React.FC<SelectorProps<Property>> = ({
  label,
  ...rest
}) => (
  <SelectorField<Property>
    model={propertyModel}
    label={label ?? "Property"}
    display={ent => ent.name ?? "(unnamed)"}
    {...rest}
  />
);
