export const extractActiveFromHash = (instance?: string) => {
  if (window.location.hash) {
    const prefix = `#${instance ?? "tab"}-`;
    if (window.location.hash.startsWith(prefix)) {
      return parseInt(window.location.hash.substr(prefix.length), 10) || 0;
    }
  }

  return 0;
};
