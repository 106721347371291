import * as React from "react";
import { model as userModel } from "entities/User/model";
import { User } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const UserSelector: React.FC<SelectorProps<User>> = ({
  label,
  ...rest
}) => (
  <SelectorField<User>
    model={userModel}
    label={label ?? "User"}
    display={ent =>
      [ent.lastName, ent.firstName].filter(a => !!a).join(", ") ?? "(unnamed)"
    }
    {...rest}
  />
);
