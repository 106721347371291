import * as React from "react";
import { DetailProps } from "../../../../types/types";
import { Agency } from "../../../../types/graphql";
import { Tabs, Tab } from "../../../../components/Tabs";
import { Members } from "./Members";

export const Detail: React.FC<DetailProps<Agency>> = ({ entity }) => {
  return (
    <Tabs>
      <Tab key="members" title="Members">
        <Members {...entity} />
      </Tab>
    </Tabs>
  );
};
