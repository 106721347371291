import * as React from "react";

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  options: Array<string>;
};

export const Checkboxes: React.FC<Props> = ({
  options,
  onChange,
  value,
  disabled
}) => {
  const values =
    value instanceof Array ? value : ((value as string) || "").split(",");
  const handleClick = (str: string) => () => {
    if (values.indexOf(str) === -1) {
      const newValue = values.concat([str]).filter(a => !!a);
      onChange &&
        onChange({
          currentTarget: { value: newValue.join(",") }
        } as React.ChangeEvent<HTMLInputElement>);
    } else {
      const newValue = values.filter(x => x !== str).filter(a => !!a);
      onChange &&
        onChange({
          currentTarget: { value: newValue.join(",") }
        } as React.ChangeEvent<HTMLInputElement>);
    }
  };
  return (
    <div className="field is-grouped">
      {options.map((str, idx) => (
        <div key={idx} className="control">
          <label className="checkbox">
            <input
              type="checkbox"
              onChange={handleClick(str)}
              checked={values.indexOf(str) !== -1}
              disabled={disabled}
            />
            {` ${str}`}
          </label>
        </div>
      ))}
    </div>
  );
};
