import * as React from "react";
import { model as SupplierInvoiceModel } from "entities/SupplierInvoice/model";
import { SupplierInvoice } from "types/graphql";
import { SelectorField } from "./SelectorField";
import { money } from "components/Format";
import type { SelectorProps } from "./selectors";

export const SupplierInvoiceSelector: React.FC<SelectorProps<
  SupplierInvoice
>> = ({ label, ...rest }) => (
  <SelectorField<SupplierInvoice>
    model={SupplierInvoiceModel}
    label={label ?? "Supplier invoice"}
    display={ent =>
      `${ent.supplier?.name} (${money(ent.amount, ent.currency)})` ??
      "(unnamed)"
    }
    {...rest}
  />
);
