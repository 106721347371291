import * as React from "react";
import { Navbar } from "components/global";

import { Route, Routes } from "react-router";
import { Dashboard } from "./Dashboard";
import { Utilities } from "./Utilities";
import { Detail } from "components/routes/Detail";
import { Listing } from "components/routes/Listing";
import { Container, Section } from "components/Layout";
import { ModelProps } from "components/routes/types";

import {
  Agency,
  AgencyMember,
  Airport,
  Country,
  Customer,
  Destination,
  Expense,
  FinanceCategory,
  Invoice,
  LegalDocument,
  LegalFolder,
  MediaGallery,
  Payment,
  Property,
  Quote,
  Supplier,
  SupplierInvoice,
  Traveller,
  Trip,
  User,
} from "types/graphql";

import { model as agency } from "entities/Agency/model";
import { model as agencyMember } from "entities/AgencyMember/model";
import { model as airport } from "entities/Airport/model";
import { model as country } from "entities/Country/model";
import { model as customer } from "entities/Customer/model";
import { model as destination } from "entities/Destination/model";
import { model as expense } from "entities/Expense/model";
import { model as financeCategory } from "entities/FinanceCategory/model";
import { model as invoice } from "entities/Invoice/model";
import { model as mediaGallery } from "entities/MediaGallery/model";
import { model as payment } from "entities/Payment/model";
import { model as property } from "entities/Property/model";
import { model as quote } from "entities/Quote/model";
import { model as supplier } from "entities/Supplier/model";
import { model as supplierInvoice } from "entities/SupplierInvoice/model";
import { model as traveller } from "entities/Traveller/model";
import { model as trip } from "entities/Trip/model";
import { model as user } from "entities/User/model";
import { model as legalFolder } from "entities/LegalFolder/model";
import { model as legalDocument } from "entities/LegalDocument/model";

export const Home: React.FC = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Dashboard />} />

        <Route path="/utilities" element={<Utilities />} />

        <Route
          path="/product/properties/:id"
          element={<Detail<Property> model={property} />}
        />

        <Route
          path="/product/properties"
          element={<ListingRoute<Property> model={property} />}
        />

        <Route
          path="/product/countries/:id"
          element={<Detail<Country> model={country} />}
        />

        <Route
          path="/product/countries"
          element={<ListingRoute<Country> model={country} />}
        />

        <Route
          path="/product/airports/:id"
          element={<Detail<Airport> model={airport} />}
        />

        <Route
          path="/product/airports"
          element={<ListingRoute<Airport> model={airport} />}
        />

        <Route
          path="/product/galleries/:id"
          element={<Detail<MediaGallery> model={mediaGallery} />}
        />

        <Route
          path="/product/galleries"
          element={<ListingRoute<MediaGallery> model={mediaGallery} />}
        />

        <Route
          path="/product/destinations/:id"
          element={<Detail<Destination> model={destination} />}
        />

        <Route
          path="/product/destinations"
          element={<ListingRoute<Destination> model={destination} />}
        />

        <Route
          path="/sales/quotes/:id"
          element={<Detail<Quote> model={quote} />}
        />

        <Route
          path="/sales/quotes"
          element={<ListingRoute<Quote> model={quote} />}
        />

        <Route
          path="/sales/trips/:id"
          element={<Detail<Trip> model={trip} />}
        />

        <Route
          path="/sales/trips"
          element={<ListingRoute<Trip> model={trip} />}
        />

        <Route
          path="/sales/customers/:id"
          element={<Detail<Customer> model={customer} />}
        />

        <Route
          path="/sales/customers"
          element={<ListingRoute<Customer> model={customer} />}
        />

        <Route
          path="/sales/travellers/:id"
          element={<Detail<Traveller> model={traveller} />}
        />

        <Route
          path="/sales/travellers"
          element={<ListingRoute<Traveller> model={traveller} />}
        />

        <Route
          path="/sales/agencies/:agency/members/:id"
          element={<Detail<AgencyMember> model={agencyMember} />}
        />

        <Route
          path="/sales/agencies/member"
          element={<ListingRoute<AgencyMember> model={agencyMember} />}
        />

        <Route
          path="/sales/agencies/:id"
          element={<Detail<Agency> model={agency} />}
        />

        <Route
          path="/sales/agencies"
          element={<ListingRoute<Agency> model={agency} />}
        />

        <Route
          path="/finance/suppliers/:id"
          element={<Detail<Supplier> model={supplier} />}
        />

        <Route
          path="/finance/suppliers"
          element={<ListingRoute<Supplier> model={supplier} />}
        />

        <Route
          path="/finance/invoices/:id"
          element={<Detail<Invoice> model={invoice} />}
        />

        <Route
          path="/finance/invoices"
          element={<ListingRoute<Invoice> model={invoice} />}
        />

        <Route
          path="/finance/supplier-invoices/:id"
          element={<Detail<SupplierInvoice> model={supplierInvoice} />}
        />

        <Route
          path="/finance/supplier-invoices"
          element={<ListingRoute<SupplierInvoice> model={supplierInvoice} />}
        />

        <Route
          path="/finance/categories/:id"
          element={<Detail<FinanceCategory> model={financeCategory} />}
        />

        <Route
          path="/finance/categories"
          element={<ListingRoute<FinanceCategory> model={financeCategory} />}
        />

        <Route
          path="/finance/expenses/:id"
          element={<Detail<Expense> model={expense} />}
        />

        <Route
          path="/finance/expenses"
          element={<ListingRoute<Expense> model={expense} />}
        />

        <Route
          path="/finance/payments/:id"
          element={<Detail<Payment> model={payment} />}
        />

        <Route
          path="/finance/payments"
          element={<ListingRoute<Payment> model={payment} />}
        />

        <Route
          path="/legal/folders/:id"
          element={<Detail<LegalFolder> model={legalFolder} />}
        />

        <Route
          path="/legal/folders"
          element={<ListingRoute<LegalFolder> model={legalFolder} />}
        />

        <Route
          path="/legal/documents/:id"
          element={<Detail<LegalDocument> model={legalDocument} />}
        />

        <Route
          path="/legal/documents"
          element={<ListingRoute<LegalDocument> model={legalDocument} />}
        />

        <Route
          path="/system/users/:id"
          element={<Detail<User> model={user} />}
        />

        <Route
          path="/system/users"
          element={<ListingRoute<User> model={user} />}
        />
      </Routes>
    </>
  );
};

export function ListingRoute<T>({ model }: ModelProps<T>) {
  return (
    <Container>
      <Section>
        <Listing<T> model={model} />
      </Section>
    </Container>
  );
}
