import * as React from "react";

type Props = {
  title?: string;
  body: string;
};

export const ErrorMessage: React.FC<Props> = ({ title, body }) => (
  <div className="message is-danger">
    <div className="message-header">
      <span>{title || "Error"}</span>
      <span className="icon">
        <i className="fad fa-exclamation-triangle"></i>
      </span>
    </div>
    <div className="message-body">{body}</div>
  </div>
);
