import * as React from "react";
import { DetailProps } from "../../../../types/types";
import { User } from "../../../../types/graphql";
import { Tabs, Tab } from "../../../../components/Tabs";

export const Detail: React.FC<DetailProps<User>> = ({ entity }) => {
  return (
    <Tabs>
      <Tab key="quotes" title="Quotes">
        <div />
      </Tab>
    </Tabs>
  );
};
