import * as React from "react";
import { Card } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Invoice } from "../../../types/graphql";
import { money, date } from "components/Format";
import { Link } from "react-router-dom";

export const Overview: React.FC<DetailProps<Invoice>> = ({
  entity,
  actions
}) => {
  const footerButtons = [
    {
      href: `https://www.takeoffgo.com/payment?id=${entity.id}`,
      icon: "fad fa-credit-card",
      text: "Online payment"
    },
    {
      href: `https://www.takeoffgo.com/invoice?id=${entity.id}`,
      icon: "fad fa-file-invoice-dollar",
      text: "Printable invoice"
    }
  ];

  return (
    <>
      <Card>
        <Card.Content>
          <h4 className="title is-4">
            <Link to={`/sales/trips/${entity.trip?.id}`}>
              {entity.trip?.name}
            </Link>
          </h4>
          <h6 className="subtitle is-6">
            {money(entity.amount, entity.currency)}
          </h6>
          <dl>
            {entity.paid && (
              <>
                <dt>Paid</dt>
                <dd>{date(entity.paid)}</dd>
              </>
            )}
          </dl>
        </Card.Content>
        <Card.Footer>
          {footerButtons.map((ent, idx) => (
            <Card.Footer.Item key={idx}>
              {ent.icon && (
                <span className="icon">
                  <i className={ent.icon} />
                </span>
              )}
              <a href={ent.href} target="_blank" rel="noreferrer noopener">
                {ent.text}
              </a>
            </Card.Footer.Item>
          ))}
        </Card.Footer>
      </Card>
      <hr />
      <div className="is-pulled-right">{actions}</div>
    </>
  );
};
