import {
  QuoteInput,
  Quote,
  QuoteCurrency,
  FkQuoteFinanceLineItemQuoteQuoteIdQuoteFinanceLineItemCreateInput,
  FkQuoteDayDestinationQuoteDayDayIdQuoteDayDestinationCreateInput,
  FkQuoteDayQuoteQuoteIdQuoteDayCreateInput,
  FkQuoteAccommodationDetailQuoteQuoteIdQuoteAccommodationDetailCreateInput,
} from "types/graphql";
import moment from "moment";

export const cloneQuote = (input: Quote): QuoteInput =>
  ({
    adjustment: input.adjustment,
    agentMargin: input.agentMargin,
    deposit: input.deposit,
    duration: input.duration,
    expires: moment().add(7, "days").format("YYYY-MM-DD"),
    margin: input.margin,
    status: 0,
    travellerCount: input.travellerCount,
    tripId: input.trip?.id,
    baseCurrency: input.baseCurrency,
    exclusions: input.exclusions,
    inclusions: input.inclusions,
    shortDescription: input.shortDescription,
    start: input.start,
    summary: input.summary,
    quoteCurrenciesUsingId: {
      create: input.quoteCurrencies.nodes.map(
        (ent) => ({ rate: ent?.rate, code: ent?.code } as QuoteCurrency)
      ),
    },
    quoteFinanceLineItemsUsingId: {
      create: input.quoteFinanceLineItems.nodes.map(
        (ent) =>
          ({
            supplierId: ent?.supplier?.id,
            amount: ent?.amount,
            sortOrder: ent?.sortOrder,
            quantity: ent?.quantity,
            currency: ent?.currency,
            description: ent?.description,
            confirmation: ent?.confirmation,
            excludeMargin: ent?.excludeMargin,
            notes: ent?.notes,
            marginOverride: ent?.marginOverride,
          } as FkQuoteFinanceLineItemQuoteQuoteIdQuoteFinanceLineItemCreateInput)
      ),
    },
    quoteDaysUsingId: {
      create: input.quoteDays.nodes.map(
        (ent) =>
          ({
            sortOrder: ent?.sortOrder,
            activityDetail: ent?.activityDetail,
            activitySummary: ent?.activitySummary,
            date: ent?.date,
            quoteDayDestinationsUsingId: {
              create: ent?.quoteDayDestinationsByDayId.nodes.map(
                (dd) =>
                  ({
                    sortOrder: dd?.sortOrder,
                    destinationId: dd?.destination?.id,
                  } as FkQuoteDayDestinationQuoteDayDayIdQuoteDayDestinationCreateInput)
              ),
            },
          } as FkQuoteDayQuoteQuoteIdQuoteDayCreateInput)
      ),
    },
    quoteAccommodationDetailsUsingId: {
      create: input.quoteAccommodationDetails.nodes.map(
        (ent) =>
          ({
            nights: ent?.nights,
            sortOrder: ent?.sortOrder,
            basis: ent?.basis,
            beverageInclusions: ent?.beverageInclusions,
            foodInclusions: ent?.foodInclusions,
            notes: ent?.notes,
            propertyId: ent?.property?.id,
            roomType: ent?.roomType,
            summary: ent?.summary,
          } as FkQuoteAccommodationDetailQuoteQuoteIdQuoteAccommodationDetailCreateInput)
      ),
    },
    quoteLegalDocumentsUsingId: {
      create: input.quoteLegalDocuments.nodes.map((ent) => ({
        sortOrder: ent?.sortOrder,
        legalDocumentId: ent?.legalDocument?.id,
      })),
    },
    quoteHeroToHeroId: input.hero
      ? {
          create: {
            imageId: input.hero.image?.id,
            subtitle: input.hero.subtitle,
            title: input.hero.title,
          },
        }
      : null,
  } as QuoteInput);
