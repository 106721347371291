import { Queries } from "types/Model";
import {
  Invoice,
  useListInvoicesQuery,
  useGetInvoiceQuery,
  useUpdateInvoiceMutation,
  useCreateInvoiceMutation
} from "types/graphql";

export const queries: Queries<Invoice> = {
  list: useListInvoicesQuery,
  create: useCreateInvoiceMutation,
  detail: useGetInvoiceQuery,
  update: useUpdateInvoiceMutation
};
