import * as React from "react";
import { Link } from "react-router-dom";
import { Customer } from "../../../../types/graphql";
import { date } from "../../../../components/Format";
import { DetailProps } from "types/types";

export const Enquiries: React.FC<DetailProps<Customer>> = ({ entity }) => (
  <table className="table is-striped is-hoverable is-fullwidth">
    <thead>
      <tr>
        <th className="col-date">Submitted</th>
        <th>Dates</th>
        <th>Destination</th>
        <th>Comments</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {entity.enquiries.nodes.map(ent =>
        !ent ? null : (
          <tr key={ent.id}>
            <td>
              <Link to={`/sales/enquiries/${ent.id}`}>{date(ent.created)}</Link>
            </td>
            <td>{ent.dates}</td>
            <td>{ent.destination}</td>
            <td>{ent.comments}</td>
            <td>{ent.status}</td>
          </tr>
        )
      )}
    </tbody>
  </table>
);
