export const model = {
  Agency: {
    name: "string",
    logo: {
      type: "MediaGalleryItem",
      patchProperty: "mediaItemToLogoId",
    },
    supplier: {
      type: "Supplier",
      patchProperty: "supplierToSupplierId",
    },
  },
};
