import * as React from "react";
import { ListingTable } from "components/ListingTable";
import { Traveller, Trip } from "types/graphql";
import { model as tripModel } from "entities/Trip/model";
import { DetailProps } from "types/types";

export const Trips: React.FC<DetailProps<Traveller>> = ({ entity }) => (
  <ListingTable<Trip>
    items={entity.tripTravellers.nodes?.map(ent => ent?.trip) as Trip[]}
    model={tripModel}
  />
);
