import { Queries } from "types/Model";
import {
  Trip,
  useListTripsQuery,
  useGetTripQuery,
  useUpdateTripMutation,
  useCreateTripMutation
} from "types/graphql";

export const queries: Queries<Trip> = {
  list: useListTripsQuery,
  create: useCreateTripMutation,
  detail: useGetTripQuery,
  update: useUpdateTripMutation
};
