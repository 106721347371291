import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Payment } from "../../../types/graphql";
import {
  TripSelector,
  FinanceCategorySelector,
  InvoiceSelector,
} from "components/Selectors";
import { DateField, AmountField } from "components/Layout/Field";

export const Editor: React.FC<DetailProps<Payment>> = ({ g }) => (
  <>
    <Columns>
      <Column>
        {g.link("trip", (trip) => (
          <TripSelector field={trip} />
        ))}
      </Column>
      <Column>
        {g.link("invoice", (invoice) => (
          <InvoiceSelector field={invoice} />
        ))}
      </Column>
      <Column>
        {g.link("category", (category) => (
          <FinanceCategorySelector field={category} />
        ))}
      </Column>
    </Columns>
    <Columns>
      <Column>
        <AmountField label="Amount" {...g.input("amount")} />
      </Column>
      <Column>
        <DateField label="Paid" {...g.input("paid")} />
      </Column>
      <Column>
        <InputField label="Currency" {...g.input("currency")} />
      </Column>
      <Column>
        <AmountField label="Amount actual" {...g.input("amountActual")} />
      </Column>
    </Columns>
    <InputField label="Summary" {...g.input("summary")} />
  </>
);
