export const model = {
  AgencyMember: {
    lastName: "string",
    firstName: "string",
    title: "string",
    email: "string",
    phone: "string",
    genderPreposition: "string",
    agency: {
      type: "Agency",
      patchProperty: "agencyToAgencyId",
    },
  },
};
