import React, { useState } from "react";
import { TabHeader } from "./TabHeader";
import cx from "classnames";
import { extractActiveFromHash } from "./util";

export { Tab } from "./Tab";

type TabsProps = {
  className?: string;
  children: any | any[];
  medium?: boolean;
  large?: boolean;
  instance?: string;
};

export const Tabs: React.FC<TabsProps> = ({
  className,
  children,
  medium,
  large,
  instance
}) => {
  const [activeTab, setActiveTab] = useState(extractActiveFromHash(instance));
  const tabs = children instanceof Array ? children : [children];
  return (
    <>
      <div
        className={cx("tabs", className, {
          "is-medium": medium,
          "is-large": large
        })}
      >
        <ul>
          {tabs.map((ent: any, index: number) => {
            const attrs = {
              key: index,
              title: ent.props.title,
              index,
              currentIndex: activeTab,
              onClick: setActiveTab
            };
            return React.createElement(TabHeader, attrs);
          })}
        </ul>
      </div>
      {tabs[activeTab]}
    </>
  );
};
