import * as React from "react";
import {
  Columns,
  Column,
  Section,
  Container,
  Message,
} from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { LegalDocument } from "../../../types/graphql";
import { Overview } from "./Overview";
import { Editor } from "./Editor";
import { Tab, Tabs } from "components/Tabs";
import { Quotes } from "./Detail/Quotes";
import ReactHtmlParser from "@orrisroot/react-html-parser";

export const View: React.FC<DetailProps<LegalDocument>> = (props) => (
  <Container>
    <Section>
      <Columns>
        <Column span={3}>
          <Overview {...props} />
        </Column>
        <Column span={9}>
          <Editor {...props} />
          {props.g.values.body ? (
            <>
              <hr />
              <Message title="Preview">
                <div className="content">
                  {ReactHtmlParser(props.g.values.body)}
                </div>
              </Message>
            </>
          ) : null}
          <hr />
          {props.entity.quoteLegalDocuments.totalCount > 0 ? (
            <Tabs>
              <Tab title="Quotes">
                <Quotes {...props} />
              </Tab>
            </Tabs>
          ) : null}
        </Column>
      </Columns>
    </Section>
  </Container>
);
