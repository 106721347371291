import * as React from "react";
import moment from "moment";
import numeral from "numeral";

export const DateFormat = {
  Short: "D MMM YYYY",
  Long: "D MMM YYYY h:mma"
};

export const MoneyFormat = {
  Default: "0,0.00"
};

export const date = (
  dt: any,
  format: string = DateFormat.Short
): string | null =>
  dt
    ? moment
        .utc(dt)
        .local()
        .format(format)
    : null;

export const money = (
  input: any,
  currency: string | null = null,
  format: string = MoneyFormat.Default
): string | null =>
  input
    ? [numeral(input).format(format), currency].filter((a: any) => a).join(" ")
    : null;

type DateProps = { input: any; format?: string };
export const Date: React.FC<DateProps> = ({ input, format }) => (
  <>{date(input, format)}</>
);

type MoneyProps = { input: any; currency?: string; format?: string };
export const Money: React.FC<MoneyProps> = ({ input, currency, format }) => (
  <>{money(input, currency, format)}</>
);
