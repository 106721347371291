import { useApolloClient } from "@apollo/client";
import { arrayMoveImmutable } from "array-move";
import cx from "classnames";
import { Box, Column, Columns } from "components/Layout";
import { DateField, SelectField, TextAreaField } from "components/Layout/Field";
import { DestinationSelector } from "components/Selectors";
import moment from "moment";
import * as React from "react";
import {
  GptItineraryDocument,
  GptItineraryQuery,
  GptItineraryQueryVariables,
  Maybe,
  Quote,
  QuoteAccommodationDetail,
  QuoteDay,
  QuoteDayDestination,
} from "types/graphql";
import { DetailProps } from "types/types";
import { MultiField, MultiFieldOptions } from "use-graphile/dist/fields/multi";

const options2: MultiFieldOptions<QuoteDayDestination> = {
  populateNewItem: (items) => {
    return { sortOrder: items.length ?? 0 } as QuoteDayDestination;
  },
};

export const DailyBreakdown: React.FC<DetailProps<Quote>> = ({ g }) => {
  const [moving, setMoving] = React.useState<number | null>(null);
  const client = useApolloClient();
  const [gptLoading, setGptLoading] = React.useState(false);
  const options: MultiFieldOptions<QuoteDay> = React.useMemo(
    () => ({
      populateNewItem: (items) => {
        return {
          sortOrder: items.length,
          date: g.values.start
            ? moment(g.values.start).add(items.length, "day")
            : null,
        } as QuoteDay;
      },
    }),
    [g.values.start]
  );

  const handleFromAccommodation = (days: MultiField<QuoteDay>) => {
    if (!window.confirm("Are you sure? This will overwrite existing data")) {
      return;
    }
    const data = [];

    let prevAccom: Maybe<QuoteAccommodationDetail> = null;
    for (const accom of g.values.quoteAccommodationDetails?.nodes) {
      for (let day = 0; day < (accom?.nights ?? 0); day++) {
        const sortOrder =
          day +
          g.values.quoteAccommodationDetails.nodes
            .filter((a) => (a?.sortOrder ?? 0) < (accom?.sortOrder ?? 0))
            .map((a) => a?.nights ?? 0)
            .reduce((a, b) => a + b, 0);

        data.push({
          ...days.items[sortOrder],
          date: moment(g.values.start)
            .add(sortOrder, "days")
            .format("YYYY-MM-DD"),
          accommodationId: accom?.id,
          quoteDayDestinationsByDayId: accom?.property?.destination
            ? {
                nodes: [
                  day === 0 &&
                  prevAccom?.property?.destination &&
                  prevAccom?.property?.destination.id !==
                    accom?.property?.destination.id
                    ? { destination: prevAccom?.property?.destination }
                    : null,
                  { destination: accom.property.destination },
                ]
                  .filter(Boolean)
                  .map((ent, sortOrder) => ({
                    ...ent,
                    sortOrder,
                  })),
              }
            : [],
          sortOrder,
        } as QuoteDay);
      }
      prevAccom = accom;
    }

    days.set(data);
  };

  const handleGpt = async (days: MultiField<QuoteDay>) => {
    const accoms = g.values.quoteAccommodationDetails?.nodes;

    const req = days.items.map((day, idx) => {
      const acc = accoms.find((a) => a?.id === day.accommodationId);
      return {
        day: idx + 1,
        property: acc?.property?.name! ?? "",
        date: day.date?.substring(0, 10)!,
      };
    });

    console.log({ days: req });

    setGptLoading(true);
    try {
      const res = await client.query<
        GptItineraryQuery,
        GptItineraryQueryVariables
      >({
        query: GptItineraryDocument,
        fetchPolicy: "network-only",
        variables: {
          input: {
            days: req,
          },
        },
      });

      if (res.data.gptItinerary) {
        g.set({
          ...g.values,
          quoteDays: {
            ...g.values.quoteDays,
            nodes: g.values.quoteDays.nodes.map(
              (day, idx) =>
                ({
                  ...day,
                  activitySummary:
                    res.data?.gptItinerary?.days[idx]?.summary ??
                    day?.activitySummary,
                  activityDetail:
                    res.data?.gptItinerary?.days[idx]?.detail ??
                    day?.activityDetail,
                } as QuoteDay)
            ),
          },
        });
      }
    } finally {
      setGptLoading(false);
    }
  };

  const handleFixDates = (days: MultiField<QuoteDay>) => {
    days.set(
      days.items.map((ent, sortOrder) => ({
        ...ent,
        sortOrder,
        date: moment(g.values.start)
          .add(sortOrder, "days")
          .format("YYYY-MM-DD"),
      }))
    );
  };

  return g.multi<QuoteDay>(
    "quoteDays",
    (days) => {
      const handleMove = (target: number) => () => {
        days.set(
          arrayMoveImmutable(days.items, moving!, target).map(
            (ent, sortOrder) => ({
              ...ent,
              sortOrder,
              date: moment(g.values.start)
                .add(sortOrder, "days")
                .format("YYYY-MM-DD"),
            })
          )
        );
        setMoving(null);
      };

      return (
        <div>
          {days.renderItems(
            (day) => {
              const movingBlock = (
                <div className="buttons is-centered">
                  <button
                    className="button is-success is-outlined"
                    onClick={handleMove(day.index)}
                  >
                    <span className="icon">
                      <i className="far fa-check"></i>
                    </span>
                  </button>
                  <button
                    className="button is-warning is-outlined"
                    onClick={() => setMoving(null)}
                  >
                    <span className="icon">
                      <i className="far fa-times"></i>
                    </span>
                  </button>
                </div>
              );

              return (
                <>
                  {moving !== null && moving !== day.index && day.index === 0
                    ? movingBlock
                    : null}
                  <Box>
                    <Columns>
                      <Column span={3}>
                        <DateField label="Date" {...day.input("date")} />
                        <SelectField
                          label="Accommodation"
                          {...day.select<QuoteAccommodationDetail>(
                            "accommodationId",
                            {
                              list: g.values.quoteAccommodationDetails?.nodes?.filter(
                                (a) => !!a?.id
                              ) as QuoteAccommodationDetail[],
                              includeEmpty: true,
                              displayKey: (ent) => ent.property?.name ?? "",
                              valueKey: (ent) => ent.id,
                            }
                          )}
                        />
                      </Column>
                      <Column span={3}>
                        <TextAreaField
                          label="Summary"
                          style={{ height: 80 }}
                          {...day.textarea("activitySummary")}
                        />
                      </Column>
                      <Column>
                        <TextAreaField
                          label="Detail"
                          style={{ height: 80 }}
                          {...day.textarea("activityDetail")}
                        />
                      </Column>

                      {moving !== null ? null : (
                        <Column narrow>
                          <div className="buttons">
                            {days.items.length < 2 ? null : (
                              <button
                                className="button is-info is-outlined"
                                onClick={() =>
                                  setMoving((prevValue) =>
                                    prevValue ? null : day.index
                                  )
                                }
                              >
                                <span className="icon">
                                  <i
                                    className={cx({
                                      "far fa-times": moving !== null,
                                      "fad fa-arrows-v": moving === null,
                                    })}
                                  />
                                </span>
                              </button>
                            )}

                            <button
                              className="button is-warning is-outlined"
                              onClick={day.remove}
                            >
                              <span className="icon">
                                <i className="fad fa-trash" />
                              </span>
                            </button>
                          </div>
                        </Column>
                      )}
                    </Columns>

                    <p className="heading">Destinations</p>
                    {day.multi<QuoteDayDestination>(
                      "quoteDayDestinationsByDayId",
                      (destinations) => (
                        <Columns>
                          {destinations.renderItems((dest) => (
                            <Column span={3}>
                              {dest.link("destination", (lnk) => (
                                <DestinationSelector
                                  label=""
                                  field={lnk}
                                  buttons={[
                                    {
                                      className: "button is-warning",
                                      onClick: dest.remove,
                                      children: (
                                        <span className="icon">
                                          <i className="fad fa-trash"></i>
                                        </span>
                                      ),
                                    } as React.DOMAttributes<HTMLButtonElement>,
                                    ...(destinations.items.length > 1
                                      ? ([
                                          dest.index > 0
                                            ? {
                                                className: "button",
                                                onClick: () => {
                                                  destinations.set(
                                                    arrayMoveImmutable(
                                                      destinations.items,
                                                      dest.index,
                                                      dest.index - 1
                                                    )
                                                  );
                                                },
                                                children: (
                                                  <span className="icon">
                                                    <i className="fad fa-arrow-left"></i>
                                                  </span>
                                                ),
                                              }
                                            : null,
                                          dest.index <
                                          destinations.items.length - 1
                                            ? {
                                                className: "button",
                                                onClick: () => {
                                                  destinations.set(
                                                    arrayMoveImmutable(
                                                      destinations.items,
                                                      dest.index,
                                                      dest.index + 1
                                                    )
                                                  );
                                                },
                                                children: (
                                                  <span className="icon">
                                                    <i className="fad fa-arrow-right"></i>
                                                  </span>
                                                ),
                                              }
                                            : null,
                                        ].filter(
                                          Boolean
                                        ) as React.DOMAttributes<HTMLButtonElement>[])
                                      : []),
                                  ]}
                                />
                              ))}
                            </Column>
                          ))}
                          <Column narrow>
                            <button
                              className="button is-info"
                              onClick={destinations.add}
                            >
                              <span className="icon">
                                <i className="far fa-plus"></i>
                              </span>
                            </button>
                          </Column>
                        </Columns>
                      ),
                      options2
                    )}
                  </Box>
                  {moving !== null && moving !== day.index && day.index > 0
                    ? movingBlock
                    : null}
                </>
              );
            },
            undefined,
            "sortOrder"
          )}
          <Box>
            <div className="buttons is-centered">
              <button
                className="button is-info"
                onClick={days.add}
                type="button"
              >
                Add
              </button>
              <button
                className="button"
                onClick={() => handleFromAccommodation(days)}
                type="button"
              >
                From accommodation
              </button>
              <button
                className={cx("button", { "is-loading": gptLoading })}
                onClick={() => handleGpt(days)}
                type="button"
              >
                GPT Itinerary
              </button>
              <button
                className="button"
                onClick={() => handleFixDates(days)}
                type="button"
              >
                Fix dates
              </button>
            </div>
          </Box>
        </div>
      );
    },
    options
  );
};
