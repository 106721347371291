import * as React from "react";
import { Link } from "react-router-dom";
import { DetailProps } from "types/types";
import { Trip, Expense } from "types/graphql";
import { date, money } from "components/Format";
import { SupplierField } from "components/SupplierField";
import moment from "moment";
import { model as expenseModel } from "entities/Expense/model";
import { Create } from "components/entities/Create";

export const ExpenseList: React.FC<DetailProps<Trip>> = ({
  entity,
  refetch,
}) => {
  const initialValues = React.useMemo(
    () => ({
      trip: {
        id: entity.id,
        name: entity.name,
      },
      paid: moment().format("YYYY-MM-DD"),
    }),
    [entity]
  );

  const createProps = Create<Expense>({
    model: expenseModel,
    initialValues,
    onCreate: () => refetch(),
  });

  return (
    <>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td colSpan={3}>
                <span className="heading">Expenses</span>
              </td>
            </tr>
            <tr>
              <th className="col-amount">Local</th>
              <th className="col-amount">Foreign</th>
              <th className="col-date">Paid</th>
            </tr>
          </thead>
          <tbody>
            {entity.expenses.nodes.map((ent) =>
              !ent ? null : (
                <React.Fragment key={ent.id}>
                  <tr>
                    <td colSpan={3}>
                      <SupplierField supplier={ent.supplier} />
                    </td>
                  </tr>
                  <tr className="is-group-end">
                    <td>
                      <Link to={`/finance/expenses/${ent.id}`}>
                        {money(ent.amountActual)}
                      </Link>
                    </td>
                    <td>{money(ent.amount, ent.currency)}</td>
                    <td>{date(ent.paid)}</td>
                  </tr>
                </React.Fragment>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="buttons is-right">
        <button className="button is-dark is-small" {...createProps}>
          Create
        </button>
      </div>
    </>
  );
};
