import { Queries } from "types/Model";
import {
  LegalFolder,
  useListLegalFoldersQuery,
  useGetLegalFolderQuery,
  useUpdateLegalFolderMutation,
  useCreateLegalFolderMutation,
} from "types/graphql";

export const queries: Queries<LegalFolder> = {
  list: useListLegalFoldersQuery,
  create: useCreateLegalFolderMutation,
  detail: useGetLegalFolderQuery,
  update: useUpdateLegalFolderMutation,
};
