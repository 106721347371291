import { Queries } from "types/Model";
import {
  Supplier,
  useListSuppliersQuery,
  useGetSupplierQuery,
  useUpdateSupplierMutation,
  useCreateSupplierMutation
} from "types/graphql";

export const queries: Queries<Supplier> = {
  list: useListSuppliersQuery,
  create: useCreateSupplierMutation,
  detail: useGetSupplierQuery,
  update: useUpdateSupplierMutation
};
