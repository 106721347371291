import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { FinanceCategory } from "../../types/graphql";

export const listing: Listing<FinanceCategory> = {
  fields: [
    {
      heading: "Name",
      render: ent => (
        <Link to={`/finance/categories/${ent.id}`}>{ent.name}</Link>
      )
    }
  ]
};
