import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { Property } from "../../types/graphql";

export const listing: Listing<Property> = {
  fields: [
    {
      heading: "Name",
      render: (ent) => (
        <Link to={`/product/properties/${ent.id}`}>{ent.name}</Link>
      ),
      className: "col-name",
    },
    {
      heading: "Destination",
      render: (ent) =>
        ent.destination ? (
          <Link to={`/product/destinations/${ent.destination.id}`}>
            {ent.destination.name}
          </Link>
        ) : null,
      className: "col-destination",
    },
    {
      heading: "Country",
      render: (ent) => ent.country?.name,
      className: "col-country",
    },
    {
      heading: "Quotes",
      render: (ent) => ent.quoteAccommodationDetails.totalCount ?? 0,
      className: "col-number",
    },
  ],
};
