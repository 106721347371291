import { Queries } from "types/Model";
import {
  Country,
  useListCountriesQuery,
  useGetCountryQuery,
  useUpdateCountryMutation,
  useCreateCountryMutation
} from "types/graphql";

export const queries: Queries<Country> = {
  list: useListCountriesQuery,
  create: useCreateCountryMutation,
  detail: useGetCountryQuery,
  update: useUpdateCountryMutation
};
