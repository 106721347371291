import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { SupplierInvoice } from "../../types/graphql";
import { money, date } from "components/Format";

export const listing: Listing<SupplierInvoice> = {
  fields: [
    {
      heading: "Due",
      render: ent => (
        <Link to={`/finance/supplier-invoices/${ent.id}`}>{date(ent.due)}</Link>
      ),
      className: "col-date"
    },
    {
      heading: "Amount",
      render: ent => money(ent.amount, ent.currency),
      className: "col-amount",
      right: true
    },
    {
      heading: "Amount actual",
      render: ent => money(ent.amountActual),
      className: "col-amount",
      right: true
    },
    {
      heading: "Paid",
      render: ent => date(ent.paid),
      className: "col-date"
    },
    {
      heading: "Trip",
      render: ent => (
        <Link to={`/sales/trips/${ent.trip?.id}`}>{ent.trip?.name}</Link>
      ),
      className: "col-trip"
    },
    {
      heading: "Supplier",
      render: ent =>
        ent.supplier ? (
          <Link to={`/finance/suppliers/${ent.supplier?.id}`}>
            {ent.supplier?.name}
          </Link>
        ) : null,
      className: "col-customer"
    },
    {
      heading: "Voided",
      render: ent => date(ent.voided),
      className: "col-date"
    }
  ]
};
