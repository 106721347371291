import * as React from "react";
import { QuoteCurrency, Quote, useListRatesQuery } from "types/graphql";
import type { Form as Graphile } from "use-graphile";

export const Currency: React.FC<any> = ({ g }: { g: Graphile<Quote> }) => {
  const rates = useListRatesQuery({
    fetchPolicy: "cache-first",
  });

  return (
    <>
      <span className="heading">Currencies</span>
      {g.multi<QuoteCurrency>("quoteCurrencies", (currencies) => (
        <>
          <div className="field has-addons">
            <div className="control">
              <input
                className="input"
                {...g.input("baseCurrency")}
                style={{ width: 60 }}
              />
            </div>
            <div className="control">
              <input
                className="input"
                value="1.00"
                style={{ width: 80 }}
                readOnly
              />
            </div>
            <div className="control">
              <button className="button is-info" onClick={currencies.add}>
                <span className="icon">
                  <i className="far fa-plus" />
                </span>
              </button>
            </div>
          </div>

          {currencies.renderItems(
            (currency) => {
              const rate = rates.data?.rates?.find(
                (r) =>
                  r?.source === g.values.baseCurrency &&
                  r?.target === currency.input("code").value
              );
              return (
                <div className="field" key={currency.index}>
                  <div className="field has-addons">
                    <div className="control">
                      <input
                        className="input"
                        style={{ width: 60 }}
                        {...currency.input("code")}
                      />
                    </div>
                    <div className="control">
                      <input
                        className="input"
                        type="number"
                        step="0.01"
                        style={{ width: 80 }}
                        {...currency.input("rate")}
                      />
                    </div>
                    <div className="control">
                      <button
                        className="button is-warning"
                        onClick={currency.remove}
                      >
                        <span className="icon">
                          <i className="fad fa-trash" />
                        </span>
                      </button>
                    </div>
                  </div>
                  {rate ? (
                    <pre
                      style={{
                        padding: "0.25rem",
                        backgroundColor:
                          rate.rate / currency.item.rate < 1
                            ? "var(--colour-danger)"
                            : undefined,
                        color:
                          rate.rate / currency.item.rate < 1
                            ? "white"
                            : undefined,
                      }}
                    >
                      {rate.source}/{rate.target} {rate.rate} (
                      {((rate.rate / currency.item.rate - 1) * 100).toFixed(2)}
                      %)
                    </pre>
                  ) : null}
                </div>
              );
            },
            undefined,
            "code"
          )}
        </>
      ))}
    </>
  );
};
