import * as React from "react";
import { SupplierField } from "../../../../../components/SupplierField";
import { DetailProps } from "../../../../../types/types";
import {
  Trip,
  Payment,
  SupplierInvoice,
  Invoice,
  Expense,
} from "types/graphql";
import { money } from "components/Format";
import { sumBy, groupBy, sum } from "../../../../../util";

export const supplierSortCompare = (a: string, b: string) => {
  const supA = JSON.parse(a.split("|")[0]);
  const supB = JSON.parse(b.split("|")[0]);

  return supA.name.localeCompare(supB.name);
};

const GroupNameDisplay = ({ group }: { group: string }) => {
  const [supplier, currency] = group.split("|");

  return (
    <>
      <SupplierField supplier={JSON.parse(supplier)} />{" "}
      <small>{currency}</small>
    </>
  );
};

export const FinancialBreakdown: React.FC<DetailProps<Trip>> = ({ entity }) => {
  const clientInvoiceTotal = sumBy(
    entity.invoices.nodes.filter((ent) => ent && !ent.voided) as Invoice[],
    "amount"
  );
  const clientPaymentTotal = sumBy(
    entity.payments.nodes as Payment[],
    "amountActual"
  );
  const clientPaymentTotalRaw = sumBy(
    entity.payments.nodes as Payment[],
    "amount"
  );

  const supplierInvoiceTotal = sumBy(
    entity.supplierInvoices.nodes.filter(
      (ent) => ent && !ent.voided
    ) as SupplierInvoice[],
    "amountActual"
  );
  const supplierPaymentTotal = sumBy(
    entity.expenses.nodes as Expense[],
    "amountActual"
  );

  const supplierInvoicesGrouped = groupBy(
    entity.supplierInvoices.nodes.filter((ent) => ent && !ent.voided),
    (ent) => (ent ? `${JSON.stringify(ent.supplier)}|${ent.currency}` : "")
  );
  const supplierPaymentsGrouped = groupBy(entity.expenses.nodes, (ent) =>
    ent ? `${JSON.stringify(ent.supplier)}|${ent.currency}` : ""
  );

  const supplierPaymentsOutstanding = sum(
    Object.keys(supplierInvoicesGrouped).map((supplier) => {
      const invoices = supplierInvoicesGrouped[supplier];
      const invoiceTotal = sumBy(invoices as SupplierInvoice[], "amountActual");
      const payments = supplierPaymentsGrouped[supplier];
      const paymentsTotal = payments
        ? sumBy(payments as Expense[], "amountActual")
        : 0;
      const due = invoiceTotal - paymentsTotal;

      return due;
    })
  );

  const paymentsDue = clientInvoiceTotal - clientPaymentTotalRaw;
  const profit = clientPaymentTotal - supplierPaymentTotal;
  const currentStanding = paymentsDue + profit - supplierPaymentsOutstanding;

  return (
    <>
      {entity.status === 3 && profit !== 0 ? (
        <>
          <article className="message is-success">
            <div className="message-body">
              This trip has been marked as complete. The total{" "}
              {profit > 0 ? "profit" : "loss"} is {money(Math.abs(profit))}{" "}
              after taking payments of {money(Math.abs(clientPaymentTotal))} and
              making payments totalling {money(Math.abs(supplierPaymentTotal))}{" "}
              to our suppliers.
            </div>
          </article>
          <hr />
        </>
      ) : null}
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td>
                <span className="heading">Financial summary</span>
              </td>
              <th />
            </tr>
          </thead>
          <tbody>
            {clientPaymentTotal !== 0 ? (
              <tr>
                <th>Payments received</th>
                <td className="has-text-right">{money(clientPaymentTotal)}</td>
              </tr>
            ) : null}
            {paymentsDue !== 0 ? (
              <tr>
                <th>Payments outstanding</th>
                <td className="has-text-right">{money(paymentsDue)}</td>
              </tr>
            ) : null}
            {supplierPaymentTotal !== 0 ? (
              <tr>
                <th>Expenses paid</th>
                <td className="has-text-right">
                  {money(supplierPaymentTotal)}
                </td>
              </tr>
            ) : null}
            {supplierPaymentsOutstanding > 0 ? (
              <tr>
                <th>Expenses outstanding</th>
                <td className="has-text-right">
                  {money(supplierPaymentsOutstanding)}
                </td>
              </tr>
            ) : null}
          </tbody>
          <tfoot>
            <tr>
              <th>
                {entity.status === 3
                  ? currentStanding > 0
                    ? "Profit"
                    : "Loss"
                  : "Current standing"}
              </th>
              <td className="has-text-right">{money(currentStanding)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td>
                <span className="heading">Finances inbound</span>
              </td>
              <th className="has-text-right">Local</th>
              <th className="has-text-right">Foreign</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Invoices sent</th>
              <td />
              <td className="has-text-right">{money(clientInvoiceTotal)}</td>
            </tr>
            <tr>
              <th>Payments received</th>
              <td className="has-text-right">{money(clientPaymentTotal)}</td>
              <td className="has-text-right">{money(clientPaymentTotalRaw)}</td>
            </tr>
            {paymentsDue !== 0 ? (
              <tr>
                <th>Payments outstanding</th>
                <td className="has-text-right">{money(paymentsDue)}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td>
                <span className="heading">Finances outbound</span>
              </td>
              <th className="has-text-right">Local</th>
              <th className="has-text-right">Foreign</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Invoices received</th>
              <td className="has-text-right">{money(supplierInvoiceTotal)}</td>
              <td />
            </tr>
            {Object.keys(supplierInvoicesGrouped)
              .sort(supplierSortCompare)
              .map((group) => {
                const invoices = supplierInvoicesGrouped[
                  group
                ] as SupplierInvoice[];

                return (
                  <tr key={group}>
                    <td>
                      <GroupNameDisplay group={group} />
                    </td>
                    <td className="has-text-right">
                      {money(sumBy(invoices, "amountActual"))}
                    </td>
                    <td className="has-text-right">
                      {money(sumBy(invoices, "amount"))}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <th>Expenses paid</th>
              <td className="has-text-right">{money(supplierPaymentTotal)}</td>
              <td />
            </tr>
            {Object.keys(supplierPaymentsGrouped)
              .sort(supplierSortCompare)
              .map((group) => {
                const payments = supplierPaymentsGrouped[group] as Expense[];

                return (
                  <tr key={group}>
                    <td>
                      <GroupNameDisplay group={group} />
                    </td>
                    <td className="has-text-right">
                      {money(sumBy(payments, "amountActual"))}
                    </td>
                    <td className="has-text-right">
                      {money(sumBy(payments, "amount"))}
                    </td>
                  </tr>
                );
              })}
            {supplierPaymentsOutstanding > 0 ? (
              <>
                <tr>
                  <th>Expenses outstanding</th>
                  <td className="has-text-right">
                    {money(supplierPaymentsOutstanding)}
                  </td>
                  <td />
                </tr>
                {Object.keys(supplierInvoicesGrouped)
                  .sort(supplierSortCompare)
                  .map((group) => {
                    const invoices = supplierInvoicesGrouped[
                      group
                    ] as SupplierInvoice[];
                    const invoiceTotal = sumBy(invoices, "amountActual");
                    const invoiceTotalRaw = sumBy(invoices, "amount");
                    const payments = supplierPaymentsGrouped[
                      group
                    ] as Expense[];
                    const paymentsTotal = payments
                      ? sumBy(payments, "amountActual")
                      : 0;
                    const paymentsTotalRaw = payments
                      ? sumBy(payments, "amount")
                      : 0;
                    const due = invoiceTotal - paymentsTotal;
                    const dueRaw = invoiceTotalRaw - paymentsTotalRaw;

                    if (!due || !dueRaw) {
                      return null;
                    }

                    return (
                      <tr key={group}>
                        <td>
                          <GroupNameDisplay group={group} />
                        </td>
                        <td className="has-text-right">{money(due)}</td>
                        <td className="has-text-right">{money(dueRaw)}</td>
                      </tr>
                    );
                  })}
              </>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  );
};
