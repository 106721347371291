export const model = {
  Quote: {
    adjustment: "number",
    agentMargin: "number",
    baseCurrency: "string",
    deposit: "number",
    duration: "number",
    exclusions: "string",
    expires: "date",
    inclusions: "string",
    locked: "date",
    margin: "number",
    name: "string",
    shortDescription: "string",
    start: "date",
    status: "number",
    travellerCount: "number",
    trip: {
      type: "Trip",
      patchProperty: "tripToTripId",
    },
    user: {
      type: "User",
      patchProperty: "userToUserId",
    },
    hero: {
      type: "QuoteHero",
      patchProperty: "quoteHeroToHeroId",
    },
    quoteDays: {
      type: "QuoteDay",
      multi: true,
      patchProperty: "quoteDaysUsingId",
    },
    quoteFinanceLineItems: {
      type: "QuoteFinanceLineItem",
      multi: true,
      patchProperty: "quoteFinanceLineItemsUsingId",
    },
    quoteCurrencies: {
      type: "QuoteCurrency",
      multi: true,
      patchProperty: "quoteCurrenciesUsingId",
    },
    quoteAccommodationDetails: {
      type: "QuoteAccommodationDetail",
      multi: true,
      patchProperty: "quoteAccommodationDetailsUsingId",
    },
    quoteLegalDocuments: {
      type: "QuoteLegalDocument",
      multi: true,
      patchProperty: "quoteLegalDocumentsUsingId",
    },
  },
  QuoteDay: {
    sortOrder: "number",
    date: "date",
    activitySummary: "string",
    activityDetail: "string",
    accommodationId: "string",
    accommodation: {
      type: "QuoteAccommodationDetail",
      patchProperty: "quoteAccommodationDetailToAccommodationId",
    },
    property: {
      type: "Property",
      patchProperty: "propertyToPropertyId",
    },
    quoteDayDestinationsByDayId: {
      type: "QuoteDayDestination",
      patchProperty: "quoteDayDestinationsUsingId",
      multi: true,
    },
  },
  QuoteDayDestination: {
    sortOrder: "number",
    destination: {
      type: "Destination",
      patchProperty: "destinationToDestinationId",
      patchWorkaroundProperty: "destinationId",
    },
  },
  QuoteAccommodationDetail: {
    sortOrder: "number",
    nights: "number",
    notes: "string",
    summary: "string",
    confirmation: "string",
    roomType: "string",
    foodInclusions: "string[]",
    beverageInclusions: "string[]",
    property: {
      type: "Property",
      patchProperty: "propertyToPropertyId",
      patchWorkaroundProperty: "propertyId",
    },
  },
  QuoteFinanceLineItem: {
    amount: "number",
    quantity: "number",
    sortOrder: "number",
    marginOverride: "number",
    excludeMargin: "boolean",
    currency: "string",
    confirmation: "string",
    description: "string",
    notes: "string",
    supplier: {
      type: "Supplier",
      patchProperty: "supplierToSupplierId",
      patchWorkaroundProperty: "supplierId",
    },
  },
  QuoteCurrency: {
    code: "string",
    rate: "number",
  },
  QuoteHero: {
    title: "string",
    subtitle: "string",
    image: {
      type: "MediaItem",
      patchProperty: "mediaItemToImageId",
      patchWorkaroundProperty: "imageId",
    },
  },
  QuoteLegalDocument: {
    sortOrder: "number",
    legalDocument: {
      type: "LegalDocument",
      patchProperty: "legalDocumentTolegalDocumentId",
      patchWorkaroundProperty: "legalDocumentId",
    },
  },
};
