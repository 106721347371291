import * as React from "react";

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = ({ style }) => (
  <svg
    viewBox="0 0 200 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M3.52912 0.529175V5.616H10.2867L0 15.8732L3.60798 19.4709L13.893 9.21364V15.9518H18.9961V0.529175H3.52912Z"
      fill="currentColor"
    />
    <path
      d="M43.6523 0.529175H50.1881L56.8553 19.4692H51.3826L50.1602 15.8667H43.5193L42.3232 19.4692H37.0098L43.6523 0.529175ZM48.6996 11.4695L46.8397 5.87976H46.8134L44.9536 11.4695H48.6996Z"
      fill="currentColor"
    />
    <path
      d="M58.0249 0.529175H63.1789V7.54916L69.1561 0.529175H75.4257L67.8006 9.21856L75.6376 19.4692H69.3155L64.2682 12.6884L63.1789 13.6681V19.4692H58.0249V0.529175Z"
      fill="currentColor"
    />
    <path
      d="M76.7189 0.529175H91.0638V5.0852H81.8713V7.70807H89.8677V11.8398H81.8713V14.9132H90.9307V19.4692H76.7173V0.529175H76.7189Z"
      fill="currentColor"
    />
    <path
      d="M99.8589 9.93447C99.8589 3.86796 104.216 0 109.768 0C115.319 0 119.491 3.89417 119.491 9.96068C119.491 16.0272 115.347 20 109.794 20C104.241 20 99.8589 16.0272 99.8589 9.93447ZM114.046 9.98689C114.046 6.173 112.107 4.55603 109.715 4.55603C107.323 4.55603 105.305 6.14515 105.305 9.98689C105.305 13.8286 107.351 15.444 109.741 15.444C112.132 15.444 114.044 13.8286 114.044 9.98689H114.046Z"
      fill="currentColor"
    />
    <path
      d="M121.301 0.529175H135.646V5.0852H126.454V7.70807H134.291V11.8398H126.454V19.4692H121.3V0.529175H121.301Z"
      fill="currentColor"
    />
    <path
      d="M137.261 0.529175H151.606V5.0852H142.414V7.70807H150.251V11.8398H142.414V19.4692H137.26V0.529175H137.261Z"
      fill="currentColor"
    />
    <path
      d="M159.231 10.0655C159.231 3.31094 163.562 0 168.928 0C174.294 0 177.11 3.15203 178.147 6.96592L172.94 8.13237C172.489 5.88139 171.054 4.5036 168.956 4.5036C166.618 4.5036 164.68 6.19921 164.68 10.0393C164.68 13.8794 166.618 15.4702 169.356 15.4702C171.746 15.4702 173.499 14.2251 173.499 12.7949V12.5836H169.301V8.8483H178.466V19.4708H174.641L174.375 15.9748H174.268C173.976 17.6163 172.063 20.0016 168.291 20.0016C164.333 20.0016 159.231 17.3263 159.231 10.0688V10.0655Z"
      fill="currentColor"
    />
    <path
      d="M180.368 9.93447C180.368 3.86796 184.725 0 190.277 0C195.829 0 200 3.89417 200 9.96068C200 16.0272 195.857 20 190.303 20C184.75 20 180.368 16.0272 180.368 9.93447ZM194.554 9.98689C194.554 6.173 192.615 4.55603 190.223 4.55603C187.831 4.55603 185.813 6.14515 185.813 9.98689C185.813 13.8286 187.858 15.444 190.249 15.444C192.64 15.444 194.552 13.8286 194.552 9.98689H194.554Z"
      fill="currentColor"
    />
    <path
      d="M24.1484 0.529175V5.11305H29.3567V19.4692H34.5091V5.11305H39.7962V0.529175H24.1484Z"
      fill="currentColor"
    />
  </svg>
);

export default Logo;
