import { Model } from "../../types/Model";
import { queries } from "./query";
import { listing } from "./listing";
import { graphileModel } from "../graphile";

import { View as display } from "./components/View";
import { Trip } from "../../types/graphql";
import { Editor as editor } from "./components/Editor";

export const TripStatus = [
  "Quoting",
  "Confirmed",
  "On trip",
  "Complete",
  "Cancelled"
];

export const model: Model<Trip> = {
  // editing
  graphileEntity: "Trip",
  graphileModel,
  // querying
  queries,
  // displaying
  listing,
  display,
  editor,
  // misc.
  breadcrumb: () => [
    { target: ["sales"], label: "Sales" },
    { target: ["sales", "trips"], label: "Trips" }
  ],
  detailUrl: trip => `/sales/trips/${trip.id}`
};
