import * as React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { date, money } from "../../../../../components/Format";
import { DetailProps } from "../../../../../types/types";
import { Trip, Payment } from "../../../../../types/graphql";
import { model as paymentModel } from "entities/Payment/model";
import { Create } from "components/entities/Create";

export const PaymentList: React.FC<DetailProps<Trip>> = ({
  entity,
  refetch
}) => {
  const initialValues = React.useMemo(
    () => ({
      trip: {
        id: entity.id,
        name: entity.name
      },
      paid: moment().format("YYYY-MM-DD")
    }),
    [entity]
  );

  const createProps = Create<Payment>({
    model: paymentModel,
    initialValues,
    onCreate: () => refetch()
  });

  return (
    <>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td>
                <span className="heading">Payments received</span>
              </td>
              <th className="col-date">Paid</th>
            </tr>
          </thead>
          <tbody>
            {entity.payments.nodes.map(ent =>
              !ent ? null : (
                <tr key={ent.id}>
                  <td>
                    <Link to={`/finance/payments/${ent.id}`}>
                      {money(ent.amount, ent.currency)}
                    </Link>
                  </td>
                  <td>{date(ent.paid)}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
        <div className="buttons is-right">
          <button className="button is-dark is-small" {...createProps}>
            Create
          </button>
        </div>
      </div>
    </>
  );
};
