import * as React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { DetailProps } from "../../../../../types/types";
import { Trip, Invoice } from "../../../../../types/graphql";
import { DateFormat, money } from "../../../../../components/Format";
import { Create } from "components/entities/Create";
import { model as invoiceModel } from "entities/Invoice/model";

export const InvoiceList: React.FC<DetailProps<Trip>> = ({
  entity,
  refetch,
}) => {
  const initialValues = React.useMemo(
    () => ({
      trip: {
        id: entity.id,
        name: entity.name,
      },
      invoiced: moment().format("YYYY-MM-DD"),
      due: moment().add(7, "days").format("YYYY-MM-DD"),
    }),
    [entity]
  );

  const createProps = Create<Invoice>({
    model: invoiceModel,
    initialValues,
    onCreate: () => refetch(),
  });

  return (
    <>
      <div className="table-container">
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td>
                <span className="heading">Invoices sent</span>
              </td>
              <th className="col-date">Due</th>
              <th className="col-date">Paid</th>
            </tr>
          </thead>
          <tbody>
            {entity.invoices.nodes.map((ent: any) => (
              <React.Fragment key={ent.id}>
                <tr className={ent.paid ? "is-muted" : ""}>
                  <td colSpan={3}>
                    <div className="columns">
                      <div className="column">
                        {ent.summary || <em>no summary</em>}
                      </div>
                      <div className="column is-narrow" />
                    </div>
                  </td>
                </tr>
                <tr className={"is-group-end" + (ent.paid ? "is-muted" : "")}>
                  <td>
                    <Link to={`/finance/invoices/${ent.id}`}>
                      {money(ent.amount, ent.currency)}
                    </Link>
                  </td>
                  <td>{moment(ent.due).format(DateFormat.Short)}</td>
                  <td>
                    {ent.paid && moment(ent.paid).format(DateFormat.Short)}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="buttons is-right">
        <button className="button is-dark is-small" {...createProps}>
          Create
        </button>
      </div>
    </>
  );
};
