import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { AgencyMember } from "../../../types/graphql";
import { AgencySelector } from "components/Selectors/AgencySelector";

export const Editor: React.FC<DetailProps<AgencyMember>> = ({ g, entity }) => (
  <>
    <Columns>
      <Column span={4}>
        <InputField
          className="input is-large"
          placeholder="First name"
          autoFocus={!entity?.id}
          {...g.input("firstName")}
        />
      </Column>
      <Column>
        <InputField
          className="input is-large"
          placeholder="Last name"
          {...g.input("lastName")}
        />
      </Column>
    </Columns>
    <Columns>
      <Column>
        {g.link("agency", (agency) => (
          <AgencySelector field={agency} />
        ))}
      </Column>
      <Column>
        <InputField label="Phone" type="tel" {...g.input("phone")} />
      </Column>
      <Column>
        <InputField label="Email" type="email" {...g.input("email")} />
      </Column>
      <Column>
        <InputField label="Title" {...g.input("title")} />
      </Column>
      <Column>
        <InputField label="Pronoun" {...g.input("genderPreposition")} />
      </Column>
    </Columns>
  </>
);
