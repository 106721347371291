import * as React from "react";
import Markdown from "react-markdown";
import { Card } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Property, MediaGallery, MediaItem } from "../../../types/graphql";
import { GallerySelector, MediaSelector } from "components/Selectors";
import { imageUrl } from "../../../util";
import { PropertyMergeAction } from "./actions/PropertyMergeAction";

export const Overview: React.FC<DetailProps<Property>> = ({
  entity,
  actions,
  g,
}) => {
  return (
    <>
      <Card>
        {g.values.heroMedia?.hash && (
          <Card.Image>
            <img
              src={`${imageUrl(g.values.heroMedia.hash)}?w=800&h=600`}
              alt=""
            />
          </Card.Image>
        )}
        <Card.Content>
          <h4 className="title is-4">{entity.name}</h4>
          <h6 className="subtitle is-6">
            {[entity.destination?.name, entity.country?.name]
              .filter((a) => a)
              .join(", ")}
          </h6>
          {entity.summary && (
            <Markdown className="content">{entity.summary}</Markdown>
          )}
          <hr />
          {g.link<MediaGallery>("gallery", (gallery) => (
            <GallerySelector field={gallery} />
          ))}
          {g.values?.gallery?.id
            ? g.link<MediaItem>("heroMedia", (image) => (
                <MediaSelector gallery={g.values.gallery?.id} {...image} />
              ))
            : null}
          {g.values.id ? <PropertyMergeAction id={g.values.id} /> : null}
        </Card.Content>
        <Card.Footer>
          <Card.Footer.Item>
            <span className="icon">
              <i className="fad fa-external-link" />
            </span>
            <a
              href={`https://www.takeoffgo.com/travel/properties/${entity.id}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              Public
            </a>
          </Card.Footer.Item>
        </Card.Footer>
      </Card>
      <hr />
      {actions}
    </>
  );
};
