import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { Airport } from "../../types/graphql";

export const listing: Listing<Airport> = {
  fields: [
    {
      heading: "Name",
      render: ent => <Link to={`/product/airports/${ent.id}`}>{ent.name}</Link>
    },
    {
      heading: "City",
      render: ent => ent.city
    },
    {
      heading: "Country",
      render: ent => ent.country?.name
    },
    {
      heading: "IATA",
      render: ent => ent.iata && <code>{ent.iata}</code>,
      className: "col-key"
    },
    {
      heading: "ICAO",
      render: ent => ent.icao && <code>{ent.icao}</code>,
      className: "col-key"
    }
  ]
};
