import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { LegalDocument } from "../../types/graphql";

export const listing: Listing<LegalDocument> = {
  fields: [
    {
      heading: "Name",
      render: (ent) => (
        <Link to={`/legal/documents/${ent.id}`}>{ent.name}</Link>
      ),
    },
    {
      heading: "Folder",
      render: (ent) => ent.folder?.name,
    },
  ],
};
