import { Queries } from "types/Model";
import {
  Payment,
  useListPaymentsQuery,
  useGetPaymentQuery,
  useUpdatePaymentMutation,
  useCreatePaymentMutation
} from "types/graphql";

export const queries: Queries<Payment> = {
  list: useListPaymentsQuery,
  create: useCreatePaymentMutation,
  detail: useGetPaymentQuery,
  update: useUpdatePaymentMutation
};
