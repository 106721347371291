import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { Destination } from "../../types/graphql";

export const listing: Listing<Destination> = {
  fields: [
    {
      heading: "Name",
      render: ent => (
        <Link to={`/product/destinations/${ent.id}`}>{ent.name}</Link>
      )
    },
    {
      heading: "Country",
      render: ent => ent.country?.name
    }
  ]
};
