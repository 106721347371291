import * as React from "react";
import { Column } from "./Layout";

type PaginationProps = {
  offset: number;
  setOffset: (offset: number) => void;
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    totalCount: number;
  };
  pageSize: number;
};

const Pagination: React.FC<PaginationProps> = ({
  offset,
  setOffset,
  pageInfo,
  pageSize
}) => {
  const handleJump = () => {
    const page = parseInt(window.prompt("Page") || "");
    if (typeof page === "number") {
      const newOffset = (page - 1) * pageSize;
      if (newOffset >= 0 && newOffset < pageInfo.totalCount) {
        setOffset(newOffset);
      }
    }
  };

  return (
    <>
      <Column narrow>
        <span>{`Page ${Math.ceil(offset / pageSize) + 1} of ${Math.ceil(
          pageInfo.totalCount / pageSize
        )} (${pageInfo.totalCount} entries)`}</span>
      </Column>
      <Column narrow>
        <div className="buttons are-small has-addons">
          <button className="button" type="button" onClick={handleJump}>
            Jump
          </button>
          {offset > pageSize && (
            <button
              className="button"
              type="button"
              onClick={() => setOffset(0)}
              disabled={!pageInfo.hasPreviousPage}
            >
              First
            </button>
          )}
          <button
            className="button"
            type="button"
            onClick={() => setOffset(offset - pageSize)}
            disabled={!pageInfo.hasPreviousPage}
          >
            <span className="icon">
              <i className="fad fa-arrow-left" />
            </span>
          </button>
          <button
            className="button"
            type="button"
            onClick={() => setOffset(offset + pageSize)}
            disabled={!pageInfo.hasNextPage}
          >
            <span className="icon">
              <i className="fad fa-arrow-right" />
            </span>
          </button>
        </div>
      </Column>
    </>
  );
};

export default Pagination;
