import { Queries } from "types/Model";
import {
  Customer,
  useListCustomersQuery,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  useCreateCustomerMutation
} from "types/graphql";

export const queries: Queries<Customer> = {
  list: useListCustomersQuery,
  create: useCreateCustomerMutation,
  detail: useGetCustomerQuery,
  update: useUpdateCustomerMutation
};
