import * as React from "react";
import { model as LegalFolderModel } from "entities/LegalFolder/model";
import { LegalFolder } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const LegalFolderSelector: React.FC<SelectorProps<LegalFolder>> = ({
  label,
  ...rest
}) => (
  <SelectorField<LegalFolder>
    model={LegalFolderModel}
    label={label ?? "Legal Folder"}
    display={(ent) => ent.name}
    {...rest}
  />
);
