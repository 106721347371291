import * as React from "react";
import { model as InvoiceModel } from "entities/Invoice/model";
import { Invoice } from "types/graphql";
import { SelectorField } from "./SelectorField";
import { money } from "components/Format";
import type { SelectorProps } from "./selectors";

export const InvoiceSelector: React.FC<SelectorProps<Invoice>> = ({
  label,
  ...rest
}) => (
  <SelectorField<Invoice>
    model={InvoiceModel}
    label={label ?? "Invoice"}
    display={ent =>
      `Invoice #${ent.number} (${money(ent.amount, ent.currency)})` ??
      "(unnamed)"
    }
    {...rest}
  />
);
