import * as React from "react";
import { useParams } from "react-router";
import { useForm as useGraphile, buildPatch } from "use-graphile/dist/index";
import cx from "classnames";
import { Loading } from "../Loading";
import { Message } from "../Layout/Message";
import { ModelProps } from "./types";
import { ErrorMessage } from "components/ErrorMessage";
import { DetailProps } from "types/types";
import { useApolloClient } from "@apollo/react-hooks";

export function Detail<T>({ model }: ModelProps<T>) {
  const [saving, setSaving] = React.useState(false);
  const client = useApolloClient();
  const { id } = useParams<{ id: string }>();
  const query = model.queries.detail({
    variables: { id },
    fetchPolicy: "cache-and-network",
    skip: false,
  });

  const initialValues = query.data ? query.data.entity : ({} as T);
  const g = useGraphile<T>(initialValues);

  React.useEffect(() => {
    if (query.data) {
      g.set(query.data.entity);
    } else {
      g.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data?.entity]);

  const [save, saveStatus] = model.queries.update();

  if (!query.data && query.loading) {
    return <Loading />;
  }

  if (query.error) {
    return (
      <Message title="An error occurred">{JSON.stringify(query.error)}</Message>
    );
  }

  if (!query.data || !query.data.entity) {
    return <Message title="An error occurred">Entity not found.</Message>;
  }

  const handleSave = async () => {
    setSaving(true);
    try {
      const value = model.beforeSave
        ? await model.beforeSave(initialValues, g.values, client)
        : g.values;

      const patch = buildPatch(
        model.graphileModel,
        model.graphileEntity,
        initialValues,
        value
      );

      try {
        await save({
          variables: { id, patch },
        });
      } catch (ex) {
        console.error("Error saving", ex);
      }
    } finally {
      setSaving(false);
    }
  };

  const handleReset = () => {
    if (query.data) {
      g.set(query.data.entity);
    } else {
      g.reset();
    }
  };

  const actions = (
    <>
      <div className="box is-floating">
        <div className="buttons is-right">
          <button
            type="reset"
            className="button"
            onClick={handleReset}
            disabled={g.clean || saveStatus.loading || saving}
          >
            Reset
          </button>
          <button
            type="submit"
            className={cx("button", "is-primary", {
              "is-loading": saveStatus.loading || saving,
            })}
            onClick={handleSave}
            disabled={g.clean}
          >
            Save
          </button>
        </div>
      </div>
      {saveStatus.error && <ErrorMessage body={saveStatus.error.message} />}
    </>
  );

  return (
    <>
      {model.display({
        g,
        actions,
        entity: query.data.entity,
        refetch: query.refetch,
      } as DetailProps<T>)}
      {/* <Prompt
        when={!g.clean}
        message="You have unsaved changes, are you sure you want to leave?"
      /> */}
    </>
  );
}
