import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { LegalDocument, LegalFolder } from "../../../types/graphql";
import { LegalFolderSelector } from "components/Selectors";
import { DateField, TextAreaField } from "components/Layout/Field";

export const Editor: React.FC<DetailProps<LegalDocument>> = ({ g, entity }) => (
  <>
    <InputField
      className="input is-large"
      placeholder="Name"
      autoFocus={!entity?.id}
      {...g.input("name")}
    />
    <Columns>
      <Column>
        <DateField label="Valid from" {...g.input("validFrom")}></DateField>
      </Column>
      <Column>
        <DateField label="Valid to" {...g.input("validTo")}></DateField>
      </Column>
    </Columns>
    <TextAreaField label="Body" {...g.textarea("body")} />

    <Columns>
      <Column>
        {g.link<LegalFolder>("folder", (parent) => (
          <LegalFolderSelector field={parent} />
        ))}
      </Column>
    </Columns>
  </>
);
