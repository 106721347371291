import React from "react";
import { gql } from "@apollo/client";
import { useApolloClient } from "@apollo/react-hooks";
import cx from "classnames";
import {
  MediaGalleryItemFilter,
  MediaGalleryItem,
  MediaItem,
} from "types/graphql";
import { Columns, Column } from "components/Layout";
import { imageUrl } from "../../util";

type Props = {
  gallery?: string;
  onChange?: (newValue: MediaItem | null) => void;
  value?: MediaItem;
};

export const MediaSelector: React.FC<Props> = ({
  gallery,
  onChange,
  value,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const client = useApolloClient();

  const filter: MediaGalleryItemFilter = React.useMemo(
    () => (gallery ? { mediaGalleryId: { equalTo: gallery } } : {}),
    [gallery]
  );

  const [data, setData] = React.useState([]);

  const handleRefresh = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await client.query({
        query,
        variables: { filter },
        fetchPolicy: "network-only",
      });

      setData(res.data.mediaGalleryItems.nodes);
    } finally {
      setLoading(false);
    }
  }, [client, filter]);

  React.useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <div className="field">
      <Columns>
        <Column narrow>
          <div className="buttons is-small">
            <button
              className="button is-info"
              onClick={() => setExpanded((prev) => !prev)}
            >
              {[expanded ? "Hide" : "Show", "gallery"].join(" ")}
            </button>
            {expanded ? (
              <button
                className={cx("button", { "is-loading": loading })}
                onClick={handleRefresh}
              >
                Refresh
              </button>
            ) : null}
            <button
              className="button"
              disabled={!value?.id}
              onClick={() => onChange && onChange(null)}
            >
              Clear
            </button>
          </div>
        </Column>
        <Column />
      </Columns>
      {expanded && (
        <>
          <Columns multiline gapless>
            {data.map((ent: MediaGalleryItem) => (
              <Column key={ent.id} span={3}>
                <button
                  className="button is-fullwidth image is-4by3"
                  onClick={() =>
                    onChange ? onChange(ent.mediaItem as MediaItem) : null
                  }
                  disabled={ent.mediaItem?.id === value?.id}
                >
                  {ent.mediaItem?.hash && (
                    <img
                      src={`${imageUrl(ent.mediaItem?.hash)}?w=400&h=300`}
                      alt={ent.mediaItem?.name ?? "Image"}
                    />
                  )}
                </button>
              </Column>
            ))}
          </Columns>
        </>
      )}
    </div>
  );
};

const query = gql`
  query ($filter: MediaGalleryItemFilter = null) {
    mediaGalleryItems(filter: $filter, orderBy: CREATED_DESC, first: 100) {
      nodes {
        id
        created
        mediaItem {
          id
          name
          fileName
          hash
        }
      }
    }
  }
`;
