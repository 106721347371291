import * as React from "react";
import moment from "moment";
import { DetailProps } from "types/types";
import { Trip, MediaGallery } from "types/graphql";
import { Columns, Column, InputField } from "components/Layout";
import {
  CustomerSelector,
  AgencyMemberSelector,
  AgencySelector,
  GallerySelector,
} from "components/Selectors";
import { DateFormat } from "components/Format";
import { SelectField, TextAreaField, DateField } from "components/Layout/Field";

const TripStatusEnum = [
  { id: 0, name: "Quoting" },
  { id: 1, name: "Confirmed" },
  { id: 2, name: "On trip" },
  { id: 3, name: "Completed" },
  { id: 4, name: "Cancelled" },
];

export const Editor: React.FC<DetailProps<Trip>> = ({ g, actions, entity }) => (
  <>
    <Columns>
      <Column>
        <InputField
          label="Name"
          {...g.input("name")}
          autoFocus={!entity?.id}
          placeholder="<name> - <destination> <year>"
        />

        {g.link("customer", (customer) => (
          <CustomerSelector field={customer} />
        ))}

        <SelectField
          label="Status"
          {...g.select("status", {
            list: TripStatusEnum,
            valueKey: (ent: any) => ent.id,
            displayKey: (ent: any) => ent.name,
          })}
        />

        <InputField label="Destination" {...g.input("destination")} />

        <TextAreaField label="Summary" {...g.textarea("summary")} />
      </Column>
      <Column>
        <InputField label="Dates" {...g.input("dates")} />
        <DateField label="Departure" {...g.input("start")} />
        <InputField
          label="Duration"
          {...g.input("duration")}
          type="number"
          min={1}
          step={1}
        />
        {g.values?.start && g.values?.duration && (
          <InputField
            label="Return"
            value={moment(g.values.start)
              .add(g.values.duration, "days")
              .format(DateFormat.Short)}
            readOnly
          />
        )}

        {g.link<MediaGallery>("mediaGallery", (field) => (
          <GallerySelector label="Attachments" field={field} />
        ))}

        {g.link("agency", (agency) => (
          <AgencySelector field={agency} />
        ))}

        {g.values?.agency &&
          g.link("agencyMember", (agencyMember) => (
            <AgencyMemberSelector field={agencyMember} />
          ))}
      </Column>
    </Columns>
    {actions}
    {/* <pre>{JSON.stringify(g.buildPatch(), null, 2)}</pre> */}
  </>
);
