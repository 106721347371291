import * as React from "react";
import { model as supplierModel } from "entities/Supplier/model";
import { Supplier } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const SupplierSelector: React.FC<SelectorProps<Supplier>> = ({
  label,
  ...rest
}) => (
  <SelectorField<Supplier>
    model={supplierModel}
    label={label ?? "Supplier"}
    display={ent => ent.name ?? "(unnamed)"}
    {...rest}
  />
);
