import * as React from "react";
import { BasicContainer } from ".";

export type SectionProps = BasicContainer;

export const Section: React.FC<SectionProps> = ({ children }) => (
  <section className="section">{children}</section>
);

export const Container: React.FC<BasicContainer> = ({ children }) => (
  <div className="container">{children}</div>
);
