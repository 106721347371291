import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "../../types/Model";
import { Trip } from "../../types/graphql";
import { TripStatus } from "./model";
import { date } from "components/Format";

export const listing: Listing<Trip> = {
  rowClassName: (ent) => {
    switch (ent.status) {
      case 0 /*Quoting*/:
      case 1 /*Confirmed*/:
      case 2 /*On trip*/:
        return "";
      default:
        return "is-muted";
    }
  },
  fields: [
    {
      heading: "Name",
      render: (ent) => <Link to={`/sales/trips/${ent.id}`}>{ent.name}</Link>,
      className: "col-trip",
    },
    {
      heading: "Customer",
      render: (ent) => (
        <Link to={`/sales/customers/${ent.customer?.id}`}>
          {ent.customer?.name}
        </Link>
      ),
      className: "col-customer",
    },
    {
      heading: "Start",
      render: (ent) => date(ent.start),
      className: "col-date",
    },
    {
      heading: "Duration",
      render: (ent) => ent.duration,
      className: "col-number",
    },
    {
      heading: "Status",
      render: (ent) => TripStatus[ent.status],
      className: "col-status",
    },
    {
      heading: "Destination",
      render: (ent) => ent.destination,
      className: "col-destination",
    },
  ],
};
