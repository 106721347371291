import { Queries } from "types/Model";
import {
  AgencyMember,
  useListAgencyMembersQuery,
  useGetAgencyMemberQuery,
  useUpdateAgencyMemberMutation,
  useCreateAgencyMemberMutation
} from "types/graphql";

export const queries: Queries<AgencyMember> = {
  list: useListAgencyMembersQuery,
  create: useCreateAgencyMemberMutation,
  detail: useGetAgencyMemberQuery,
  update: useUpdateAgencyMemberMutation
};
