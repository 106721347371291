import * as React from "react";
import cx from "classnames";

type TabHeaderProps = {
  index: number;
  currentIndex: number;
  title: string;
  onClick: (index: number) => void;
};

export const TabHeader: React.FC<TabHeaderProps> = ({
  index,
  currentIndex,
  title,
  onClick
}) => {
  const active = index === currentIndex;
  const handleClick = () => onClick(index);

  return (
    <li className={cx({ "is-active": active })}>
      <a href={`#tab-${index}`} onClick={handleClick}>
        {title}
      </a>
    </li>
  );
};
