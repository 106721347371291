import * as React from "react";
import cx from "classnames";
import { BasicContainer } from ".";
import moment from "moment";

export type FieldProps = {
  hasAddons?: boolean;
} & BasicContainer;

export const Field: React.FC<FieldProps> = ({ children, hasAddons }) => (
  <div
    className={cx("field", {
      "has-addons": hasAddons,
      "is-fullwidth": hasAddons,
    })}
  >
    {children}
  </div>
);

type BaseFieldProps<T> = {
  label?: string;
  errorMessage?: string;
  helpMessage?: string | React.ReactElement | null;
} & React.InputHTMLAttributes<T>;

type InputFieldProps = BaseFieldProps<HTMLInputElement>;
type SelectFieldProps = BaseFieldProps<HTMLSelectElement>;
type TextAreaFieldProps = BaseFieldProps<HTMLTextAreaElement>;

export const InputField: React.FC<InputFieldProps> = ({
  label,
  errorMessage,
  helpMessage,
  className,
  ...input
}) => {
  const id = React.useId();
  return (
    <Field>
      {label && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="control is-expanded">
        <input className={className ?? "input"} id={id} {...input} />
      </div>
      {helpMessage && <p className="help">{helpMessage}</p>}
      {errorMessage && <p className="help is-danger">{errorMessage}</p>}
    </Field>
  );
};

export const TextAreaField: React.FC<TextAreaFieldProps> = ({
  label,
  errorMessage,
  helpMessage,
  className,
  ...input
}) => {
  const id = React.useId();
  return (
    <Field>
      {label && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="control is-expanded">
        <textarea
          className={className ?? "input"}
          id={id}
          {...input}
          style={{ height: 70 }}
        />
      </div>
      {helpMessage && <p className="help">{helpMessage}</p>}
      {errorMessage && <p className="help is-danger">{errorMessage}</p>}
    </Field>
  );
};

export const SelectField: React.FC<SelectFieldProps> = ({
  label,
  errorMessage,
  className,
  ...select
}) => {
  const id = React.useId();
  return (
    <Field>
      {label && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="control is-expanded">
        <span className="select is-fullwidth">
          <select {...select} />
        </span>
      </div>
      {errorMessage && <p className="help is-danger">{errorMessage}</p>}
    </Field>
  );
};

export const DateField: React.FC<InputFieldProps> = (props) => {
  const m = moment(props.value?.toString());
  const value = m.isValid() ? m.format("YYYY-MM-DD") : "";
  const weekday = m.isValid()
    ? m.toDate().toLocaleString("en-us", { weekday: "long" })
    : "";

  return (
    <InputField
      {...props}
      type="date"
      value={value || ""}
      label={weekday ? `${props.label} (${weekday})` : props.label}
    />
  );
};

export const AmountField: React.FC<InputFieldProps> = (props) => {
  const v = parseFloat(props.value as string);
  const value = isNaN(v) ? props.value : v.toString(10);

  return <InputField {...props} value={value} type="number" step={0.01} />;
};
