export const model = {
  Destination: {
    name: "string",
    body: "string",
    latitude: "number",
    longitude: "number",
    country: {
      type: "Country",
      patchProperty: "countryToCountryId"
    },
    gallery: {
      type: "MediaGallery",
      patchProperty: "mediaGalleryToGalleryId"
    },
    heroMedia: {
      type: "MediaItem",
      patchProperty: "mediaItemToHeroMediaId",
      patchWorkaroundProperty: "heroMediaId"
    }
  }
};
