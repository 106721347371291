import * as React from "react";
import Logo from "../Logo";
import { Columns, Column, Section } from "components/Layout";
import { useAuth0 } from "@auth0/auth0-react";

export const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Columns centered>
      <Column narrow>
        <Section>
          <Logo />
        </Section>
        <Section>
          <div className="message">
            <div className="message-header">Jambo</div>
            <div className="message-body content has-text-centered">
              <p>You must be authorised to access this system.</p>
              <button
                type="button"
                className="button is-dark"
                onClick={() => loginWithRedirect()}
              >
                Login
              </button>
            </div>
          </div>
        </Section>
      </Column>
    </Columns>
  );
};
