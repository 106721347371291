import * as React from "react";
import { DetailProps } from "types/types";
import { SupplierInvoice, Expense } from "types/graphql";
import { ListingTable } from "components/ListingTable";
import { model } from "entities/Expense/model";
import { Create } from "components/entities/Create";
import moment from "moment";

export const Expenses: React.FC<DetailProps<SupplierInvoice>> = ({
  entity,
  refetch
}) => {
  const initialValues = React.useMemo(
    () => ({
      paid: moment().format("YYYY-MM-DD"),
      supplierInvoice: entity,
      supplier: entity.supplier,
      trip: entity.trip,
      category: entity.category,
      amount: entity.amount,
      amountActual: entity.amountActual,
      currency: entity.currency
    }),
    [entity]
  );
  const createProps = Create<Expense>({
    model,
    initialValues,
    onCreate: () => refetch()
  });

  return (
    <>
      <ListingTable<Expense>
        items={entity.expenses.nodes as Expense[]}
        model={model}
      />
      <hr />
      <div className="buttons is-right">
        <button
          type="button"
          className="button is-dark is-small"
          {...createProps}
        >
          Create
        </button>
      </div>
    </>
  );
};
