import * as React from "react";
import {
  Columns,
  Column,
  Section,
  Container
} from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { User } from "../../../types/graphql";
import { Overview } from "./Overview";
import { Editor } from "./Editor";
import { Detail } from "./Detail";

export const View: React.FC<DetailProps<User>> = props => (
  <Container>
    <Section>
      <Columns>
        <Column span={3}>
          <Overview {...props} />
        </Column>
        <Column span={9}>
          <Editor {...props} />
          <hr />
          <Detail {...props} />
        </Column>
      </Columns>
    </Section>
  </Container>
);
