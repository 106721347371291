import * as React from "react";
import { Customer, Traveller, CustomerTraveller } from "types/graphql";
import { DetailProps } from "types/types";
import { TravellerSelector } from "components/Selectors";

export const Travellers: React.FC<DetailProps<Customer>> = ({ g }) =>
  g.multi<CustomerTraveller>("customerTravellers", travellers => (
    <>
      {travellers.renderItems(traveller =>
        traveller.link<Traveller>("traveller", field => (
          <TravellerSelector
            field={field}
            label={""}
            buttons={[
              {
                className: "button is-warning",
                onClick: traveller.remove,
                children: (
                  <span className="icon">
                    <i className="fad fa-trash"></i>
                  </span>
                )
              } as React.DOMAttributes<HTMLButtonElement>
            ]}
          />
        ))
      )}
      <div className="buttons is-centered">
        <button className="button is-info" onClick={travellers.add}>
          <span className="icon">
            <i className="far fa-plus"></i>
          </span>
          <span>Add traveller</span>
        </button>
      </div>
    </>
  ));
