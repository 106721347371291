export const model = {
  LegalDocument: {
    name: "string",
    body: "string",
    validFrom: "string",
    validTo: "string",
    folder: {
      type: "LegalDocument",
      patchProperty: "legalFolderToFolderId",
    },
  },
};
