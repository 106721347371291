import * as React from "react";
import { DetailProps } from "types/types";
import { Quote } from "types/graphql";
import { Message } from "components/Layout";
import { date } from "components/Format";

export const Acceptance: React.FC<DetailProps<Quote>> = ({ entity }) => {
  if ((entity.quoteAcceptances.nodes.length ?? 0) === 0) {
    return null;
  }

  return (
    <Message title="Quote acceptance" excludeWrapper>
      {entity.quoteAcceptances.nodes.map((ent) => (
        <React.Fragment key={ent!.id}>
          <div className="content">
            <dl>
              <dt>Accepted</dt>
              <dd>{date(ent?.created)}</dd>
              <dt>By</dt>
              <dd>
                {ent?.name} ({ent?.email})
              </dd>
            </dl>
          </div>
        </React.Fragment>
      ))}
    </Message>
  );
};
