import * as React from "react";
import { useForm as useGraphile, buildPatch } from "use-graphile";
import cx from "classnames";

import { useNavigate } from "react-router";
import { ModelProps } from "../routes/types";
import { useModal } from "react-modal-hook";
import { ErrorMessage } from "components/ErrorMessage";

type CreateProps<T> = ModelProps<T> & {
  initialValues?: any;
  onCreate?: (ent: T) => void;
};

const emptyValue = {};

// TODO: !!! note, the reason input fields are screwy (it jumps to the end when typing in a field)
// is because `g` keeps changing causing a re-render.
// need to find a way to stop that from happening

export function Create<T>({ model, initialValues, onCreate }: CreateProps<T>) {
  const navigate = useNavigate();
  const [create, createStatus] = model.queries.create();
  const g = useGraphile<T>();

  React.useEffect(() => {
    g.set(initialValues);
    // eslint-disable-next-line
  }, [initialValues]);

  const [showModal, hideModal] = useModal(() => {
    const handleSave = async () => {
      try {
        const res = await create({
          variables: {
            entity: buildPatch(
              model.graphileModel,
              model.graphileEntity,
              emptyValue as T,
              g.values
            ),
          },
        });

        if (res.data) {
          if (onCreate) {
            onCreate(res.data.create.entity);
            handleClose();
          } else if (model.detailUrl) {
            navigate(model.detailUrl(res.data.create.entity));
          } else {
            handleClose();
          }
        }
      } catch (ex) {
        console.error("Create failed", ex);
        return;
      }
    };

    const handleClose = () => {
      g.reset();
      hideModal();
    };

    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <span className="modal-card-title">Create</span>
            <button type="button" className="delete" onClick={hideModal} />
          </header>
          <section className="modal-card-body">
            {model.editor({
              g,
              actions: null,
            })}
            {createStatus.error && (
              <ErrorMessage body={createStatus.error.message} />
            )}
          </section>
          <footer className="modal-card-foot">
            <div className="buttons is-grouped">
              <button type="button" className="button" onClick={handleClose}>
                Cancel
              </button>
              <button
                type="submit"
                className={cx("button", "is-primary", {
                  "is-loading": createStatus.loading,
                })}
                onClick={handleSave}
                disabled={g.clean}
              >
                Create
              </button>
            </div>
          </footer>
        </div>
      </div>
    );
  }, [g, model]);

  return {
    onClick: showModal,
  };
}
