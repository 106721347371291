import moment from "moment";

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
export const get = (obj: any, path: string, defaultValue: any = null) => {
  const result = String.prototype.split
    .call(path, /[,[\].]+?/)
    .filter(Boolean)
    .reduce(
      (res, key) => (res !== null && res !== undefined ? res[key] : res),
      obj
    );

  return result === undefined || result === obj ? defaultValue : result;
};

export const sum = (input: number[]): number =>
  input.reduce((p: any, c: any) => p + parseFloat(c), 0);

export const sumBy = <TEntity = any>(
  input: TEntity[],
  field: keyof TEntity
): number => input.reduce((p, c) => p + parseFloat(get(c, field as string)), 0);

export const groupBy = <TEntity = any>(
  input: TEntity[],
  field: (ent: TEntity) => string
): { [key: string]: TEntity[] } =>
  input.reduce(
    (r, v, i, a, k = field(v)) => (
      // eslint-disable-next-line
      ((r as any)[k] || ((r as any)[k] = [])).push(v), r
    ),
    {}
  );

export const imageUrl = (
  hash: string,
  isProtected: boolean = false,
  token?: string
): string =>
  `https://${isProtected ? "secure-cdn" : "cdn"}.takeoffgo.com/${hash}${
    isProtected ? `?token=${token}` : ""
  }`;

export const getExchangeRates = () => {
  const key = "exchangeRates";
  if (localStorage[key]) {
    try {
      const exchangeRates = JSON.parse(localStorage[key]);
      if (moment(exchangeRates.timestamp).isAfter(moment().add(-12, "hours"))) {
        return Promise.resolve(exchangeRates.rates);
      }
    } catch (ex) {
      console.warn("Invalid exchange rates", ex);
    }
  }

  return fetch(
    "https://openexchangerates.org/api/latest.json?app_id=13917022cffc4f3b8c2c5a15ac6cb69c"
  )
    .then(res => res.json())
    .then(res => {
      localStorage[key] = JSON.stringify({
        timestamp: moment().format(),
        rates: res.rates
      });

      return res.rates;
    });
};
