import React from "react";
import cx from "classnames";
import { Property, useMergePropertyIntoMutation } from "types/graphql";
import { InputProps } from "use-graphile/dist/fields/input";
import { SelectProps } from "use-graphile/dist/fields/select";
import { TextAreaProps } from "use-graphile/dist/fields/textarea";
import { useNavigate } from "react-router";
import { PropertySelector } from "components/Selectors/PropertySelector";

export const PropertyMergeAction: React.FC<{ id: string }> = ({ id }) => {
  const [target, setTarget] = React.useState<Property | null>();
  const navigate = useNavigate();
  const [merge, mergeStatus] = useMergePropertyIntoMutation();

  const handleMerge = async () => {
    if (!target) return;
    await merge({ variables: { source: id, target: target.id } });
    navigate(`/product/properties/${target.id}`);
  };

  return (
    <PropertySelector
      label="Merge property into"
      buttons={
        target
          ? [
              {
                className: cx("button is-success", {
                  "is-loading": mergeStatus.loading,
                }),
                onClick: handleMerge,
                children: (
                  <span className="icon">
                    <i className="far fa-check"></i>
                  </span>
                ),
              } as React.DOMAttributes<HTMLButtonElement>,
            ]
          : []
      }
      field={{
        onChange: setTarget,
        value: target as Property,
        initialValue: null,
        reset: () => {},
        set: () => {},
        input: () => {
          return {} as InputProps;
        },
        radio: () => {
          return {} as InputProps;
        },
        link: () => {
          return {} as InputProps;
        },
        checkbox: () => {
          return {} as InputProps;
        },
        select: () => {
          return {} as SelectProps;
        },
        textarea: () => {
          return {} as TextAreaProps;
        },
        multi: () => {},
        display: () => {},
      }}
    />
  );
};
