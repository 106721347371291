export const model = {
  Invoice: {
    amount: "number",
    currency: "string",
    due: "string",
    key: "string",
    summary: "string",
    type: "string",
    number: "number",
    voided: "date",
    paid: "date",
    note: "string",
    invoiced: "date",
    locked: "date",
    category: {
      type: "FinanceCategory",
      patchProperty: "financeCategoryToCategoryId"
    },
    trip: {
      type: "Trip",
      patchProperty: "tripToTripId"
    },
    quote: {
      type: "Quote",
      patchProperty: "quoteToQuoteId"
    }
  }
};
