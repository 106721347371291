import * as React from "react";
import { Customer, Trip } from "types/graphql";
import { Create } from "components/entities/Create";
import { model as tripModel } from "entities/Trip/model";
import { ListingTable } from "components/ListingTable";
import { DetailProps } from "types/types";

export const Trips: React.FC<DetailProps<Customer>> = ({ entity, refetch }) => {
  const initialValues = React.useMemo(
    () => ({ customer: { id: entity.id, name: entity.name } }),
    [entity]
  );
  const createProps = Create<Trip>({
    model: tripModel,
    initialValues,
    onCreate: () => refetch()
  });

  return (
    <>
      <ListingTable<Trip>
        model={tripModel}
        items={entity.trips.nodes as Trip[]}
      />
      <hr />
      <button type="button" className="button is-info" {...createProps}>
        New
      </button>
    </>
  );
};
