import * as React from "react";
import { model as agencyMemberModel } from "entities/AgencyMember/model";
import { AgencyMember } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const AgencyMemberSelector: React.FC<SelectorProps<AgencyMember>> = ({
  label,
  ...rest
}) => (
  <SelectorField<AgencyMember>
    model={agencyMemberModel}
    label={label ?? "Agency member"}
    display={(ent) =>
      [ent.lastName, ent.firstName].filter((a) => !!a).join(", ")
    }
    {...rest}
  />
);
