import React from 'react'
import { BasicContainer } from '.'

class CardFooter extends React.Component<BasicContainer> {
  render() {
    return <div className="card-footer">{this.props.children}</div>
  }

  static Item: React.FC<BasicContainer> = ({ children }) => <div className="card-footer-item">{children}</div>
}

class CardHeader extends React.Component<BasicContainer> {
  render() {
    return <div className="card-header">{this.props.children}</div>
  }

  static Title: React.FC<BasicContainer> = ({ children }) => <div className="card-header-title">{children}</div>
}

export class Card extends React.Component<BasicContainer> {
  render() {
    return <div className="card">{this.props.children}</div>
  }

  static Image: React.FC<BasicContainer> = ({ children }) => <div className="card-image">{children}</div>
  static Content: React.FC<BasicContainer> = ({ children }) => <div className="card-content">{children}</div>
  static Footer = CardFooter
  static Header = CardHeader
}
