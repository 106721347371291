import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { MediaGallery } from "../../../types/graphql";
import { GallerySelector } from "components/Selectors";

export const Editor: React.FC<DetailProps<MediaGallery>> = ({ g, entity }) => (
  <>
    <Columns>
      <Column>
        <InputField
          className="input"
          label="Name"
          autoFocus={!entity?.id}
          {...g.input("name")}
        />
      </Column>
      <Column>
        {g.link<MediaGallery>("parent", field => (
          <GallerySelector label="Parent" field={field} />
        ))}
      </Column>
      <Column>
        <div className="field">
          <label className="checkbox">
            <input type="checkbox" {...g.checkbox("isProtected")} />
            <span>Protected</span>
          </label>
        </div>
      </Column>
    </Columns>
  </>
);
