import * as React from "react";
import { DetailProps } from "../../../../types/types";
import { Trip, TripFlight, Airport } from "../../../../types/graphql";
import { AirportSelector } from "components/Selectors";
import { Columns, Column, InputField } from "components/Layout";

export const FlightList: React.FC<DetailProps<Trip>> = ({ g }) =>
  g.multi<TripFlight>("tripFlights", (flights) => (
    <>
      <p className="heading">Flights</p>
      {flights.renderItems(
        (flight) => (
          <>
            <Columns>
              <Column span={2}>
                <InputField
                  label="Flight"
                  placeholder="QF"
                  {...flight.input("carrier")}
                />
                <InputField placeholder="462" {...flight.input("number")} />
              </Column>
              <Column>
                {flight.link<Airport>("departureAirport", (field) => (
                  <AirportSelector label="Departure" field={field} />
                ))}
                <InputField type="datetime" {...flight.input("departure")} />
              </Column>
              <Column>
                {flight.link<Airport>("arrivalAirport", (field) => (
                  <AirportSelector label="Arrival" field={field} />
                ))}
                <InputField type="datetime" {...flight.input("arrival")} />
              </Column>
            </Columns>
            <Columns>
              <Column>
                <InputField
                  {...flight.input("notes")}
                  placeholder="Notes - i.e., Not a Take Off Go service"
                />
              </Column>
              <Column narrow>
                <div className="buttons is-right">
                  <button className="button is-warning" onClick={flight.remove}>
                    <span className="icon">
                      <i className="fad fa-trash" />
                    </span>
                  </button>
                </div>
              </Column>
            </Columns>
            <hr />
          </>
        ),
        undefined,
        "departure"
      )}
      <div className="buttons is-right">
        <button
          type="button"
          className="button is-dark is-small"
          onClick={flights.add}
        >
          Add
        </button>
      </div>
    </>
  ));
