import { Queries } from "types/Model";
import {
  Traveller,
  useListTravellersQuery,
  useGetTravellerQuery,
  useUpdateTravellerMutation,
  useCreateTravellerMutation
} from "types/graphql";

export const queries: Queries<Traveller> = {
  list: useListTravellersQuery,
  create: useCreateTravellerMutation,
  detail: useGetTravellerQuery,
  update: useUpdateTravellerMutation
};
