import * as React from "react";
import mapboxgl from "mapbox-gl";
import type { SelectorProps } from "./selectors";
import { Columns, InputField, Column, Card } from "components/Layout";

import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYnJlbmRhbm1ja2VuemllIiwiYSI6ImNsejNjZnJlbDF5cXMycW9pcTdudDZqcDgifQ.7aI71acZ_XeQWSxCgLh5xQ";

type Location = {
  latitude?: number | null;
  longitude?: number | null;
};

type Props = SelectorProps<Location> & {
  changeOnClick?: boolean;
};

export const MapSelector: React.FC<Props> = ({ field, changeOnClick }) => {
  const mapContainer = React.useRef<HTMLDivElement | null>(null);
  const map = React.useRef<mapboxgl.Map | null>(null);
  const marker = React.useRef<mapboxgl.Marker | null>(null);

  const handlePaste = (ev: React.ClipboardEvent<HTMLInputElement>) => {
    const data = ev.clipboardData.getData("text");
    if (data) {
      const [latitude, longitude] = data.split(",").map(parseFloat);
      if (latitude && longitude) {
        ev.preventDefault();
        field.onChange({ latitude, longitude });
      }
    }
  };

  React.useEffect(() => {
    if (map.current) return;

    const hasValue = field.value?.latitude && field.value?.longitude;

    map.current = new mapboxgl.Map({
      container: mapContainer.current!,
      style: "mapbox://styles/brendanmckenzie/clygj16a500wo01r4dwbtdcui",
      center: hasValue
        ? new mapboxgl.LngLat(field.value.longitude!, field.value.latitude!)
        : [20, 0],
      zoom: hasValue ? 6 : 1,
      scrollZoom: false,
    });

    map.current.addControl(new mapboxgl.NavigationControl(), "top-right");

    map.current.on("click", (ev) => {
      const newValue: Location = {
        latitude: ev.lngLat.lat,
        longitude: ev.lngLat.lng,
      };

      changeOnClick && field.onChange(newValue);
    });
  });

  React.useEffect(() => {
    if (!map.current) {
      return;
    }

    const hasValue = field.value?.latitude && field.value?.longitude;
    if (!hasValue) {
      return;
    }
    const pt = new mapboxgl.LngLat(
      field.value.longitude!,
      field.value.latitude!
    );

    if (marker.current) {
      marker.current.setLngLat(pt);
    } else {
      marker.current = new mapboxgl.Marker().setLngLat(pt).addTo(map.current!);
    }

    map.current.setCenter(pt);
    map.current.setZoom(12);
  }, [field.value]);

  return (
    <Card>
      <Card.Image>
        <div style={{ height: 400 }} id="map" ref={mapContainer}></div>
      </Card.Image>
      <Card.Content>
        <Columns>
          <Column>
            <InputField
              label="Latitude"
              {...field.input("latitude")}
              onPaste={handlePaste}
              helpMessage="Try pasting latitude, longitude tuples here"
            />
          </Column>
          <Column>
            <InputField
              label="Longitude"
              {...field.input("longitude")}
              onPaste={handlePaste}
            />
          </Column>
        </Columns>
      </Card.Content>
    </Card>
  );
};
