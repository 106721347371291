import * as React from "react";
import { model as countryModel } from "entities/Country/model";
import { Country } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const CountrySelector: React.FC<SelectorProps<Country>> = ({
  label,
  ...rest
}) => (
  <SelectorField<Country>
    model={countryModel}
    label={label ?? "Country"}
    display={ent => ent.name ?? "(unnamed)"}
    {...rest}
  />
);
