import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { LegalFolder } from "../../../types/graphql";
import { LegalFolderSelector } from "components/Selectors";

export const Editor: React.FC<DetailProps<LegalFolder>> = ({ g, entity }) => (
  <>
    <InputField
      className="input is-large"
      placeholder="Name"
      autoFocus={!entity?.id}
      {...g.input("name")}
    />
    <Columns>
      <Column>
        {g.link<LegalFolder>("parent", (parent) => (
          <LegalFolderSelector label="Parent" field={parent} />
        ))}
      </Column>
    </Columns>
  </>
);
