import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import {
  Destination,
  Property,
  PropertySummaryDocument,
  PropertySummaryQuery,
  PropertySummaryQueryVariables,
} from "../../../types/graphql";
import { TextAreaField, DateField } from "components/Layout/Field";
import {
  CountrySelector,
  DestinationSelector,
  MapSelector,
} from "components/Selectors";
import { useApolloClient } from "@apollo/client";
import cx from "classnames";

export const Editor: React.FC<DetailProps<Property>> = ({ g, entity }) => {
  const [loading, setLoading] = React.useState(false);
  const client = useApolloClient();
  const handleGPT = async () => {
    setLoading(true);
    try {
      const res = await client.query<
        PropertySummaryQuery,
        PropertySummaryQueryVariables
      >({
        query: PropertySummaryDocument,
        fetchPolicy: "network-only",
        variables: {
          input: {
            name: g.values.name!,
            country: g.values.country?.name!,
          },
        },
      });

      if (res.data.propertySummary) {
        g.set({
          ...g.values,
          summary: res.data.propertySummary.summary,
          latitude: res.data.propertySummary.latitude,
          longitude: res.data.propertySummary.longitude,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Columns>
        <Column>
          <InputField
            className="input is-large"
            placeholder="Name"
            autoFocus={!entity?.id}
            disabled={loading}
            {...g.input("name")}
          />
        </Column>
        <Column narrow>
          <button
            className={cx("button is-large", {
              "is-loading": loading,
            })}
            type="button"
            onClick={handleGPT}
            disabled={!g.values?.name! || !g.values?.country?.name!}
          >
            Ask GPT
          </button>
        </Column>
      </Columns>

      <Columns>
        <Column span={5}>
          <Columns>
            <Column>
              <InputField label="Airport" {...g.input("nearestAirport")} />
            </Column>
            <Column>
              {g.link("country", (country) => (
                <CountrySelector field={country} />
              ))}
            </Column>
          </Columns>
          {/* <Columns>
            <Column>
              <InputField label="Region" {...g.input("region")} />
            </Column>
            <Column>
              <InputField label="City" {...g.input("city")} />
            </Column>
          </Columns> */}
          {g.link<Destination>("destination", (field) => (
            <DestinationSelector field={field} />
          ))}

          <TextAreaField
            label="Summary"
            {...g.textarea("summary")}
            disabled={loading}
          />
          <TextAreaField label="Notes" {...g.textarea("notes")} />
          <Columns>
            <Column>
              <InputField
                label="Email"
                type="email"
                {...g.input("contactEmail")}
              />
            </Column>
            <Column>
              <InputField
                label="Phone"
                type="tel"
                {...g.input("contactNumber")}
              />
            </Column>
          </Columns>
          <InputField label="Website" {...g.input("url")} />
          <Columns>
            <Column span={6}>
              <DateField label="Featured" {...g.input("featured")} />
            </Column>
          </Columns>
          <TextAreaField
            label="Feature copy"
            {...g.textarea("featureCopy")}
            helpMessage="Appears on the main website"
          />
        </Column>
        <Column>
          <MapSelector
            field={{
              onChange: (val) => g.set({ ...g.values, ...val }),
              value: g.values,
              initialValue: g.values,
              ...g,
              set: () => null,
            }}
          />
        </Column>
      </Columns>
    </>
  );
};
