import * as React from "react";

type MessageProps = {
  title?: string;
  children: React.ReactNode;
  excludeWrapper?: boolean;
};

export const Message: React.FC<MessageProps> = ({
  title,
  children,
  excludeWrapper,
}) => {
  const content = (
    <div className="message">
      {title ? <div className="message-header">{title}</div> : null}
      <div className="message-body">{children}</div>
    </div>
  );
  return excludeWrapper ? (
    content
  ) : (
    <div className="container">
      <div className="section">{content}</div>
    </div>
  );
};
