import * as React from "react";
import { Message } from "../Layout/Message";
import { Navbar } from "../global";

export const Loading: React.FC = () => (
  <>
    <Navbar />
    <Message>
      <p>Checking your credentials, please wait...</p>
    </Message>
  </>
);
