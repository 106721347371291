import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Airport, Country } from "../../../types/graphql";
import { CountrySelector, MapSelector } from "components/Selectors";

export const Editor: React.FC<DetailProps<Airport>> = ({ g, entity }) => (
  <>
    <InputField
      className="input is-large"
      placeholder="Name"
      autoFocus={!entity?.id}
      {...g.input("name")}
    />
    <Columns>
      <Column>
        <InputField
          className="input "
          label="Display name"
          {...g.input("displayName")}
        />
      </Column>
      <Column>
        {g.link<Country>("country", (country) => (
          <CountrySelector field={country} />
        ))}
      </Column>
    </Columns>
    <Columns>
      <Column>
        <InputField label="City" {...g.input("city")} />
      </Column>
      <Column span={2}>
        <InputField label="IATA code" {...g.input("iata")} />
      </Column>
      <Column span={2}>
        <InputField label="ICAO code" {...g.input("icao")} />
      </Column>
      <Column>
        <InputField label="Timezone" {...g.input("timezone")} />
      </Column>
    </Columns>
    <Column>
      <MapSelector
        field={{
          onChange: (val) => g.set({ ...g.values, ...val }),
          value: g.values,
          initialValue: g.values,
          ...g,
          set: () => null,
        }}
      />
    </Column>
  </>
);
