import * as React from "react";

import { model } from "entities/Quote/model";
import { LegalDocument, Quote } from "types/graphql";
import { ListingTable } from "components/ListingTable";
import { DetailProps } from "types/types";

export const Quotes: React.FC<DetailProps<LegalDocument>> = ({ entity }) => {
  return (
    <>
      <ListingTable<Quote>
        items={
          entity.quoteLegalDocuments?.nodes.map((ent) => ent?.quote) as Quote[]
        }
        model={model}
      />
    </>
  );
};
