import * as React from "react";
import { Agency, AgencyMember } from "../../../../types/graphql";
import { Create } from "../../../../components/entities/Create";
import { model as agencyMemberModel } from "../../../../entities/AgencyMember/model";
import { ListingTable } from "components/ListingTable";

export const Members: React.FC<Agency> = entity => {
  const initialValues = React.useMemo(
    () => ({ agency: { id: entity.id, name: entity.name } }),
    [entity]
  );
  const createProps = Create<AgencyMember>({
    model: agencyMemberModel,
    initialValues
  });

  return (
    <>
      <ListingTable<AgencyMember>
        model={agencyMemberModel}
        items={entity.agencyMembers.nodes as AgencyMember[]}
      />
      <div className="buttons is-right">
        <button type="button" className="button is-info" {...createProps}>
          New
        </button>
      </div>
    </>
  );
};
