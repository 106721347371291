import { Model } from "use-graphile";

export const model: Model = {
  Trip: {
    name: "string",
    status: "number",
    destination: "string",
    summary: "string",
    dates: "string",
    start: "date",
    duration: "number",
    customer: {
      type: "Customer",
      patchProperty: "customerToCustomerId",
    },
    tripFlights: {
      type: "TripFlight",
      multi: true,
      patchProperty: "tripFlightsUsingId",
    },
    tripTravellers: {
      type: "TripTraveller",
      multi: true,
      patchProperty: "tripTravellersUsingId",
    },
    agency: {
      type: "Agency",
      patchProperty: "agencyToAgencyId",
    },
    agencyMember: {
      type: "AgencyMember",
      patchProperty: "agencyMemberToAgencyMemberId",
    },
    mediaGallery: {
      type: "MediaGallery",
      patchProperty: "mediaGalleryToMediaGalleryId",
    },
  },
  TripFlight: {
    arrival: "datetime",
    arrivalAirport: {
      type: "Airport",
      patchProperty: "airportToArrivalAirportId",
      patchWorkaroundProperty: "arrivalAirportId",
    },
    departure: "datetime",
    departureAirport: {
      type: "Airport",
      patchProperty: "airportToDepartureAirportId",
      patchWorkaroundProperty: "departureAirportId",
    },
    carrier: "string",
    number: "string",
    notes: "string",
  },
  TripTraveller: {
    traveller: {
      type: "Traveller",
      patchProperty: "travellerToTravellerId",
      patchWorkaroundProperty: "travellerId",
    },
  },
};
