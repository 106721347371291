import * as React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { Card, Columns, Column } from "components/Layout";
import { DetailProps } from "types/types";
import {
  MediaGallery,
  MediaGalleryItem,
  useDeleteMediaGalleryItemMutation,
  useRenameMediaItemMutation
} from "types/graphql";
import { imageUrl } from "../../../util";
import { date } from "components/Format";

export const Detail: React.FC<DetailProps<MediaGallery>> = ({
  entity,
  refetch
}) => {
  const [preview, setPreview] = React.useState<MediaGalleryItem | null>(null);
  const [del, deleteStatus] = useDeleteMediaGalleryItemMutation();
  const [rename, renameStatus] = useRenameMediaItemMutation();

  const handleSelect = (item?: MediaGalleryItem) => (
    ev: React.MouseEvent<HTMLAnchorElement>
  ) => {
    ev.preventDefault();

    if (preview === item || !item) {
      setPreview(null);
    } else if (item) {
      setPreview(item);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure?")) {
      await del({ variables: { id: preview?.id } });
      setPreview(null);
      refetch();
    }
  };

  const handleRename = async () => {
    const name = preview?.mediaItem?.fileName;
    const newName = window.prompt("New name", name ?? "");
    if (newName && newName !== name) {
      const res = await rename({
        variables: { id: preview?.mediaItem?.id, name: newName }
      });
      setPreview({
        ...preview,
        mediaItem: res.data?.updateMediaItem?.mediaItem
      } as MediaGalleryItem);
    }
  };

  return (
    <Columns>
      <Column>
        <table className="table is-fullwidth is-hoverable is-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th className="col-date">Uploaded</th>
            </tr>
          </thead>
          <tbody>
            {entity.childMediaGalleries?.nodes.map(ent => (
              <tr key={ent?.id}>
                <td>
                  <Link to={`/product/galleries/${ent?.id}`}>{ent?.name}</Link>
                </td>
                <td>Folder</td>
                <td />
              </tr>
            ))}
            {entity.mediaGalleryItems?.nodes.map(ent => (
              <tr key={ent?.id}>
                <td>
                  <a
                    href={imageUrl(ent?.mediaItem?.hash ?? "")}
                    onClick={handleSelect(ent as MediaGalleryItem)}
                  >
                    {ent?.mediaItem?.fileName ?? "(unnamed)"}
                  </a>
                </td>
                <td>{ent?.mediaItem?.contentType}</td>
                <td>{date(ent?.mediaItem?.created)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Column>
      {preview && (
        <Column span={4}>
          <Card>
            {preview.mediaItem?.contentType?.startsWith("image/") ? (
              <Card.Image>
                <figure className="image">
                  <img
                    src={`${imageUrl(
                      preview.mediaItem?.hash ?? ""
                    )}?w=800&h=600`}
                    alt=""
                  />
                </figure>
              </Card.Image>
            ) : null}
            <Card.Content>
              <a
                href={imageUrl(
                  preview?.mediaItem?.hash ?? "",
                  entity?.isProtected
                )}
              >
                {preview.mediaItem?.fileName}
              </a>
            </Card.Content>
            <Card.Footer>
              <Card.Footer.Item>
                <button
                  className={cx("button is-info", {
                    "is-loading": renameStatus.loading
                  })}
                  onClick={handleRename}
                >
                  <span className="icon">
                    <i className="fad fa-pen"></i>
                  </span>
                  <span>Rename</span>
                </button>
              </Card.Footer.Item>
              <Card.Footer.Item>
                <button
                  className={cx("button is-warning", {
                    "is-loading": deleteStatus.loading
                  })}
                  onClick={handleDelete}
                >
                  <span className="icon">
                    <i className="fad fa-trash"></i>
                  </span>
                  <span>Delete</span>
                </button>
              </Card.Footer.Item>
            </Card.Footer>
          </Card>
        </Column>
      )}
    </Columns>
  );
};
