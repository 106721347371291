import * as React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Auth0Provider
    domain="takeoffgo.au.auth0.com"
    clientId="IlP3hpL3LqWSUCVK66cG1U1HhUfz7UpY"
    legacySameSiteCookie={false}
    cacheLocation="localstorage"
    authorizationParams={{
      audience: "https://jambo.takeoffgo.com/",
      redirect_uri: window.location.origin,
    }}
    useRefreshTokens
  >
    {children}
  </Auth0Provider>
);
