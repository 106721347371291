import * as React from "react";
import { DetailProps } from "types/types";
import { Quote, QuoteAccommodationDetail } from "types/graphql";
import { Columns, Column, Box } from "components/Layout";
import { InputField } from "components/Layout/Field";
import { PropertySelector } from "components/Selectors/PropertySelector";
import { MultiField, MultiFieldOptions } from "use-graphile/dist/fields/multi";
import { Checkboxes } from "components/Checkboxes";
import { arrayMoveImmutable } from "array-move";

const options: MultiFieldOptions<QuoteAccommodationDetail> = {
  populateNewItem: (items) => {
    return { sortOrder: items.length } as QuoteAccommodationDetail;
  },
};

export const Accommodation: React.FC<DetailProps<Quote>> = ({ g }) => {
  const [moving, setMoving] = React.useState<number | null>(null);

  const handleUpdateDuration = (
    accom: MultiField<QuoteAccommodationDetail>
  ) => {
    const duration = accom.items.reduce((acc, cur) => acc + cur.nights, 0);
    g.set({ ...g.values, duration });
  };

  return g.multi<QuoteAccommodationDetail>(
    "quoteAccommodationDetails",
    (accom) => {
      const handleMove = (target: number) => () => {
        accom.set(
          arrayMoveImmutable(accom.items, moving!, target).map(
            (ent, sortOrder) => ({
              ...ent,
              sortOrder,
            })
          )
        );
        setMoving(null);
      };

      return (
        <div>
          {accom.renderItems(
            (acc) => {
              const movingBlock = (
                <div className="buttons is-centered">
                  <button
                    className="button is-success is-outlined"
                    onClick={handleMove(acc.index)}
                  >
                    <span className="icon">
                      <i className="far fa-check"></i>
                    </span>
                  </button>
                  <button
                    className="button is-warning is-outlined"
                    onClick={() => setMoving(null)}
                  >
                    <span className="icon">
                      <i className="far fa-times"></i>
                    </span>
                  </button>
                </div>
              );

              return (
                <>
                  {moving !== null && acc.index !== moving && acc.index === 0
                    ? movingBlock
                    : null}
                  <Box>
                    <Columns>
                      <Column>
                        <Columns>
                          <Column span={1}>
                            <InputField
                              label="Nights"
                              {...acc.input("nights")}
                            />
                          </Column>
                          <Column span={3}>
                            {acc.link("property", (property: any) => (
                              <PropertySelector field={property} />
                            ))}
                          </Column>
                          <Column>
                            <InputField
                              label="Room type"
                              {...acc.input("roomType")}
                            />
                          </Column>
                          <Column span={1}>
                            <InputField label="Basis" {...acc.input("basis")} />
                          </Column>
                          <Column>
                            <InputField
                              label="Confirmation"
                              {...acc.input("confirmation")}
                            />
                          </Column>
                        </Columns>
                        <Columns>
                          <Column narrow>
                            <label className="label">Food inclusions</label>
                          </Column>
                          <Column narrow>
                            <Checkboxes
                              {...acc.input("foodInclusions")}
                              options={["Breakfast", "Lunch", "Dinner"]}
                            />
                          </Column>
                          <Column narrow>
                            <label className="label">Beverage inclusions</label>
                          </Column>
                          <Column narrow>
                            <Checkboxes
                              {...acc.input("beverageInclusions")}
                              options={["Local", "All"]}
                            />
                          </Column>
                        </Columns>
                      </Column>

                      {moving !== null ? null : (
                        <Column narrow>
                          <div className="buttons">
                            {accom.items.length < 2 ? null : (
                              <button
                                className="button is-info is-outlined"
                                onClick={() =>
                                  setMoving((prevValue) =>
                                    prevValue ? null : acc.index
                                  )
                                }
                              >
                                <span className="icon">
                                  <i className="fad fa-arrows-v" />
                                </span>
                              </button>
                            )}

                            <button
                              className="button is-warning is-outlined"
                              onClick={acc.remove}
                            >
                              <span className="icon">
                                <i className="fad fa-trash" />
                              </span>
                            </button>
                          </div>
                        </Column>
                      )}
                    </Columns>
                  </Box>
                  {moving !== null && acc.index !== moving && acc.index > 0
                    ? movingBlock
                    : null}
                </>
              );
            },
            undefined,
            "sortOrder"
          )}
          <Box>
            <div className="buttons is-centered">
              <button
                className="button is-info"
                onClick={accom.add}
                type="button"
              >
                Add
              </button>
              <button
                className="button"
                onClick={() => handleUpdateDuration(accom)}
                type="button"
              >
                Update duration ("Nights")
              </button>
            </div>
          </Box>
        </div>
      );
    },
    options
  );
};
