import * as React from "react";
import { Link } from "react-router-dom";
import { Listing } from "types/Model";
import { Country } from "types/graphql";

export const listing: Listing<Country> = {
  fields: [
    {
      heading: "Name",
      render: ent => <Link to={`/product/countries/${ent.id}`}>{ent.name}</Link>
    },
    {
      heading: "ISO",
      render: ent => [ent.iso2, ent.iso3].filter(a => !!a).join(" / "),
      className: "col-key"
    }
  ]
};
