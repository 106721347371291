import * as React from "react";
import { InputField } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Agency, MediaItem, Supplier } from "../../../types/graphql";
import { MediaSelector } from "components/Selectors/MediaSelector";
import { SupplierSelector } from "components/Selectors";

export const Editor: React.FC<DetailProps<Agency>> = ({ g, entity }) => (
  <>
    <InputField
      className="input is-large"
      placeholder="Name"
      autoFocus={!entity?.id}
      {...g.input("name")}
    />
    {g.link<MediaItem>("logo", (logo) => (
      <MediaSelector gallery="5b197b7e-630d-489b-aff9-41d5a2415b14" {...logo} />
    ))}
    {g.link<Supplier>("supplier", (field) => (
      <SupplierSelector field={field} />
    ))}
  </>
);
