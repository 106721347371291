import * as React from "react";
import { Card } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { User } from "../../../types/graphql";

export const Overview: React.FC<DetailProps<User>> = ({ entity, actions }) => {
  return (
    <>
      <Card>
        <Card.Content>
          <h4 className="title is-4">
            {[entity.firstName, entity.lastName].join(" ")}
          </h4>
          <p>{entity.email}</p>
        </Card.Content>
      </Card>
      <hr />
      <div className="is-pulled-right">{actions}</div>
    </>
  );
};
