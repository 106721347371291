import { Queries } from "types/Model";
import {
  Expense,
  useListExpensesQuery,
  useGetExpenseQuery,
  useUpdateExpenseMutation,
  useCreateExpenseMutation
} from "types/graphql";

export const queries: Queries<Expense> = {
  list: useListExpensesQuery,
  create: useCreateExpenseMutation,
  detail: useGetExpenseQuery,
  update: useUpdateExpenseMutation
};
