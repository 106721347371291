import { Queries } from "types/Model";
import {
  Airport,
  useListAirportsQuery,
  useGetAirportQuery,
  useUpdateAirportMutation,
  useCreateAirportMutation
} from "types/graphql";

export const queries: Queries<Airport> = {
  list: useListAirportsQuery,
  create: useCreateAirportMutation,
  detail: useGetAirportQuery,
  update: useUpdateAirportMutation
};
