import * as React from "react";
import { DetailProps } from "types/types";
import { Quote } from "types/graphql";
import { Columns, Column } from "components/Layout";
import { Currency } from "./Currency";
import { LineItems } from "./LineItems";
import { Summary } from "./Summary";
import { Config } from "./Config";

export const Financials: React.FC<DetailProps<Quote>> = ({ g }) => (
  <>
    <div className="box">
      <Columns>
        <Column>
          <Summary g={g} />
        </Column>
        <Config g={g} />
        <Column narrow>
          <Currency g={g} />
        </Column>
      </Columns>
    </div>
    <LineItems g={g} />
  </>
);
