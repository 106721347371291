import { Model } from "../../types/Model";
import { queries } from "./query";
import { listing } from "./listing";
import { graphileModel } from "../graphile";

import { View as display } from "./components/View";
import { LegalFolder } from "../../types/graphql";
import { Editor as editor } from "./components/Editor";

export const model: Model<LegalFolder> = {
  // editing
  graphileEntity: "LegalFolder",
  graphileModel,
  // querying
  queries,
  // displaying
  listing,
  display,
  editor,
  // misc.
  breadcrumb: () => [
    { target: ["legal"], label: "Legal" },
    { target: ["legal", "folders"], label: "Folders" },
  ],
};
