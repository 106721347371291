import * as React from "react";
import { DetailProps } from "types/types";
import { Quote } from "types/graphql";
import { DateFormat, date } from "components/Format";

export const Views: React.FC<DetailProps<Quote>> = ({ entity }) => (
  <div className="table-container">
    <table className="table is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th className="col-date">When</th>
          <th>IP</th>
          <th>Country</th>
          <th>City</th>
          <th>Type</th>
          <th>Timezone</th>
        </tr>
      </thead>
      <tbody>
        {entity.quoteViews?.nodes.map((ent) =>
          !ent ? null : (
            <tr key={ent.id}>
              <td>{date(ent.created, DateFormat.Long)}</td>
              <td>{ent.userIp}</td>
              <td>{ent.country}</td>
              <td>{ent.city}</td>
              <td>{ent.viewType}</td>
              <td>{ent.timezone}</td>
            </tr>
          )
        ) ?? []}
      </tbody>
    </table>
  </div>
);
