import { Queries } from "types/Model";
import {
  Property,
  useListPropertiesQuery,
  useGetPropertyQuery,
  useUpdatePropertyMutation,
  useCreatePropertyMutation
} from "types/graphql";

export const queries: Queries<Property> = {
  list: useListPropertiesQuery,
  create: useCreatePropertyMutation,
  detail: useGetPropertyQuery,
  update: useUpdatePropertyMutation
};
