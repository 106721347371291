import * as React from "react";
import { Card } from "components/Layout";
import { DetailProps } from "types/types";
import { Payment } from "types/graphql";
import { Link } from "react-router-dom";
import { money, date } from "components/Format";

export const Overview: React.FC<DetailProps<Payment>> = ({
  entity,
  actions
}) => {
  return (
    <>
      <Card>
        <Card.Content>
          <h4 className="title is-4">
            <Link to={`/sales/trips/${entity.trip?.id}`}>
              {entity.trip?.name}
            </Link>
          </h4>
          <h6 className="subtitle is-6">
            {money(entity.amount, entity.currency)}
          </h6>
          <dl>
            {entity.paid && (
              <>
                <dt>Paid</dt>
                <dd>{date(entity.paid)}</dd>
              </>
            )}
          </dl>
        </Card.Content>
      </Card>
      <hr />
      <div className="is-pulled-right">{actions}</div>
    </>
  );
};
