import { Queries } from "types/Model";
import {
  LegalDocument,
  useListLegalDocumentsQuery,
  useGetLegalDocumentQuery,
  useUpdateLegalDocumentMutation,
  useCreateLegalDocumentMutation,
} from "types/graphql";

export const queries: Queries<LegalDocument> = {
  list: useListLegalDocumentsQuery,
  create: useCreateLegalDocumentMutation,
  detail: useGetLegalDocumentQuery,
  update: useUpdateLegalDocumentMutation,
};
