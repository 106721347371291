import * as React from "react";
import { DetailProps } from "types/types";
import { Quote, QuoteLegalDocument } from "types/graphql";
import { Columns, Column, Box } from "components/Layout";
import { MultiFieldOptions } from "use-graphile/dist/fields/multi";
import { arrayMoveImmutable } from "array-move";
import { LegalDocumentSelector } from "components/Selectors";

const options: MultiFieldOptions<QuoteLegalDocument> = {
  populateNewItem: (items) => {
    return { sortOrder: items.length } as QuoteLegalDocument;
  },
};

export const LegalDocuments: React.FC<DetailProps<Quote>> = ({ g }) => {
  const [moving, setMoving] = React.useState<number | null>(null);

  return g.multi<QuoteLegalDocument>(
    "quoteLegalDocuments",
    (docs) => {
      const handleMove = (target: number) => () => {
        docs.set(
          arrayMoveImmutable(docs.items, moving!, target).map(
            (ent, sortOrder) => ({
              ...ent,
              sortOrder,
            })
          )
        );
        setMoving(null);
      };

      return (
        <div>
          {docs.renderItems(
            (acc) => {
              const movingBlock = (
                <div className="buttons is-centered">
                  <button
                    className="button is-success is-outlined"
                    onClick={handleMove(acc.index)}
                  >
                    <span className="icon">
                      <i className="far fa-check"></i>
                    </span>
                  </button>
                  <button
                    className="button is-warning is-outlined"
                    onClick={() => setMoving(null)}
                  >
                    <span className="icon">
                      <i className="far fa-times"></i>
                    </span>
                  </button>
                </div>
              );

              return (
                <>
                  {moving !== null && acc.index !== moving && acc.index === 0
                    ? movingBlock
                    : null}
                  <Box>
                    <Columns>
                      <Column>
                        {acc.link("legalDocument", (property: any) => (
                          <LegalDocumentSelector field={property} />
                        ))}
                      </Column>

                      {moving !== null ? null : (
                        <Column narrow>
                          <div className="buttons">
                            {docs.items.length < 2 ? null : (
                              <button
                                className="button is-info is-outlined"
                                onClick={() =>
                                  setMoving((prevValue) =>
                                    prevValue ? null : acc.index
                                  )
                                }
                              >
                                <span className="icon">
                                  <i className="fad fa-arrows-v" />
                                </span>
                              </button>
                            )}

                            <button
                              className="button is-warning is-outlined"
                              onClick={acc.remove}
                            >
                              <span className="icon">
                                <i className="fad fa-trash" />
                              </span>
                            </button>
                          </div>
                        </Column>
                      )}
                    </Columns>
                  </Box>
                  {moving !== null && acc.index !== moving && acc.index > 0
                    ? movingBlock
                    : null}
                </>
              );
            },
            undefined,
            "sortOrder"
          )}
          <Box className="has-text-centered">
            <button className="button is-info" onClick={docs.add}>
              Add
            </button>
          </Box>
        </div>
      );
    },
    options
  );
};
