import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { SupplierInvoice } from "../../../types/graphql";
import {
  TripSelector,
  FinanceCategorySelector,
  SupplierSelector,
} from "components/Selectors";
import { DateField, AmountField } from "components/Layout/Field";

export const Editor: React.FC<DetailProps<SupplierInvoice>> = ({ g }) => (
  <>
    <Columns>
      <Column>
        {g.link("supplier", (supplier) => (
          <SupplierSelector field={supplier} />
        ))}
      </Column>
      <Column>
        {g.link("trip", (trip) => (
          <TripSelector field={trip} />
        ))}
      </Column>
      <Column>
        {g.link("category", (category) => (
          <FinanceCategorySelector field={category} />
        ))}
      </Column>
    </Columns>
    <Columns>
      <Column>
        <DateField label="Due" {...g.input("due")} />
      </Column>
      <Column>
        <AmountField label="Amount" {...g.input("amount")} />
      </Column>
      <Column>
        <InputField label="Currency" {...g.input("currency")} />
      </Column>
      <Column>
        <AmountField label="Amount actual" {...g.input("amountActual")} />
      </Column>
      <Column>
        <DateField label="Paid" {...g.input("paid")} />
      </Column>
    </Columns>
    <Columns>
      <Column span={6}>
        <InputField label="Summary" {...g.input("summary")} />
      </Column>
      <Column>
        <InputField label="Type" {...g.input("type")} />
      </Column>
      <Column>
        <InputField label="Number" {...g.input("number")} />
      </Column>
      <Column>
        <DateField label="Voided" {...g.input("voided")} />
      </Column>
    </Columns>
  </>
);
