import * as React from "react";
import { InputField, Columns, Column } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Supplier } from "../../../types/graphql";

export const Editor: React.FC<DetailProps<Supplier>> = ({ g, entity }) => (
  <>
    <InputField
      className="input is-large"
      placeholder="Name"
      autoFocus={!entity?.id}
      {...g.input("name")}
    />
    <Columns>
      <Column span={3}>
        <InputField label="Phone" type="tel" {...g.input("contactNumber")} />
      </Column>
      <Column>
        <InputField label="Email" type="email" {...g.input("contactEmail")} />
      </Column>
    </Columns>
  </>
);
