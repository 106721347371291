import * as React from "react";
import { model as tripModel } from "entities/Trip/model";
import { Trip } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const TripSelector: React.FC<SelectorProps<Trip>> = ({
  label,
  ...rest
}) => (
  <SelectorField<Trip>
    model={tripModel}
    label={label ?? "Trip"}
    display={ent => ent.name ?? "(unnamed)"}
    {...rest}
  />
);
