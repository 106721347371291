import * as React from "react";
import { Card } from "../../../components/Layout";
import { DetailProps } from "../../../types/types";
import { Supplier } from "../../../types/graphql";

export const Overview: React.FC<DetailProps<Supplier>> = ({
  entity,
  actions
}) => {
  const footerButtons = [
    entity.contactEmail
      ? {
          href: `mailto:${entity.contactEmail}`,
          icon: "fas fa-envelope",
          text: "Email"
        }
      : null,
    entity.contactNumber
      ? {
          href: `tel:${entity.contactNumber.replace(/\s/g, "")}`,
          icon: "fas fa-phone",
          text: "Phone"
        }
      : null
  ].filter(ent => ent);

  return (
    <>
      <Card>
        <Card.Content>
          <h4 className="title is-4">{entity.name}</h4>
          {entity.contactEmail && (
            <h6 className="subtitle is-6">{entity.contactEmail}</h6>
          )}
        </Card.Content>
        {footerButtons.length > 0 && (
          <Card.Footer>
            {footerButtons.map((ent, idx) =>
              ent ? (
                <Card.Footer.Item key={idx}>
                  {ent.icon && (
                    <span className="icon">
                      <i className={ent.icon} />
                    </span>
                  )}
                  <a href={ent.href} target="_blank" rel="noreferrer noopener">
                    {ent.text}
                  </a>
                </Card.Footer.Item>
              ) : null
            )}
          </Card.Footer>
        )}
      </Card>
      <hr />
      <div className="is-pulled-right">{actions}</div>
    </>
  );
};
