import * as React from "react";
import type { Form as Graphile } from "use-graphile";
import { Quote } from "types/graphql";
import { Column, InputField } from "components/Layout";

export const Config: React.FC<any> = ({ g }: { g: Graphile<Quote> }) => (
  <>
    <Column narrow>
      <InputField label="Margin" {...g.input("margin")} />
      <InputField label="Agent margin" {...g.input("agentMargin")} />
    </Column>
    <Column narrow>
      <InputField label="Adjustment" {...g.input("adjustment")} />
      <InputField label="Deposit" {...g.input("deposit")} />
    </Column>
  </>
);
