import * as React from "react";
import { model as AirportModel } from "entities/Airport/model";
import { Airport } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const AirportSelector: React.FC<SelectorProps<Airport>> = ({
  label,
  ...rest
}) => (
  <SelectorField<Airport>
    model={AirportModel}
    label={label ?? "Airport"}
    display={ent => `${ent.iata ?? ent.icao} - ${ent.city}` ?? "(unnamed)"}
    {...rest}
  />
);
