import { Queries } from "types/Model";
import {
  MediaGallery,
  useListGalleriesQuery,
  useGetGalleryQuery,
  useUpdateGalleryMutation,
  useCreateGalleryMutation
} from "types/graphql";

export const queries: Queries<MediaGallery> = {
  list: useListGalleriesQuery,
  create: useCreateGalleryMutation,
  detail: useGetGalleryQuery,
  update: useUpdateGalleryMutation
};
