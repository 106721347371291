import * as React from "react";
import { DetailProps } from "../../../types/types";
import { Trip } from "../../../types/graphql";
import { Editor } from "./Editor";

export const Overview: React.FC<DetailProps<Trip>> = props => (
  <>
    <Editor {...props} />
    {/* <Field
      control={GallerySelector}
      label="Media library (attachments)"
      name="mediaGallery"
      source={detail}
      onChange={onInputChange}
      emptyValue={null}
    /> */}
  </>
);
