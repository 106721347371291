import * as React from "react";
import { DetailProps } from "../../../../types/types";
import { Trip, Quote } from "../../../../types/graphql";
import { model as quoteModel } from "entities/Quote/model";
import { Create } from "components/entities/Create";
import { ListingTable } from "components/ListingTable";

export const QuoteList: React.FC<DetailProps<Trip>> = ({ entity, refetch }) => {
  const initialValues = React.useMemo(
    () =>
      ({
        trip: {
          id: entity.id,
          name: entity.name,
        },
        start: entity.start,
        duration: entity.duration,
      } as Quote),
    [entity]
  );

  const createProps = Create<Quote>({
    model: quoteModel,
    initialValues,
    onCreate: () => refetch(),
  });

  return (
    <>
      <ListingTable<Quote>
        excludedColumns={["Trip"]}
        items={entity.quotes.nodes as Quote[]}
        model={quoteModel}
      />

      <div className="buttons is-right">
        <button
          type="button"
          className="button is-small is-dark"
          {...createProps}
        >
          Add
        </button>
      </div>
    </>
  );
};
