import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";

const endpointMap: { [key: string]: string } = {
  // localhost: "http://[::1]:5002/graphql",
  default: "https://api.takeoffgo.com/graphql",
};

export const makeClient = (accessToken: string | null) => {
  const cache = new InMemoryCache({
    dataIdFromObject: (object: any) => object.id,
  });

  const httpLink = new HttpLink({
    uri: endpointMap[window.location.hostname] || endpointMap.default,
    headers: accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {},
  });

  const link = from([/*errorLink,*/ httpLink]);

  const client = new ApolloClient<any>({
    link,
    cache,
    connectToDevTools: true,
  });

  return client;
};
