import * as React from "react";
import { DetailProps } from "types/types";
import { Traveller } from "types/graphql";
import { Tabs, Tab } from "components/Tabs";
import { Trips } from "./Trips";
import { Customers } from "./Customers";
import { Passports } from "./Passports";

export const Detail: React.FC<DetailProps<Traveller>> = props => {
  return (
    <Tabs>
      <Tab key="passports" title="Passports">
        <Passports {...props} />
      </Tab>
      <Tab key="trips" title="Trips">
        <Trips {...props} />
      </Tab>
      <Tab key="customers" title="Customers">
        <Customers {...props} />
      </Tab>
    </Tabs>
  );
};
