import * as React from "react";
import { model as agencyModel } from "entities/Agency/model";
import { Agency } from "types/graphql";
import { SelectorField } from "./SelectorField";
import type { SelectorProps } from "./selectors";

export const AgencySelector: React.FC<SelectorProps<Agency>> = ({
  label,
  ...rest
}) => (
  <SelectorField<Agency>
    model={agencyModel}
    label={label ?? "Agency"}
    display={ent => ent.name ?? "(unnamed)"}
    {...rest}
  />
);
