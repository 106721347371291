import * as React from "react";
import type { Form as Graphile } from "use-graphile";
import { Quote, QuoteFinanceLineItem } from "types/graphql";
import { LineItem } from "./components/LineItem";
import { MultiFieldOptions } from "use-graphile/dist/fields/multi";
import { arrayMoveImmutable } from "array-move";
import { Box } from "components/Layout";

const options: MultiFieldOptions<QuoteFinanceLineItem> = {
  populateNewItem: (items) => {
    return { sortOrder: items.length ?? 0 } as QuoteFinanceLineItem;
  },
};

export const LineItems: React.FC<any> = ({ g }: { g: Graphile<Quote> }) => {
  const [moving, setMoving] = React.useState<number | null>(null);

  return g.multi<QuoteFinanceLineItem>(
    "quoteFinanceLineItems",
    (lineItems) => {
      const handleMove = (target: number) => () => {
        lineItems.set(
          arrayMoveImmutable(lineItems.items, moving!, target).map(
            (ent, sortOrder) => ({
              ...ent,
              sortOrder,
            })
          )
        );
        setMoving(null);
      };
      return (
        <div>
          {lineItems.renderItems(
            (i) => {
              const movingBlock = (
                <div className="buttons is-centered">
                  <button
                    className="button is-success is-outlined"
                    onClick={handleMove(i.index)}
                  >
                    <span className="icon">
                      <i className="far fa-check"></i>
                    </span>
                  </button>
                  <button
                    className="button is-warning is-outlined"
                    onClick={() => setMoving(null)}
                  >
                    <span className="icon">
                      <i className="far fa-times"></i>
                    </span>
                  </button>
                </div>
              );

              return (
                <>
                  {moving !== null && i.index !== moving && i.index === 0
                    ? movingBlock
                    : null}
                  <Box>
                    <LineItem
                      lineItem={i}
                      g={g}
                      onMove={
                        lineItems.items.length > 2
                          ? () =>
                              setMoving((prevValue) =>
                                prevValue ? null : i.index
                              )
                          : undefined
                      }
                      moving={moving}
                    />
                  </Box>
                  {moving !== null && i.index !== moving && i.index > 0
                    ? movingBlock
                    : null}
                </>
              );
            },
            undefined,
            "sortOrder"
          )}
          <div className="box has-text-centered">
            <button
              type="button"
              className="button is-info"
              onClick={lineItems.add}
            >
              Add
            </button>
          </div>
        </div>
      );
    },
    options
  );
};
